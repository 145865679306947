import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Image as Img,
  Paragraph,
  Input,
  Link,
} from "theme-ui"
import { theme } from "../theme"
import Goat2 from "images/goat-2.png"
import Goat3 from "images/goat-3.png"
import Goat4 from "images/goat-4.png"

function preloadImage(url) {
  var img = new Image()
  img.src = url
}

const bgColors = [
  "#8fabdc",
  "#fb1420",
  "#1d77eb",
]
const goats = [
  Goat2,
  Goat3,
  Goat4,

]
const phrases = [
  "HIT ME",
  "LFGOAT",
  "BLEEEAT",
  "BLUE CHIP",
  "F*CK ETH",
  "GOAT TRIBE",
  "CLICK ME",
  "BAAAH",
  "BILO RULES",
  "DO IT",
  "LFINVEST",
]
const waitingGifs = [
  "https://c.tenor.com/-P-xeHYEY9QAAAAd/sad-pablo-lonely.gif",
  "https://c.tenor.com/rec5dlPBK2cAAAAd/mr-bean-waiting.gif",
  "https://c.tenor.com/DijuQ74YJnwAAAAC/hitmans-bodyguard-hitmans-bodyguard-gi-fs.gif",
  "https://c.tenor.com/vTY0qobiAtsAAAAC/judge-judy-time.gif",
  "https://c.tenor.com/WSDlnEeAG48AAAAC/waiting-spongebob.gif",
  "https://c.tenor.com/fyylSNGrZ80AAAAd/well-waiting.gif",
  "https://c.tenor.com/ZhnTD1C2eJsAAAAd/dog-cute.gif",
  "https://c.tenor.com/1qYRipB8yqwAAAAC/will-ferrell-say-something.gif",
  "https://c.tenor.com/89P_KY3375QAAAAC/stone-cold-steve-austin.gif",
  "https://c.tenor.com/S8H2f8iDMKYAAAAC/waiting-aiw.gif",
  "https://c.tenor.com/MWUQNMa9qXYAAAAC/lisa-simpson-waiting-for-an-answer.gif",
  "https://c.tenor.com/2v8EwMyAny8AAAAC/please-hmm.gif",
  "https://c.tenor.com/CKxw634NBOMAAAAC/rick-and-morty-i-cant-take-it-anymore-i-just-want-to-die.gif",
  "https://c.tenor.com/iIR6arMpYCIAAAAC/forever-sandlot.gif",
  "https://c.tenor.com/sKg02SlxaoQAAAAC/red-goobler-solar-opposites.gif",
  "https://c.tenor.com/NMLAWSo-mqIAAAAd/yoda-patience.gif",
  "https://c.tenor.com/WrGifwMDFugAAAAC/patience-young.gif",
  "https://c.tenor.com/U78pJ-UPQ3kAAAAC/cat-patience-cat.gif",
  "https://c.tenor.com/o6r7p0Dhr9AAAAAC/im-isaiah.gif",
  "https://c.tenor.com/F02lwzSKPSAAAAAd/horse-man-drunk-carrying-a-horse.gif",
  "https://c.tenor.com/ycKJas-YT0UAAAAM/im-waiting-aki-and-paw-paw.gif",
  "https://c.tenor.com/btU_dDhCocIAAAAC/waiting-cookie.gif",
  "https://c.tenor.com/lQNf6C6HDYQAAAAC/angry-kid.gif",
  "https://c.tenor.com/sGzHvagmSugAAAAC/staredown-forreal.gif",
  "https://c.tenor.com/PX6i9045kWAAAAAC/daddy-chill.gif",
  "https://c.tenor.com/alnE0X0fbV8AAAAC/chill-chill-out.gif",
  "https://c.tenor.com/oWvq12gtjDIAAAAC/high-pineapple-express.gif",
  "https://c.tenor.com/FKn-8Ac5M7oAAAAC/no-bugs-bunny.gif",
  "https://c.tenor.com/zK6-iEBjjcEAAAAC/no-nooo.gif",
  "https://c.tenor.com/1FR4o42djy8AAAAC/spiderman-magic.gif",
]

const soldOutGifs = [
  "https://c.tenor.com/sKg02SlxaoQAAAAC/red-goobler-solar-opposites.gif",
  "https://c.tenor.com/0hNIHb95iDMAAAAC/no-more-rebel-wilson.gif",
  "https://c.tenor.com/WejWsIlk3_cAAAAd/thats-enough-okay-jason-bateman.gif",
  "https://c.tenor.com/hKsgsuQ5N8MAAAAC/cymbals-monkey-brain.gif",
  "https://c.tenor.com/98QJc8KJsMkAAAAC/gimme-no-more.gif",
  "https://c.tenor.com/N2ibf5aQj2gAAAAC/dragrace-bitch-you-done.gif",
  "https://c.tenor.com/3DGYbblZUasAAAAd/youre-done-done-son.gif",
  "https://c.tenor.com/ig55vSh4xHwAAAAC/throw-dog.gif",
  "https://c.tenor.com/VJz7OLQ8DkQAAAAM/heather-dubrow-shannon-beador.gif",
  "https://c.tenor.com/MPsGPP25j70AAAAC/good-day-fez.gif",
  "https://c.tenor.com/J3mNIbj6A4wAAAAd/empty-shelves-john-travolta.gif",
  "https://c.tenor.com/UbGii9oGosoAAAAC/money-wallet.gif",
  "https://c.tenor.com/nd2wxsrTXYEAAAAd/level-up-next-level.gif",
  "https://c.tenor.com/WS9TNssQALkAAAAd/roughneck-rabbits-roughneck.gif",
  "https://c.tenor.com/Q6BU4RC5zQsAAAAd/sold-out-what.gif",
  "https://c.tenor.com/TK5hcjkNr_cAAAAC/gordon-ramsey-you-are-done.gif",
  "https://c.tenor.com/MC7K9jo7V0YAAAAC/survivor-jeff-probst.gif",
  "https://c.tenor.com/qYrv7LSWFFIAAAAC/finn-adventure-time.gif",
  "https://c.tenor.com/gKcLWQAVD8sAAAAC/done-annoyed.gif",
  "https://c.tenor.com/jhJp2A-K5HwAAAAd/gollum-leave-now.gif",
  "https://c.tenor.com/TbGZDsVxx0wAAAAC/i-dont-need-you-anymore-kyle-broflovski.gif",
  "https://c.tenor.com/VGmgyUMNP88AAAAC/toy-story-woody.gif",
  "https://c.tenor.com/FqdTy04G6m4AAAAC/i-dont-need-you-anymore-satan.gif",
  "https://c.tenor.com/JLpNtvfer7wAAAAM/you-need-to-go-saturday-night-live.gif",
  "https://c.tenor.com/JWTW52xCLycAAAAd/i-think-you-should-leave-before-i-call-the-police-eric-cartman.gif",
  "https://c.tenor.com/6aZotD1Q0SkAAAAC/tigger-sad.gif",
  "https://c.tenor.com/ipXfNs5DnFIAAAAd/get-the-hell-out-of-here-sung-won-cho.gif",
  "https://c.tenor.com/0Ar7aEuIPT4AAAAC/get-the-fudge-out-eric-cartman.gif",
  "https://c.tenor.com/Wl0r-dwasLYAAAAC/sad-the-simpsons.gif",
  "https://c.tenor.com/L2T_fqCrWyMAAAAC/theres-no-more-scared.gif",
  "https://c.tenor.com/dlJSiLUJNmsAAAAC/math-calculate.gif",
  "https://c.tenor.com/18sqCWkrm9oAAAAC/confused-wtf.gif",
  "https://c.tenor.com/3yyytNGYC7cAAAAC/empty-tank.gif",
  "https://c.tenor.com/q-E5wj1K6OYAAAAC/monsters-inc-sully.gif",
  "https://c.tenor.com/65QP-lH9krMAAAAC/no-money-donald-duck.gif",
  "https://c.tenor.com/lQNf6C6HDYQAAAAC/angry-kid.gif",
  "https://c.tenor.com/sGzHvagmSugAAAAC/staredown-forreal.gif",
]

const getRandomNumber = (previous) => {
  let num = Math.floor(Math.random() * goats.length)
  if (num == previous) num = Math.floor(Math.random() * goats.length)
  if (num == previous) num = Math.floor(Math.random() * goats.length)
  return num
}

export const MintInactive = (props) => {
  const [randomNumber, setRandomNumber] = useState(getRandomNumber())
  const [waitingGif, setWaitingGif] = useState(null)
  const [soldOutGif, setSoldOutGif] = useState(null)
  const tokenStart = props.mint_access_token_start
  const tokenEnd = props.mint_access_token_end

  const randomGoat = goats[randomNumber]
  const randomBg = bgColors[randomNumber]

  useEffect(() => {
    goats.forEach((goat) => {
      preloadImage(goat)
    })
    setWaitingGif(waitingGifs[Math.floor(Math.random() * waitingGifs.length)])
    setSoldOutGif(soldOutGifs[Math.floor(Math.random() * soldOutGifs.length)])
  }, [])

  const mintStart = new Date(tokenStart)
  const mintEnd = new Date(tokenEnd)

  const mintTokenStart = `${mintStart.toLocaleDateString()} - ${mintStart.toLocaleTimeString()}`

  const mintTokenEnd = `${mintEnd.toLocaleDateString()} - ${mintEnd.toLocaleTimeString()}`

  const isPreMint = new Date() < mintStart
  const isPostMint = new Date() > mintEnd

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "white",
          width: "100%",
          minHeight: "100vh",
          pb: [400, 0],
          bg: randomBg,
        }}
      >
        <Img
          src={randomGoat}
          sx={{
            width: ["250px", "500px"],
            position: "fixed",
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        <Box
          sx={{
            bg: "transparent",
            width: ["90%", "600px"],
            maxWidth: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            ml: "auto",
            top: 0,
            right: [20, 30],
            borderRadius: "15px",
            p: "20px",
            pb: "40px",
            zIndex: 10,
          }}
        >
          <Box>
            <Paragraph
              className="marker"
              sx={{
                color: randomNumber != 10 ? "black" : "white",
                whiteSpace: "pre-wrap",
                fontSize: ["22px", "28px"],
                mb: 25,
              }}
            >
              {`SGS isn't minting right now`}
            </Paragraph>
            <Paragraph
              className="marker"
              sx={{ ...buttonStyles, mb: 30, mt: 0, top: 0 }}
              onClick={() =>
                setSoldOutGif(
                  soldOutGifs[Math.floor(Math.random() * soldOutGifs.length)]
                )
              }
            >
              {"LFGOAT"}
            </Paragraph>
            <Img
              src={soldOutGif}
              sx={{
                width: ["100%"],
                position: "relative",
                zIndex: 5,
                maxWidth: "400px",
                maxHeight: "400px",
                objectFit: "contain",
                objectPosition: "left",
              }}
            />
          </Box>

          <Link
            className="marker"
            sx={{
              textAlign: "center",
              position: "relative",
              top: 10,
              color: "white !important",
              whiteSpace: "nowrap",
              zIndex: 1005,
              border: "4px solid black",
              bg: "black",
              py: "12px",
              width: ["100%", 300],
              borderRadius: "8px",
              cursor: "pointer",
              fontSize: "24px",
              userSelect: "none",
              display: "block",
              marginTop: "25px",
              textDecoration: "none",
              textTransform: "uppercase",
              "&:hover": {
                bg: "#1c1c1c",
                borderColor: "#1c1c1c",
              },
            }}
            href={"/sgs"}
          >
            Back
          </Link>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  top: 10,
  color: "white !important",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "4px solid black",
  bg: "black",
  py: "12px",
  width: ["100%", 300],
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "24px",
  userSelect: "none",
  display: "block",
  marginTop: "25px",
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    bg: "#1c1c1c",
    borderColor: "#1c1c1c",
  },
}

export default MintInactive

//  {isPreMint && (
//   <Box>
//     <Paragraph
//       className="marker"
//       sx={{
//         color: randomNumber != 10 ? "black" : "white",
//         whiteSpace: "pre-wrap",
//         fontSize: ["22px", "26px"],
//         mb: 25,
//       }}
//     >
//       {`Your access token isn't active yet\nCome back at\n${mintTokenStart}`}
//     </Paragraph>
//     <MintingTips />

//     <Paragraph
//       className="marker"
//       sx={{ ...buttonStyles, mb: 30, mt: 0, top: 0 }}
//       onClick={() =>
//         setWaitingGif(
//           waitingGifs[Math.floor(Math.random() * waitingGifs.length)]
//         )
//       }
//     >
//       {"LFGOAT"}
//     </Paragraph>
//     <Img
//       src={waitingGif}
//       sx={{
//         width: ["100%"],
//         position: "relative",
//         zIndex: 5,
//         maxWidth: "400px",
//         maxHeight: "400px",
//         objectFit: "contain",
//         objectPosition: "left",
//       }}
//     />
//   </Box>
// )}
// {isPostMint && (
//   <Box>
//     <Paragraph
//       className="marker"
//       sx={{
//         color: randomNumber != 10 ? "black" : "white",
//         whiteSpace: "pre-wrap",
//         fontSize: ["22px", "28px"],
//         mb: 25,
//       }}
//     >
//       {`Your access token ended at\n${mintTokenEnd}\nGo home!`}
//     </Paragraph>
//     <Paragraph
//       className="marker"
//       sx={{ ...buttonStyles, mb: 30, mt: 0, top: 0 }}
//       onClick={() =>
//         setSoldOutGif(
//           soldOutGifs[Math.floor(Math.random() * soldOutGifs.length)]
//         )
//       }
//     >
//       {"LFGOAT"}
//     </Paragraph>
//     <Img
//       src={soldOutGif}
//       sx={{
//         width: ["100%"],
//         position: "relative",
//         zIndex: 5,
//         maxWidth: "400px",
//         maxHeight: "400px",
//         objectFit: "contain",
//         objectPosition: "left",
//       }}
//     />
//   </Box>
// )}
