import React, { useEffect, useState } from "react"
import { ThemeProvider, Box, Grid, Image as Img, Paragraph } from "theme-ui"
import { theme } from "../theme"

import SGSLogo from "images/sgs-logo-trans.png"

const waitingGifs = [
  "https://c.tenor.com/-P-xeHYEY9QAAAAd/sad-pablo-lonely.gif",
  "https://c.tenor.com/rec5dlPBK2cAAAAd/mr-bean-waiting.gif",
  "https://c.tenor.com/DijuQ74YJnwAAAAC/hitmans-bodyguard-hitmans-bodyguard-gi-fs.gif",
  "https://c.tenor.com/vTY0qobiAtsAAAAC/judge-judy-time.gif",
  "https://c.tenor.com/WSDlnEeAG48AAAAC/waiting-spongebob.gif",
  "https://c.tenor.com/fyylSNGrZ80AAAAd/well-waiting.gif",
  "https://c.tenor.com/ZhnTD1C2eJsAAAAd/dog-cute.gif",
  "https://c.tenor.com/1qYRipB8yqwAAAAC/will-ferrell-say-something.gif",
  "https://c.tenor.com/89P_KY3375QAAAAC/stone-cold-steve-austin.gif",
  "https://c.tenor.com/S8H2f8iDMKYAAAAC/waiting-aiw.gif",
  "https://c.tenor.com/MWUQNMa9qXYAAAAC/lisa-simpson-waiting-for-an-answer.gif",
  "https://c.tenor.com/2v8EwMyAny8AAAAC/please-hmm.gif",
  "https://c.tenor.com/CKxw634NBOMAAAAC/rick-and-morty-i-cant-take-it-anymore-i-just-want-to-die.gif",
  "https://c.tenor.com/iIR6arMpYCIAAAAC/forever-sandlot.gif",
  "https://c.tenor.com/sKg02SlxaoQAAAAC/red-goobler-solar-opposites.gif",
  "https://c.tenor.com/NMLAWSo-mqIAAAAd/yoda-patience.gif",
  "https://c.tenor.com/WrGifwMDFugAAAAC/patience-young.gif",
  "https://c.tenor.com/U78pJ-UPQ3kAAAAC/cat-patience-cat.gif",
  "https://c.tenor.com/o6r7p0Dhr9AAAAAC/im-isaiah.gif",
  "https://c.tenor.com/F02lwzSKPSAAAAAd/horse-man-drunk-carrying-a-horse.gif",
  "https://c.tenor.com/ycKJas-YT0UAAAAM/im-waiting-aki-and-paw-paw.gif",
  "https://c.tenor.com/btU_dDhCocIAAAAC/waiting-cookie.gif",
  "https://c.tenor.com/lQNf6C6HDYQAAAAC/angry-kid.gif",
  "https://c.tenor.com/sGzHvagmSugAAAAC/staredown-forreal.gif",
  "https://c.tenor.com/PX6i9045kWAAAAAC/daddy-chill.gif",
  "https://c.tenor.com/alnE0X0fbV8AAAAC/chill-chill-out.gif",
  "https://c.tenor.com/oWvq12gtjDIAAAAC/high-pineapple-express.gif",
  "https://c.tenor.com/FKn-8Ac5M7oAAAAC/no-bugs-bunny.gif",
  "https://c.tenor.com/zK6-iEBjjcEAAAAC/no-nooo.gif",
  "https://c.tenor.com/1FR4o42djy8AAAAC/spiderman-magic.gif",
]

const getRandomNumber = (previous) => {
  let num = Math.floor(Math.random() * waitingGifs.length)
  if (num == previous) num = Math.floor(Math.random() * waitingGifs.length)
  if (num == previous) num = Math.floor(Math.random() * waitingGifs.length)
  return num
}

export const MintInactiveWolf = (props) => {
  const [randomNumber, setRandomNumber] = useState(getRandomNumber())
  const [waitingGif, setWaitingGif] = useState(null)

  useEffect(() => {
    setWaitingGif(waitingGifs[Math.floor(Math.random() * waitingGifs.length)])
  }, [])

  const tokenStart = props.mint_access_token_start
  const tokenEnd = props.mint_access_token_end
  const isWl = props.is_wl
  const isVip = props.is_vip
  const mint = props.mint ? JSON.parse(props.mint) : null

  // user
  const mintStart = new Date(tokenStart)
  const mintEnd = new Date(tokenEnd)

  // wl
  const wlStart = new Date(mint.wl_start)

  // public
  const publicStart = new Date(mint.token_start)

  const mintTokenStart = `${mintStart.toLocaleDateString()} - ${mintStart.toLocaleTimeString()}`
  const mintTokenEnd = `${mintEnd.toLocaleDateString()} - ${mintEnd.toLocaleTimeString()}`

  const mintWlStart = `${wlStart.toLocaleDateString()} - ${wlStart.toLocaleTimeString()}`
  const mintPublicStart = `${publicStart.toLocaleDateString()} - ${publicStart.toLocaleTimeString()}`

  const isPreMint = new Date() < mintStart
  const isPostMint = new Date() > mintEnd

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          pb: [400, 0],
          bg: "#000000",
        }}
      >
        {/* <Img
          src={Image1}
          sx={{
            width: ["250px", "500px"],
            position: "fixed",
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        /> */}
        <Box
          sx={{
            bg: "transparent",
            width: ["90%", "600px"],
            maxWidth: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            ml: "auto",
            top: 0,
            right: [20, 30],
            borderRadius: "15px",
            pt: "20px",
            px: ["10px", "20px"],
            pb: "40px",
            zIndex: 10,
          }}
        >
          {isPreMint && (
            <Box>
              {isVip && (
                <Paragraph
                  sx={{
                    color: "white",
                    whiteSpace: "pre-wrap",
                    fontSize: ["22px", "22px"],
                    mb: "6px",
                  }}
                >
                  You're VIP!
                </Paragraph>
              )}
              {isWl && (
                <Paragraph
                  sx={{
                    color: "white",
                    whiteSpace: "pre-wrap",
                    fontSize: ["22px", "22px"],
                    mb: "6px",
                  }}
                >
                  Congrats, you're on the WL!
                </Paragraph>
              )}
              <Paragraph
                className="marker"
                sx={{
                  color: "white",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "22px"],
                  mb: 25,
                }}
              >
                {`The mint isn't active yet\nThe mint starts at ${mintTokenStart} (local)`}
              </Paragraph>
              <Img
                src={waitingGif}
                sx={{
                  width: ["100%"],
                  position: "relative",
                  zIndex: 5,
                  maxWidth: "400px",
                  maxHeight: "400px",
                  objectFit: "contain",
                  objectPosition: "left",
                }}
              />
            </Box>
          )}
          {isPostMint && (
            <Box>
              <Paragraph
                className="marker"
                sx={{
                  color: "white",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "28px"],
                  mb: 25,
                  mt: 25,
                }}
              >
                {`The mint is over!`}
              </Paragraph>
            </Box>
          )}

          <Box
            className="marker"
            sx={{ ...buttonStyles }}
            onClick={() => history.back()}
          >
            Back
          </Box>
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 15,
            left: ["unset", 0],
            right: 0,
            zIndex: 5,
            maxWidth: "200px",
            mx: "auto",
          }}
        >
          <Grid
            sx={{
              gridTemplateColumns: "1fr auto auto 1fr",
              alignItems: "center",
            }}
          >
            <span></span>
            <Paragraph sx={{ color: "white", whiteSpace: "pre-wrap" }}>
              {"Powered by\nSGS Minting"}
            </Paragraph>
            <Img
              src={SGSLogo}
              sx={{
                width: ["60px"],
              }}
            />
            <span></span>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  top: 10,
  color: "white !important",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "4px solid #1c1c1c",
  bg: "#1c1c1c",
  py: "12px",
  width: ["100%", 300],
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "24px",
  userSelect: "none",
  display: "block",
  marginTop: "25px",
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    bg: "#2f2f2f",
    borderColor: "#2f2f2f",
  },
}

export default MintInactiveWolf
