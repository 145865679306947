import React, { useEffect, useState } from "react"
import { ThemeProvider, Box, Grid, Image as Img, Paragraph } from "theme-ui"
import { theme } from "../theme"

import Image1 from "images/rabbit-7.png"
import SGSLogo from "images/sgs-logo-trans.png"

// The WL Mint isn't active yet...
// The 24 hour WL Mint goes live at:
// 4/20/22 - 5pm UTC - 1pm EST - 10am PST

// The Public Mint goes live at:
// 4/21/22 - 5pm UTC - 1pm EST - 10am PST

export const MintInactiveDRRS = (props) => {
  const tokenStart = props.mint_access_token_start
  const tokenEnd = props.mint_access_token_end
  const isWl = props.is_wl
  const mint = props.mint ? JSON.parse(props.mint) : null

  // user
  const mintStart = new Date(tokenStart)
  const mintEnd = new Date(tokenEnd)

  // wl
  const wlStart = new Date(mint.wl_start)

  // public
  const publicStart = new Date(mint.token_start)

  const mintTokenStart = `${mintStart.toLocaleDateString()} - ${mintStart.toLocaleTimeString()}`
  const mintTokenEnd = `${mintEnd.toLocaleDateString()} - ${mintEnd.toLocaleTimeString()}`

  const mintWlStart = `${wlStart.toLocaleDateString()} - ${wlStart.toLocaleTimeString()}`
  const mintPublicStart = `${publicStart.toLocaleDateString()} - ${publicStart.toLocaleTimeString()}`

  const isPreMint = new Date() < mintStart
  const isPostMint = new Date() > mintEnd

  const message = isWl
    ? `The WL mint isn't active yet`
    : `The mint isn't active yet`

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          pb: [400, 0],
          bg: "#000000",
        }}
      >
        <Img
          src={Image1}
          sx={{
            width: ["250px", "500px"],
            position: "fixed",
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        <Box
          sx={{
            bg: "transparent",
            width: ["90%", "600px"],
            maxWidth: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            ml: "auto",
            top: 0,
            right: [20, 30],
            borderRadius: "15px",
            pt: "20px",
            px: ["10px", "20px"],
            pb: "40px",
            zIndex: 10,
          }}
        >
          {isPreMint && (
            <Box>
              {isWl && (
                <Paragraph
                  sx={{
                    color: "#707070",
                    whiteSpace: "pre-wrap",
                    fontSize: ["22px", "26px"],
                    mb: 25,
                  }}
                >
                  Congrats, you're on the WL!
                </Paragraph>
              )}

              <Paragraph
                className="marker"
                sx={{
                  color: "#707070",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mb: 25,
                }}
              >
                {message}
              </Paragraph>
              {/* <Paragraph
                className="marker"
                sx={{
                  color: "#707070",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mb: 25,
                }}
              >
                {`The 24 hour WL Mint goes live at:\n4/20/22 - 5pm UTC - 1pm EST - 10am PST`}
              </Paragraph> */}
              <Paragraph
                className="marker"
                sx={{
                  color: "#707070",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mb: 25,
                }}
              >
                {`The Public Mint goes live at:\n4/21/22 - 5pm UTC - 1pm EST - 10am PST`}
              </Paragraph>
            </Box>
          )}
          {isPostMint && (
            <Box>
              <Paragraph
                className="marker"
                sx={{
                  color: "#707070",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "28px"],
                  mb: 25,
                }}
              >
                {`The mint ended at\n${mintTokenEnd}\nGo home!`}
              </Paragraph>
            </Box>
          )}

          <Box
            className="marker"
            sx={{ ...buttonStyles }}
            onClick={() => history.back()}
          >
            Back
          </Box>
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 15,
            left: ["unset", 0],
            right: 0,
            zIndex: 5,
            maxWidth: "200px",
            mx: "auto",
          }}
        >
          <Grid
            sx={{
              gridTemplateColumns: "1fr auto auto 1fr",
              alignItems: "center",
            }}
          >
            <span></span>
            <Paragraph sx={{ color: "white", whiteSpace: "pre-wrap" }}>
              {"Powered by\nSGS Minting"}
            </Paragraph>
            <Img
              src={SGSLogo}
              sx={{
                width: ["60px"],
              }}
            />
            <span></span>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  top: 10,
  color: "#f25100 !important",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "4px solid #1c1c1c",
  bg: "#1c1c1c",
  py: "12px",
  width: ["100%", 300],
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "24px",
  userSelect: "none",
  display: "block",
  marginTop: "25px",
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    bg: "#2f2f2f",
    borderColor: "#2f2f2f",
  },
}

export default MintInactiveDRRS
