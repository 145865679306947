import React, { useState } from "react"
import { Box, Paragraph } from "theme-ui"

export const MintingTips = (props) => {
  const [showTips, setShowTips] = useState(false)

  return (
    <Box sx={{ mb: 30 }}>
      <Paragraph
        className="marker"
        sx={{
          color: props.black ? "black" : "white",
          whiteSpace: "pre-wrap",
          fontSize: ["22px"],
          lineHeight: ["24px"],
          cursor: "pointer",
        }}
        onClick={() => setShowTips(!showTips)}
      >
        {showTips ? "Hide minting tips" : "See minting tips"}
      </Paragraph>
      {showTips && (
        <Paragraph
          className="marker"
          sx={{
            color: props.black ? "black" : "white",
            whiteSpace: "pre-wrap",
            fontSize: ["22px"],
            lineHeight: ["24px"],
          }}
        >
          {`\n- If the tx is building or validating, just give it a minute or two. It's been several minutes (3 to 5) and the tx is still "building", then refresh the page and try again.\n\n- If the tx is "validating", just wait it out. If it's been several minutes, there may have been an error. Refresh and try again.\n\n- If you get an error, screenshot it and ask a MOD to help you out. If you don't see an error, check the Developer Tools console and screenshot any relevant errors from there. Ie right click -> inspect -> Console tab -> pink/red errors.\n\n- If the blockchain is congested, don't sumbit lots of pending txs at once (JPG, CNFT, SGS). New txs update the utxos and if they change before the next tx it can cause other txs to fail.\n\n- If your tx has been pending for more than 2 hours and still hasn't been confirmed, you can reset the tx in your "Successful Orders" below by clicking the "Reset tx" link. Then you can re-sign a new tx and try again.\n\n- Reach out to MODs via Discord if you're having issues.\n\n\nNotes on the minting process:\n\n- The minting process is a multi-signature tx. Everything is done in 1 tx with 2 outputs (your wallet gets the NFT, our payment wallet gets the ADA)\n\n- The frontend/website creates the tx and you sign it (sig 1). Then that tx is sent to our backend/wallet where we validate that the order and tx are correct (sig 2). If correct, the backend wallet submits the tx to the blockchain/Blockfrost.\n\n- The tx could take a few minutes (5-30) or a couple hours (1-2) depending on blockchain load. If the tx takes longer than 2 hours, you will be able to reset the tx yourself using the website. Re-sign the order and submit again.\n\n- It doesn't matter if you're using a different node for Nami. The tx is always submitted by our backend, not by your Nami node.`}
        </Paragraph>
      )}
    </Box>
  )
}

export default MintingTips
