import React, { useEffect, useState } from "react"
import { Box, Grid, Spinner, Image } from "theme-ui"
import CardanoWallet, { Cardano } from "../nami-js/nami"
import NamiLogo from "images/nami-logo.svg"
import EternlLogo from "images/eternl-logo.svg"
import FlintLogo from "images/flint-logo.svg"

export const ConnectWallet = (props) => {
  const blockfrostApiKey = props.blockfrostApiKey
  const hidden = props.hidden
  const styles = props.styles || {}
  const popupStyles = props.popupStyles || {}
  const handleConnect = props.handleConnect || (() => {})
  const handleLoading = props.handleLoading || (() => {})
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(false)
  const [loading, setLoading] = useState(null)
  const [connected, setConnected] = useState(null)

  const setWallet = (walletName) => {
    connectWallet(walletName)
    setSelected(walletName)
    setOpen(false)
  }

  const images = {
    nami: NamiLogo,
    eternl: EternlLogo,
    ccvault: EternlLogo,
    flint: FlintLogo,
  }

  const connectWallet = async (walletName) => {
    let wallet = null
    let walletData = window?.cardano?.[walletName]
    if (!walletData && walletName == "eternl") window?.cardano?.["ccvault"]

    if (walletData?.name) {
      setLoading(true)
      const S = await Cardano()
      try {
        await walletData.enable().then(async (enabledWallet) => {
          wallet = new CardanoWallet(S, enabledWallet, blockfrostApiKey)
          const address = await wallet.getAddress()
          const balance = await wallet.getBalance()
          wallet.balance = balance
          wallet.address = address
          sessionStorage.setItem("sgsWallet", walletName)
          setConnected(wallet)
          handleConnect(wallet)
          setLoading(false)
        })
      } catch {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    const existingWallet = sessionStorage.getItem("sgsWallet")
    if (existingWallet) {
      connectWallet(existingWallet)
      setSelected(existingWallet)
    }
  }, [])

  useEffect(() => {
    handleLoading(loading)
  }, [loading])

  return (
    <>
      {open && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "1000px",
            height: "100vh",
            zIndex: 42069,
          }}
          onClick={() => setOpen(false)}
        ></Box>
      )}

      <Box
        sx={{
          position: "relative",
          display: hidden ? "none" : "inline-block",
          zIndex: 42070,
        }}
      >
        <Box
          className="marker"
          sx={{ ...buttonStyles, ...styles }}
          onClick={() => !loading && setOpen(!open)}
        >
          {loading && <Spinner size={28} sx={{ color: "white" }} />}
          {!loading && connected && (
            <Grid
              sx={{
                gridTemplateColumns: "1fr auto auto 1fr",
                alignItems: "center",
              }}
            >
              <span />
              <Image
                src={images[selected]}
                sx={{
                  width: selected == "flint" ? "20px" : "30px",
                }}
              />
              <span>
                {`${(connected?.balance?.lovelace / 1000000)?.toFixed(1)} ₳`}
              </span>
              <span />
            </Grid>
          )}
          {!loading && !connected && "CONNECT WALLET"}
        </Box>
        {open && (
          <Box
            sx={{
              position: "absolute",
              top: "95px",
              bg: "white",
              width: ["100%", 300],
              borderRadius: "8px",
              zIndex: 2000,
              p: "5px",
              ...popupStyles,
            }}
          >
            <Grid sx={selectStyles} onClick={() => setWallet("nami")}>
              <Image
                src={NamiLogo}
                sx={{
                  width: "30px",
                }}
              />
              Nami
            </Grid>
            <Grid sx={selectStyles} onClick={() => setWallet("eternl")}>
              <Image
                src={EternlLogo}
                sx={{
                  width: "30px",
                }}
              />
              Eternl
            </Grid>
            <Grid sx={selectStyles} onClick={() => setWallet("flint")}>
              <Image
                src={FlintLogo}
                sx={{
                  width: "20px",
                }}
              />
              Flint
            </Grid>
          </Box>
        )}
      </Box>
    </>
  )
}

const selectStyles = {
  p: "8px",
  gridTemplateColumns: "auto 1fr",
  alignItems: "center",
  borderRadius: "3px",
  cursor: "pointer",
  color: "black",
  "&:hover": { bg: "lightgrey" },
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  minHeight: "60px",
  color: "white",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "4px solid #1c1c1c",
  bg: "#1c1c1c",
  py: "12px",
  width: ["100%", 300],
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "22px",
  userSelect: "none",
  display: "inline-block",
  marginTop: "25px",
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    bg: "#2f2f2f",
    borderColor: "#2f2f2f",
  },
}

export default ConnectWallet
