import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Grid,
  Image as Img,
  Paragraph,
  Input,
  Link,
} from "theme-ui"
import { theme } from "../theme"
import Goat2 from "images/goat-2.png"
import Goat3 from "images/goat-3.png"
import Goat4 from "images/goat-4.png"

function preloadImage(url) {
  var img = new Image()
  img.src = url
}

const bgColors = [
  "#8fabdc",
  "#fb1420",
  "#1d77eb",
]
const goats = [
  Goat2,
  Goat3,
  Goat4,
]
const phrases = [
  "HIT ME",
  "LFGOAT",
  "BLEEEAT",
  "BLUE CHIP",
  "F*CK ETH",
  "GOAT TRIBE",
  "CLICKY CLICK",
  "BAAAH",
  "BILO RULES",
  "DO IT",
  "LFINVEST",
  "BLEAT IT",
  "SHHHHH",
]
const getRandomNumber = (previous) => {
  let num = Math.floor(Math.random() * goats.length)
  if (num == previous) num = Math.floor(Math.random() * goats.length)
  if (num == previous) num = Math.floor(Math.random() * goats.length)
  return num
}

export const SgsHome = (props) => {
  const mintLink = props.mintLink

  const mints = JSON.parse(props.mints)
  const userMints = JSON.parse(props.userMints)

  const drrsMint = mints?.find((mint) => mint.name == "DRRS")
  const drrsUserMint = userMints?.find((uMint) => uMint.mint_id == drrsMint?.id)
  const isWl = drrsUserMint?.is_wl

  const [randomNumber, setRandomNumber] = useState(getRandomNumber())
  const [phrase, setPhrase] = useState(0)

  const randomGoat = goats[randomNumber]
  const randomBg = bgColors[randomNumber]

  const handleClick = () => {
    setRandomNumber(getRandomNumber(randomNumber))
    setPhrase(getRandomNumber(phrase))
  }

  useEffect(() => {
    goats.forEach((goat) => {
      preloadImage(goat)
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      {/* <div id="overlay"></div> */}

      <Box
        sx={{
          bg: "white",
          width: "100%",
          height: ["calc(100vh + 100px)", "100vh"],
          bg: randomBg,
        }}
      >
        <Img
          src={randomGoat}
          sx={{
            width: ["250px", "500px"],
            position: "fixed",
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        <Box
          sx={{
            bg: "transparent",
            width: ["90%", "600px"],
            maxWidth: "100%",
            position: "absolute",
            top: ["75px", "100px"],
            right: [20, 30],
            borderRadius: "15px",
            p: "20px",
            fontSize: ["18px", "22px"],
            mx: ["auto", 0],
            pb: "10px",
            zIndex: 10,
          }}
        >
          <Paragraph
            className="marker"
            sx={{
              color: randomNumber != 10 ? "black" : "white",
              whiteSpace: "pre-wrap",
              fontSize: ["26px", "32px"],
              lineHeight: ["22px", "32px"],
            }}
          >
            {"You made it! \n\nWelcome to the SGS \n\nLFGOAT!"}
          </Paragraph>
          <Box
            className="marker"
            sx={{
              textAlign: "center",
              position: "relative",
              top: 0,
              marginTop: "25px",
              color: "white",
              whiteSpace: "nowrap",
              zIndex: 1005,
              border: "4px solid black",
              bg: "black",
              py: "12px",
              width: ["100%", 350],
              borderRadius: "8px",
              cursor: "pointer",
              fontSize: "24px",
              userSelect: "none",
              "&:hover": {
                bg: "#1c1c1c",
                borderColor: "#1c1c1c",
              },
            }}
            onClick={handleClick}
          >
            {phrases[phrase]}
          </Box>
          {mints?.map((mint) => {
            const isRegistered = userMints?.find(
              (userMint) => userMint.mint_id == mint.id
            )
            const registerText = isRegistered ? `Unregister` : `Register`
            const registerPath = isRegistered
              ? `unregister-mint`
              : `register-mint`

            const baseUrl = `${location?.protocol}//${mint.subdomain}.${location?.host?.replace('www.', '')}`

            return (
              <>
                {isRegistered && (
                  <Link
                    className="marker"
                    sx={{
                      textAlign: "center",
                      position: "relative",
                      top: 0,
                      color: "white !important",
                      whiteSpace: "nowrap",
                      zIndex: 1005,
                      border: "4px solid black",
                      bg: "black",
                      py: "12px",
                      width: ["100%", 350],
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "24px",
                      userSelect: "none",
                      display: "block",
                      marginTop: "25px",
                      textDecoration: "none",
                      textTransform: "uppercase",
                      "&:hover": {
                        bg: "#2f2f2f",
                        borderColor: "#2f2f2f",
                      },
                    }}
                    href={`${baseUrl}/mint/${mint.access_token}`}
                  >
                    {`${mint.name} Mint`}
                  </Link>
                )}
                <Link
                  className="marker"
                  sx={{
                    textAlign: "center",
                    position: "relative",
                    top: 0,
                    color: "white !important",
                    whiteSpace: "nowrap",
                    zIndex: 1005,
                    border: "4px solid black",
                    bg: "black",
                    py: "12px",
                    width: ["100%", 350],
                    borderRadius: "8px",
                    cursor: "pointer",
                    fontSize: "24px",
                    userSelect: "none",
                    display: "block",
                    marginTop: "25px",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    "&:hover": {
                      bg: "#2f2f2f",
                      borderColor: "#2f2f2f",
                    },
                  }}
                  href={`/${registerPath}/${mint.id}/${mint.access_token}`}
                  onClick={(e) => {
                    if (isRegistered) {
                      if (
                        !window?.confirm(
                          "Are you sure you want to unregister from the mint?\n\nThis will remove any WL status you have for the mint"
                        ) &&
                        window.location
                      ) {
                        e.preventDefault()
                      }
                    }
                  }}
                >
                  {`${registerText} ${mint.name} Mint`}
                </Link>
              </>
            )
          })}
          {/* {mintLink && (
            <Link
              className="marker"
              sx={{
                textAlign: "center",
                position: "relative",
                top: 35,
                color: "white !important",
                whiteSpace: "nowrap",
                zIndex: 1005,
                border: "4px solid black",
                bg: "black",
                py: "12px",
                width: ["100%", 300],
                borderRadius: "8px",
                cursor: "pointer",
                fontSize: "24px",
                userSelect: "none",
                display: "block",
                marginTop: "25px",
                textDecoration: "none",
                textTransform: "uppercase",
                "&:hover": {
                  bg: "#1c1c1c",
                  borderColor: "#1c1c1c",
                },
              }}
              href={mintLink}
            >
              Mint Link
            </Link>
          )} */}
          {/* <Link
            className="marker"
            sx={{
              textAlign: "center",
              position: "relative",
              top: 35,
              color: "white !important",
              whiteSpace: "nowrap",
              zIndex: 1005,
              border: "4px solid black",
              bg: "black",
              py: "12px",
              width: ["100%", 300],
              borderRadius: "8px",
              cursor: "pointer",
              fontSize: "24px",
              userSelect: "none",
              display: "block",
              marginTop: "25px",
              textDecoration: "none",
              textTransform: "uppercase",
              "&:hover": {
                bg: "#1c1c1c",
                borderColor: "#1c1c1c",
              },
            }}
            href={"/airdrops"}
          >
            Claim Drops
          </Link> */}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default SgsHome
