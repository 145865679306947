import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Grid,
  Image as Img,
  Paragraph,
  Link,
} from "theme-ui"
import { theme } from "../theme"
import Image1 from "images/wolf-1.png"
import SGSLogo from "images/sgs-logo-trans.png"

export const HomeWolf = (props) => {
  const mints = JSON.parse(props.mints)
  const userMints = JSON.parse(props.userMints)

  const mint = mints?.find((mint) => mint.name == "WOLF")
  const userMint = userMints?.find((uMint) => uMint.mint_id == mint?.id)
  const isWl = userMint?.is_wl

  let message = "You made it! \n\nWelcome to the Famous Wolf Club mint\n\n"
  // if (isWl) message += "You're on the WL"

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "white",
          width: "100%",
          height: ["calc(100vh + 100px)", "100vh"],
          bg: "#000000",
        }}
      >
        <Img
          src={Image1}
          sx={{
            width: ["250px", "500px"],
            position: "fixed",
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        <Box
          sx={{
            bg: "transparent",
            width: ["90%", "600px"],
            maxWidth: "100%",
            position: "absolute",
            top: ["75px", "100px"],
            right: [20, 30],
            borderRadius: "15px",
            p: "20px",
            fontSize: ["18px", "22px"],
            mx: ["auto", 0],
            pb: "10px",
            zIndex: 10,
          }}
        >
          <Paragraph
            className="marker"
            sx={{
              color: "white",
              whiteSpace: "pre-wrap",
              fontSize: ["26px", "26px"],
              lineHeight: ["32px", "32px"],
            }}
          >
            {message}
          </Paragraph>
          {mints?.map((mint) => {
            const isRegistered = userMints?.find(
              (userMint) => userMint.mint_id == mint.id
            )
            const registerText = isRegistered ? `Unregister` : `Register`
            const registerPath = isRegistered
              ? `unregister-mint`
              : `register-mint`

            return (
              <>
                {isRegistered && (
                  <Link
                    className="marker"
                    sx={{
                      textAlign: "center",
                      position: "relative",
                      top: 0,
                      color: "#f25100 !important",
                      whiteSpace: "nowrap",
                      zIndex: 1005,
                      border: "4px solid #1c1c1c",
                      bg: "#1c1c1c",
                      py: "12px",
                      width: ["100%", 350],
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "24px",
                      userSelect: "none",
                      display: "block",
                      marginTop: "25px",
                      textDecoration: "none",
                      textTransform: "uppercase",
                      "&:hover": {
                        bg: "#2f2f2f",
                        borderColor: "#2f2f2f",
                      },
                    }}
                    href={`/mint/${mint.access_token}`}
                  >
                    {`Mint Link`}
                  </Link>
                )}
                {!isRegistered && (
                  <Link
                    className="marker"
                    sx={{
                      textAlign: "center",
                      position: "relative",
                      top: 0,
                      color: "#f25100 !important",
                      whiteSpace: "nowrap",
                      zIndex: 1005,
                      border: "4px solid #1c1c1c",
                      bg: "#1c1c1c",
                      py: "12px",
                      width: ["100%", 350],
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "24px",
                      userSelect: "none",
                      display: "block",
                      marginTop: "25px",
                      textDecoration: "none",
                      textTransform: "uppercase",
                      "&:hover": {
                        bg: "#2f2f2f",
                        borderColor: "#2f2f2f",
                      },
                    }}
                    href={`/${registerPath}/${mint.id}/${mint.access_token}`}
                  >
                    {`${registerText} ${mint.name} Mint`}
                  </Link>
                )}
              </>
            )
          })}
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 15,
            left: ["unset", 0],
            right: 0,
            zIndex: 5,
            maxWidth: "200px",
            mx: "auto",
          }}
        >
          <Grid
            sx={{
              gridTemplateColumns: "1fr auto auto 1fr",
              alignItems: "center",
            }}
          >
            <span></span>
            <Paragraph sx={{ color: "white", whiteSpace: "pre-wrap" }}>
              {"Powered by\nSGS Minting"}
            </Paragraph>
            <Img
              src={SGSLogo}
              sx={{
                width: ["60px"],
              }}
            />
            <span></span>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default HomeWolf
