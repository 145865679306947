import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Grid,
  Image as Img,
  Paragraph,
  Link,
} from "theme-ui"
import { theme } from "../theme"
import Image1 from "images/drrs-armory-interior.jpg"
import Scramble from "react-scramble"
import Typewriter from "typewriter-effect"
import AudioPlayer from "./AudioPlayer"

import CC1 from "images/cc1.jpg"
import CC2 from "images/cc2.jpg"
import CC3 from "images/cc3.jpg"
import CC4 from "images/cc4.jpg"
import CC5 from "images/cc5.jpg"
import CC6 from "images/cc6.jpg"

const message = "CONCLAVE JUMP NOTES — "

const message2 =
  "\nEACH TIME YOU MINT 3 WEAPONS YOU RECEIVE 1 CRAFTING COMPONENT"

const messages = [message, message2]

const images = [CC1, CC2, CC3, CC4, CC5, CC6]

export const DrrsWeaponsCC = (props) => {
  const [index, setIndex] = useState(0)

  const craftingComponemts = [
    "Crafting_01.mp4",
    "Crafting_02.mp4",
    "Crafting_03.mp4",
    "Crafting_04.mp4",
    "Crafting_05.mp4",
    "Crafting_06.mp4",
  ]

  useEffect(() => {
    setTimeout(() => {
      setIndex(1)
    }, 1500)
  }, [])

  const translations = [
    ["50px 50px", "100px 100px"],
    ["-50px 50px", "0px 100px"],
    ["50px 0px", "-100px 100px"],
    ["-50px 0px", "100px -100px"],
    ["50px -50px", "0px -100px"],
    ["-50px -50px", "-100px -100px"],
  ]

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "white",
          width: "100%",
          height: ["calc(100vh - 90px)"],
          bg: "#000000",
          backgroundImage: `url(${Image1})`,
          backgroundRepeat: "no-repeat",
          objectFit: "cover",
          backgroundSize: ["auto 100%", "100% auto"],
        }}
      >
        <Box sx={{ px: 25, maxWidth: "1200px", m: "auto" }}>
          <Box
            className="pulse"
            sx={{
              position: "relative",
              border: "3px solid #e0400f",
              width: "100%",
              p: 15,
              bg: "rgba(0, 0, 0, 0.75)",
              mr: 25,
              mt: 15,
              overflow: "auto",
              display: "flex",
              transition: "all 750ms",
              minHeight: ["102px", "120px"],
              "&:hover": {
                bg: "rgba(0, 0, 0, 0.90)",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: ["8px", 15],
                top: ["8px", 15],
                color: "#e0400f",
                zIndex: 33,
              }}
            >
              <AudioPlayer src="cc2.mp3" />
            </Box>
            <Paragraph
              className="marker"
              sx={{
                color: "#e0400f",
                fontSize: ["16px", "18px"],
                whiteSpace: "pre-wrap",
                mb: 0,
                width: "100%",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Scramble
                  autoStart
                  text={"CC-53CR37 | ACCESS GRANTED"}
                  speed="slow"
                  steps={[
                    {
                      roll: 6,
                      action: "+",
                      type: "all",
                    },
                    {
                      action: "-",
                      type: "all",
                    },
                  ]}
                />
              </Box>
              {index > 0 && (
                <Typewriter
                  key={`typewriter-${index}`}
                  options={{
                    delay: 45,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(messages[0])
                      .pauseFor(1500)
                      .typeString(messages[1])
                      .start()
                  }}
                />
              )}
            </Paragraph>
          </Box>
        </Box>

        <Box
          sx={{
            mt: "25px",
            display: "grid",
            gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr"],
            gridGap: "25px",
            width: "100%",
            position: "relative",
            alignItems: ["space-between", "center"],
            px: 15,
            maxWidth: "1100px",
            mx: "auto",
          }}
        >
          {craftingComponemts.map((cc, index) => (
            <Box
              m="auto"
              sx={{
                width: [150, 175],
                transition: "all 300ms",
                position: "relative",
                zIndex: 3333,
                "&:hover": {
                  transform: ["scale(1.75)", "scale(2.25)"],
                  zIndex: 3334,
                  translate: translations[index],
                },
              }}
            >
              <video
                width="100%"
                height="auto"
                autoPlay
                muted
                loop
                preload="metadata"
                poster={images[index]}
              >
                <source src={cc} type="video/mp4" />
              </video>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            color: "white",
            width: "100%",
            position: "relative",
            top: 10,
            pb: 15,
          }}
        >
          <Box>
            <Link href="/" sx={{ textDecoration: "none" }}>
              <Box className="pulse" sx={buttonStyles2}>
                <Scramble
                  autoStart
                  text={`BACK`}
                  speed="slow"
                  steps={[
                    {
                      roll: 6,
                      action: "+",
                      type: "all",
                    },
                    {
                      action: "-",
                      type: "all",
                    },
                  ]}
                />
              </Box>
            </Link>
          </Box>
          {/* <Box>
            <Box className="pulse" sx={buttonStyles2}>
              <Scramble
                autoStart
                text={`MINT`}
                speed="slow"
                steps={[
                  {
                    roll: 6,
                    action: "+",
                    type: "all",
                  },
                  {
                    action: "-",
                    type: "all",
                  },
                ]}
              />
            </Box>
          </Box> */}
        </Box>

        <Box
          sx={{
            bg: "transparent",
            width: ["90%", "600px"],
            maxWidth: "100%",
            position: "absolute",
            top: ["75px", "100px"],
            right: [20, 30],
            borderRadius: "15px",
            p: "20px",
            fontSize: ["18px", "22px"],
            mx: ["auto", 0],
            pb: "10px",
            zIndex: 10,
          }}
        ></Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  color: "#e0400f",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "3px solid #e0400f",
  bg: "rgba(0, 0, 0, 0.7)",
  py: "12px",
  px: 25,
  mx: "auto",
  minWidth: "200px",
  cursor: "pointer",
  fontSize: "20px",
  userSelect: "none",
  display: "inline-block",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "25px",
  fontFamily: '"Electrolize", system-ui, sans-serif !important',
  textDecoration: "none",
  textTransform: "uppercase",
  transition: "all 750ms",
  "&:hover": {
    bg: "rgba(0, 0, 0, 0.90)",
  },
}

const buttonStyles2 = {
  textAlign: "center",
  position: "relative",
  minHeight: "60px",
  color: "#e0400f",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "3px solid #e0400f",
  bg: "rgba(0, 0, 0, 0.7)",
  py: "12px",
  px: 15,
  mx: "auto",
  width: ["80%", 250],
  minWidth: ["unset", "200px"],
  cursor: "pointer",
  fontSize: "18px",
  userSelect: "none",
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "25px",
  fontFamily: '"Electrolize", system-ui, sans-serif !important',
  textDecoration: "none",
  textTransform: "uppercase",
  transition: "all 750ms",
  "&:hover": {
    bg: "rgba(0, 0, 0, 0.90)",
  },
}

export default DrrsWeaponsCC
