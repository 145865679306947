import React from "react"
import { ThemeProvider, Box, Image as Img, Paragraph } from "theme-ui"
import { theme } from "../theme"
import Image1 from "images/deadstore.jpg"
import Scramble from "react-scramble"
import Typewriter from "typewriter-effect"

export const DRRSStoreInactive = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "#000000",
          width: "100%",
          minHeight: "calc(100vh - 70px)",
          color: "#e0400f",
          px: 25,
          height: "100%",
        }}
      >
        <Img
          src={Image1}
          sx={{
            width: "100%",
            height: "calc(100vh - 70px)",
            position: "fixed",
            objectFit: "cover",
            objectPosition: "top",
            top: 70,
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        <Box
          sx={{
            bg: "transparent",
            maxWidth: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            borderRadius: "15px",
            fontSize: ["16px", "22px"],
            pb: "40px",
            zIndex: 10,
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              height: "100%",
              gridGap: 20,
              pb: "85vh",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: ["1fr"],
                gridGap: 20,
                mx: "auto",
                mt: [30, 150],
                width: "100%",
                maxWidth: 800,
              }}
            >
              <Box
                className="pulse"
                sx={{
                  border: "3px solid #e0400f",
                  width: "100%",
                  p: 25,
                  position: "relative",
                  minHeight: 350,
                }}
              >
                <Paragraph
                  sx={{
                    fontSize: ["26px"],
                    width: "100%",
                    textTransform: "uppercase",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      bg: "black",
                      opacity: 0.9,
                      zIndex: -1,
                    }}
                  />
                  <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("Loading store details ...")
                      .pauseFor(1500)
                      .typeString('\n\nPermissions not met\nAccess Denied')
                      .start()
                  }}
                  options={{
                    delay: 35,
                  }}
                />
              </Paragraph>
                  {/* <Scramble
                    autoStart
                    text={"Permissions not met\n\nAccess Denied"}
                    speed="slow"
                    steps={[
                      {
                        roll: 9,
                        action: "+",
                        type: "all",
                      },
                      {
                        action: "-",
                        type: "all",
                      },
                    ]}
                  /> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const linkStyles = {
  fontSize: ["22px", "26px"],
  textTransform: "uppercase",
  mb: 20,
  cursor: "pointer",
  color: "#962a0c",
  "&:hover": {
    color: "#e0400f",
  },
}

export default DRRSStoreInactive
