import React, { useEffect, useState } from "react"
import { Box, Image as Img, Paragraph } from "theme-ui"

import Meme1 from "images/sgs-1.jpeg"

function preloadImage(url) {
  var img = new Image()
  img.src = url
}

const memes = [
  {
    img: Meme1,
    caption: "SHHHHH",
  }
]

const getRandomMemeNumber = (previous) => {
  let num = Math.floor(Math.random() * memes.length)
  if (num == previous) num = Math.floor(Math.random() * memes.length)
  if (num == previous) num = Math.floor(Math.random() * memes.length)
  return num
}

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export const RandomMemes = (props) => {
  const [memeIndex, setSetMemeIndex] = useState(0)
  const [shuffledMemes, setShuffledMemes] = useState(memes)

  useEffect(() => {
    const newMemes = shuffle(memes)
    setShuffledMemes(newMemes)

    const firstFive = newMemes.slice(0, 5)

    firstFive.forEach((meme) => {
      preloadImage(meme.img)
    })
  }, [])

  const updateIndex = () => {
    let newMemeIndex = memeIndex + 1
    if (newMemeIndex > memes.length - 1) newMemeIndex = 0
    setSetMemeIndex(newMemeIndex)

    let preloadIndex = memeIndex + 5
    if (preloadIndex <= memes.length - 1) {
      preloadImage(shuffledMemes?.[preloadIndex]?.img)
    }
  }

  return (
    <Box>
      <Paragraph
        className="marker"
        sx={{ ...buttonStyles, mt: 0, mb: 15, top: "-5px" }}
        onClick={updateIndex}
      >
        {"MEME ME"}
      </Paragraph>
      <Img
        src={shuffledMemes[memeIndex]?.img}
        sx={{
          width: ["100%"],
          position: "relative",
          zIndex: 5,
          maxWidth: "400px",
          mx: "auto",
        }}
      />
      <Paragraph
        className="marker"
        sx={{
          color: "black",
          whiteSpace: "pre-wrap",
          fontSize: ["22px", "26px"],
          mb: 10,
          mt: 15,
        }}
      >
        {shuffledMemes[memeIndex]?.caption}
      </Paragraph>
    </Box>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  top: 10,
  color: "white !important",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "4px solid black",
  bg: "black",
  py: "12px",
  width: ["100%", 300],
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "24px",
  userSelect: "none",
  display: "block",
  marginTop: "25px",
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    bg: "#1c1c1c",
    borderColor: "#1c1c1c",
  },
}

export default RandomMemes
