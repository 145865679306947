import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Grid,
  Image as Img,
  Paragraph,
  Link,
} from "theme-ui"
import { theme } from "../theme"
import Image1 from "images/drrs-armory-interior.jpg"
import Scramble from "react-scramble"
import Typewriter from "typewriter-effect"
import AudioPlayer from "./AudioPlayer"
import ConnectWallet from "../ConnectWalletDrrs"

const stren =
  "<span class='weapon-title'>WEAPON FOCUS  |  STR</span>\n\
WEAPON CLASS\n\
BRUTE\n\
RONIN\n\
LIFELEECH\n\
FANATIC\n\
TEMPLAR\n\
KENSAI\n\
SAMURAI ..."

const agil =
  "\n\n<span class='weapon-title'>WEAPON FOCUS  |  AGIL</span>\n\
WEAPON CLASS\n\
CUTPURSE\n\
OUTLAW\n\
PHLEBOTOMIST\n\
STIMFREAK\n\
YAMABUSHI\n\
PRESDIGITATOR\n\
MECHINJA ..."

const wit =
  "\n\n<span class='weapon-title'>WEAPON FOCUS  |  WIT</span>\n\
WEAPON CLASS\n\
NOVICE\n\
DISCIPLE\n\
VAMPYRE\n\
FLAGELLANT\n\
SHUKENJA\n\
NEUROMANCER\n\
STREET SEER ..."

const legendary =
  "\n\n<span class='weapon-title'>SPECIAL WEAPON CLASS</span>\nL3G3ND4RY"

const crafted =
  "\n\n<span class='weapon-title'>SPECIAL WEAPON CLASS</span>\nCR4F73D"

const messages = [stren, agil, wit, legendary, crafted]

export const HomeDrrsWeapons = ({ isWl, wlStart, publicStart }) => {
  const [connected, setConnected] = useState(null)
  const [hover, setHover] = useState(false)

  const isWlStart = new Date() >= new Date(wlStart)
  const isPublicStart = new Date() >= new Date(publicStart)
  let message = `THE MINT IS NOT ACTIVE YET\n\nMINT GOES LIVE APRIL 22nd\n\nWL:               10am PST  |  1pm EST  |  5pm UTC\nPUBLIC:    1pm PST  |  4pm EST  |  8pm UTC`
  if (isWlStart)
    message = "WL MINT IS ACTIVE\n\nPUBLIC:    1pm PST  |  4pm EST  |  8pm UTC"
  if (isPublicStart) message = "PUBLIC MINT IS OVER"

  // # Mole Federation Armory Key:  8dc65bb6a57ef18b7e97a1ee379b5c0af003cb92f01c4c3910281bb6
  // # Dead Rabbit Depot Key:  f60ee3ef108c483e03e4c59109e4438bed8d3d2138a6350a58fcf906
  const moleKey = "8dc65bb6a57ef18b7e97a1ee379b5c0af003cb92f01c4c3910281bb6"
  const depotKey = "f60ee3ef108c483e03e4c59109e4438bed8d3d2138a6350a58fcf906"

  const moleKeys =
    connected?.balance?.assets?.filter((nft) => nft.policy == moleKey) || []

  const depotKeys =
    connected?.balance?.assets?.filter((nft) => nft.policy == depotKey) || []

  const hasKeys = connected && (moleKeys?.length > 0 || depotKeys?.length > 0)

  if (connected && !hasKeys)
    message += "\n\nWallet connected but no WL keys detected"

  const showLink =
    (isWlStart && isWl) || (isWlStart && hasKeys) || isPublicStart

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "white",
          width: "100%",
          height: ["calc(100vh - 70px)"],
          bg: "#000000",
          backgroundImage: `url(${Image1})`,
          backgroundRepeat: "no-repeat",
          objectFit: "cover",
          backgroundSize: ["auto 100%", "100% auto"],
          // overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: ["flex", "grid"],
            flexDirection: "column",
            // gridTemplateColumns: ["1fr", null, "69% auto"],
            gridTemplateColumns: ["1fr"],
            width: "100%",
            position: "relative",
            height: "100%",
            pr: [0, 15],
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: [40, 50],
              top: [60, 50],
              color: "#e0400f",
              zIndex: 69,
            }}
          >
            <AudioPlayer src="home1.mp3" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 30,
              color: "#e0400f",
              textTransform: "uppercase",
            }}
          >
            <Box sx={{ px: 25, maxWidth: "600px", m: "auto" }}>
              <Box
                className="pulse"
                sx={{
                  position: "relative",
                  border: "3px solid #e0400f",
                  width: "100%",
                  py: 20,
                  bg: "rgba(0, 0, 0, 0.8)",
                  mr: 25,
                  mt: 15,
                  overflow: "auto",
                  display: "flex",
                  transition: "all 750ms",
                  "&:hover": {
                    bg: "rgba(0, 0, 0, 1)",
                  },
                }}
              >
                <Paragraph
                  className="marker"
                  sx={{
                    color: "#e0400f",
                    fontSize: ["14px", "18px"],
                    whiteSpace: "pre-wrap",
                    mb: 0,
                    width: "100%",
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>{message}</Box>
                </Paragraph>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns:
                !showLink && !isWlStart ? ["1fr", "1fr 1fr"] : "1fr 1fr",
              color: "white",
              width: "100%",
              position: "relative",
              top: ["60%", "70%"],
              pb: ["20px", 0],
              bottom: 0,
              textAlign: "center",
            }}
          >
            <Box>
              {isWlStart || isPublicStart ? (
                <Link href="/CC-53CR37" sx={{ textDecoration: "none" }}>
                  <Box className="pulse" sx={buttonStyles}>
                    <Scramble
                      autoStart
                      text={`CC-53CR37`}
                      speed="slow"
                      steps={[
                        {
                          roll: 6,
                          action: "+",
                          type: "all",
                        },
                        {
                          action: "-",
                          type: "all",
                        },
                      ]}
                    />
                  </Box>
                </Link>
              ) : (
                <Box sx={{ position: "relative" }}>
                  {hover && (
                    <Box
                      className="pulse"
                      sx={{
                        ...buttonStyles,
                        fontSize: ["18px", "20px"],
                        position: "absolute",
                        bottom: 80,
                        minHeight: 139,
                        left: 0,
                        right: 0,
                        mx: "auto",
                        whiteSpace: "pre-wrap",
                        width: [250, 400],
                        lineHeight: "40px",
                        bg: "rgba(0, 0, 0, 0.8)",
                      }}
                    >
                      {`Nice try.\nDiscover the secret on Mint Day.`}
                    </Box>
                  )}
                  <Box
                    className="pulse-grey"
                    sx={{
                      ...buttonStyles,
                      cursor: "not-allowed",
                      "&:hover": {
                        border: "3px solid grey",
                        color: "grey",
                      },
                    }}
                    onMouseOver={() => {
                      setHover(true)
                    }}
                    onMouseLeave={() => {
                      setHover(false)
                    }}
                  >
                    <Scramble
                      autoStart
                      text={`CC-53CR37`}
                      speed="slow"
                      steps={[
                        {
                          roll: 6,
                          action: "+",
                          type: "all",
                        },
                        {
                          action: "-",
                          type: "all",
                        },
                      ]}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {/* <Box sx={{ position: "relative" }}>
            <Box
              className="pulse"
              sx={{
                border: "3px solid #e0400f",
                width: ["calc(100% - 50px)", "100%"],
                p: 25,
                position: ["relative", "absolute"],
                minHeight: 350,
                maxHeight: [500, "unset"],
                bg: "rgba(0, 0, 0, 0.75)",
                mr: 25,
                ml: [25, 0],
                mt: 25,
                top: 0,
                bottom: 25,
                overflow: "auto",
                display: "flex",
                flexDirection: "column-reverse",
                transition: "all 750ms",
                "&:hover": {
                  bg: "rgba(0, 0, 0, 0.90)",
                },
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  right: 25,
                  top: 25,
                  color: "#e0400f",
                }}
              >
                <AudioPlayer src="home1.mp3" />
              </Box>
              <Paragraph
                className="marker"
                sx={{
                  color: "#e0400f",
                  fontSize: ["18px", "23px"],
                  whiteSpace: "pre-wrap",
                  mb: 0,
                  "& .weapon-title": {
                    fontSize: ["22px", "26px"],
                  },
                }}
              >
                <Typewriter
                  key={`typewriter-${index}`}
                  options={{
                    delay: 45,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(index == 0 ? "LOADING ..." : "")
                      .pauseFor(index == 0 ? 3000 : 0)
                      .deleteAll()
                      .typeString(messages[0])
                      .pauseFor(2500)
                      .typeString(messages[1])
                      .pauseFor(2500)
                      .typeString(messages[2])
                      .pauseFor(2500)
                      .typeString(messages[3])
                      .pauseFor(1500)
                      .typeString(messages[4])
                      .pauseFor(1500)
                      .typeString("\n\nINITIATE RELOAD ...")
                      .pauseFor(2500)
                      .callFunction(() => {
                        setIndex(index + 1)
                      })
                      .start()
                  }}
                />
              </Paragraph>
            </Box>
          </Box> */}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  minHeight: "60px",
  color: "#e0400f",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "3px solid #e0400f",
  bg: "rgba(0, 0, 0, 0.7)",
  py: "12px",
  px: 15,
  mx: "auto",
  width: ["80%", 300],
  minWidth: ["unset", "200px"],
  cursor: "pointer",
  fontSize: ["18px", "22px"],
  userSelect: "none",
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "25px",
  fontFamily: '"Electrolize", system-ui, sans-serif !important',
  textDecoration: "none",
  textTransform: "uppercase",
  transition: "all 750ms",
  "&:hover": {
    bg: "rgba(0, 0, 0, 0.90)",
  },
}

export default HomeDrrsWeapons
