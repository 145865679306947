import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Grid,
  Image as Img,
  Paragraph,
  Input,
  Select,
  Link,
} from "theme-ui"
import { theme } from "../theme"
import Image1 from "images/deadstore.jpg"
import Typewriter from "typewriter-effect"
import AudioPlayer from "./AudioPlayer"

import { BLOCKFROST_API_KEY } from "../Constants"

const ArmoryKey = "/armory-key.gif"
const DepotKey = "/depot-key.gif"
const KismetKey = "/kismet-key.gif"
const MoleHq = "/mole-hq.gif"

const images = {
  "STORE-ARMORY": ArmoryKey,
  "STORE-DEPOT": DepotKey,
  "STORE-KISMET": KismetKey,
  "STORE-MOLE": MoleHq,
}

import axios from "axios"
import ConnectWallet from "../ConnectWalletDrrs"

export const DRRSStore = (props) => {
  const blockfrostApiKey = BLOCKFROST_API_KEY
  const userId = props.user_id
  const csrf = props.csrf

  let activeOrder = props.active_order || {}
  // console.log("activeOrder", activeOrder)

  const storeWallet =
    "addr1v80z5zth5nfmtnjncwttmv7a0unl3e6nl509smeg9qcyg9qaf6f8y"
  const deadWallet =
    activeOrder?.nft?.pay_address ||
    "addr1q97hx4ywp0ww5qewlta074y9jnylns56u6cd87e4s8pal2x05g9rc4gpjpennnmf7288y6lz4y8ywtqgy46lmzet6hpsfxmm33"

  // real policies
  const deadPolicy = "6194158d24d71eca5cc5601c45de123bf78d02c297e851be2608810a"
  const deadName = "DEAD"

  // test policies
  // const deadPolicy = "a0028f350aaabe0545fdcb56b039bfb08e4bb4d8c4d7c3c7d481c235" // hosky
  // const deadName = "HOSKY"

  const image = activeOrder?.nft?.image
    ? activeOrder?.nft?.image +
      "?pinataGatewayToken=Z6fVNonkqUQxdxwNN1robHyrswH6-opcZo9G3ufqstE-dQfTej-YtpuO1zDaFm8C"
    : images[activeOrder?.nft.token_type]

  if (!blockfrostApiKey) return null
  if (!userId) return null

  const [connected, setConnected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [walletLoading, setWalletLoading] = useState(false)
  const [loadingText, setLoadingText] = useState("")
  const [order, setOrder] = useState(activeOrder)
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(null)
  const [typing, setTyping] = useState(false)

  const hasPendingTx = order && order?.tx_hash && !order?.asset_tx_hash
  const orderCompleted = order && order?.tx_hash && order?.asset_tx_hash

  const dead = connected?.balance?.assets?.find(
    (nft) => nft.policy == deadPolicy
  )
  const deadAmount = dead?.quantity
  const hasMinDead = true

  const txSubmitted = order.tx_created_at ? new Date(order.tx_created_at) : null
  const txSubmittedText = txSubmitted
    ? `${txSubmitted.toLocaleDateString()} - ${txSubmitted.toLocaleTimeString()}`
    : txSubmitted

  const txStatus = orderCompleted ? "Confirmed" : "Pending"

  useEffect(() => {
    refresh()
    if (order && !order.asset_tx_hash && order.tx_status != "failed") {
      setTimeout(refresh, 5000)
    }

    if (order && order.tx_errors) {
      const newErrors = order.tx_errors?.split(";;") || []
      setErrors([
        "There was an error. Re-sign the tx and try again.",
        ...newErrors,
      ])
    }
  }, [])

  const handleConnect = (connectedWallet) => {
    setConnected(connectedWallet)
  }

  async function refresh() {
    console.log("refresh")
    if (!order?.id || !activeOrder?.id) return null

    const resp = await axios.get(`/orders/${activeOrder?.id}`, {
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

    const orderData = resp?.data

    const isDifferent = JSON.stringify(order) != JSON.stringify(orderData)

    if (isDifferent && orderData?.id) {
      setOrder(orderData)

      if (orderData.tx_hash != order.tx_hash) {
        setSuccess(
          "Your transaction was successfully submitted\n\nIt is pending. The asset should be sent to you shortly"
        )
      }

      if (orderData.asset_tx_hash || order.asset_tx_hash) {
        setSuccess("")
      }

      if (orderData.tx_errors) {
        const newErrors = orderData.tx_errors?.split(";;") || []
        setSuccess("")
        setErrors([
          "There was an error. Re-sign the tx and try again.",
          ...newErrors,
        ])
      }
    }

    if (!orderData.asset_tx_hash) {
      const delay = orderData.tx_hash ? 30000 : 10000
      setTimeout(refresh, delay)
    }
  }

  const handleMintClick = () => {
    setLoading(true)
    setLoadingText("Building TX ...")

    const createTransaction = async () => {
      let paymentAddress = await connected.getAddress() //  wallet address
      const userWallet = paymentAddress
      const priceAmount = parseInt(order.total)

      const deadPayment = [
        { unit: `${deadPolicy}.${deadName}`, quantity: priceAmount },
      ]

      let dummyNftsMeta = {
        TEST: {
          Test: "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
          Publisher:
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
          Twitter:
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
        },
      }

      let dummyMetadata = {
        721: {
          1234: dummyNftsMeta,
        },
      }

      const assetsValid = true

      let recipients = [
        {
          address: deadWallet,
          amount: "0",
          assets: deadPayment,
        },
        {
          address: storeWallet,
          amount: "2",
        },
      ]

      let utxos = null
      let netId = null
      let transaction = null

      if (assetsValid) {
        try {
          utxos = await connected.getUtxosHex()
          netId = await connected.getNetworkId()
          transaction = await connected.transaction({
            PaymentAddress: paymentAddress,
            recipients: recipients,
            metadata: dummyMetadata,
            addMetadata: false,
            utxosRaw: utxos,
            networkId: netId?.id || 1,
            ttl: 7200,
          })
        } catch (err) {
          let errMessage = err?.message
          if (errMessage == "INPUTS_EXHAUSTED")
            errMessage =
              "UXTOs exhausted - You don't have enough funds or it's too many utxos.\n\nTry sending yourself all/most of your DEAD to your same wallet. That will put all the DEAD on one utxo. Then resign the tx and try again."
          console.error(err)
          setLoading(false)
          setErrors(["Oh no! There was an error building the tx.", errMessage])
        }
      } else {
        setLoading(false)
        setErrors(["Oh no! There was an error building the tx."])
      }

      let witnessBuyer = null
      try {
        witnessBuyer = await connected.signTx(transaction, true)
        setLoadingText("Validating TX ...")
      } catch {
        // if user clicks cancel or Nami throws an error
        setLoading(false)
      }

      if (witnessBuyer) {
        try {
          let resp = null
          resp = await axios.post(
            `/orders/process/${order.id}`,
            {
              transaction,
              witness_buyer: witnessBuyer,
              user_wallet: userWallet,
            },
            {
              headers: {
                "X-CSRF-Token": csrf,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )

          if (resp?.data?.success) {
            setSuccess(
              "Confirmed ...\n\nYour transaction has been submitted to be processed."
            )
            setLoading(false)
            setErrors([])
            const newOrder = {
              ...order,
              tx_status: "submitted",
              witness_buyer: witnessBuyer,
            }
            setOrder(newOrder)

            // start polling for order status
            setTimeout(refresh, 5000)
          } else {
            console.log(resp?.data)
            const backendErrors = resp?.data?.errors || []
            setLoading(false)
            setErrors([
              "Something went wrong and we couldn't create the transaction. Try again or contact the DRRS team to let them know.",
              ...backendErrors,
            ])
          }
        } catch (err) {
          console.error(err)
          setLoading(false)
          setErrors([
            "Oh no! Something went wrong. Try it again or contact the SGS team to let them know.",
            err,
          ])
        }
      } else {
        console.error(witnessBuyer)
        setLoading(false)
      }
    }
    if (!loading) createTransaction()
    // send transaction, witnessBuyer, order, and user to backend
  }

  // console.log("success", success)
  // console.log("hasPendingTx", hasPendingTx)
  // console.log("orderCompleted", orderCompleted)

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "#000000",
          width: "100%",
          minHeight: "calc(100vh - 70px)",
          color: "#e0400f",
          px: 25,
        }}
      >
        <Box
          sx={{
            width: 400,
            height: 600,
            position: "fixed",
            bottom: 0,
            left: [150],
            zIndex: 7,
            cursor: "pointer",
          }}
          onClick={() => window.location.replace("/")}
        />
        <Img
          src={Image1}
          sx={{
            width: "100%",
            height: "calc(100vh - 70px)",
            position: "fixed",
            objectFit: "cover",
            objectPosition: "top",
            top: 70,
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        <Box
          sx={{
            p: "20px",
            width: "100%",
            textAlign: "right",
            position: "relative",
            zIndex: 20,
          }}
        >
          <ConnectWallet
            blockfrostApiKey={blockfrostApiKey}
            handleConnect={handleConnect}
            handleLoading={setWalletLoading}
            styles={{ top: 0, mt: 0 }}
            popupStyles={{ top: "75px" }}
          />
        </Box>
        <Box
          className="pulse"
          sx={{
            maxWidth: "100%",
            position: "relative",
            display: "flex",
            maxWidth: ["100%", 700],
            ml: "auto",
            flexDirection: "column",
            fontSize: ["16px", "22px"],
            pb: "40px",
            zIndex: 10,
            color: "#e0400f",
            padding: ["25px"],
            border: "3px solid #e0400f",
            mt: 20,
            minHeight: 350,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              bg: "black",
              opacity: 0.9,
              zIndex: -1,
            }}
          />
          <Box sx={{ position: "absolute", right: 25, top: 25 }}>
            <AudioPlayer src="/Racing_Through_The_Galaxy.wav" />
          </Box>
          {!connected && (
            <Box key={walletLoading}>
              <Paragraph
                className="marker"
                sx={{
                  fontSize: ["22px", "26px"],
                }}
              >
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .callFunction(() => {
                        console.log("started typing")
                        setTyping(true)
                      })
                      .typeString(
                        walletLoading
                          ? "Connecting wallet ..."
                          : "You must connect your wallet ..."
                      )
                      .start()
                  }}
                  options={{
                    delay: 35,
                  }}
                />
              </Paragraph>
            </Box>
          )}

          {connected && !activeOrder?.id && (
            <Box key={walletLoading}>
              <Paragraph
                className="marker"
                sx={{
                  fontSize: ["22px", "26px"],
                  whiteSpace: "pre-wrap",
                }}
              >
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .callFunction(() => {
                        setTyping(true)
                      })
                      .typeString(
                        "There was an issue creating your order\n\nContact support to let them know"
                      )
                      .start()
                  }}
                  options={{
                    delay: 35,
                  }}
                />
              </Paragraph>
            </Box>
          )}

          {connected && activeOrder?.id && (
            <Box>
              {errors && errors.length > 0 && (
                <Box>
                  {errors?.map((error) => (
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "#e0400f",
                        whiteSpace: "pre-wrap",
                        fontSize: ["20px"],
                        textAlign: "left",
                        mb: 30,
                        mt: 0,
                        mx: "auto",
                        maxWidth: 1000,
                      }}
                    >
                      {error}
                    </Paragraph>
                  ))}
                </Box>
              )}

              {success && (
                <Box>
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "#e0400f",
                      fontSize: ["22px", "26px"],
                      whiteSpace: "pre-wrap",
                      my: 20,
                    }}
                  >
                    <Typewriter
                      options={{
                        strings: success,
                        autoStart: true,
                        delay: 35,
                      }}
                    />
                  </Paragraph>
                </Box>
              )}

              {hasPendingTx && !success && (
                <>
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "#e0400f",
                      whiteSpace: "pre-wrap",
                      fontSize: ["22px", "26px"],
                      mb: 30,
                    }}
                  >
                    <Typewriter
                      options={{
                        strings: `You have a pending tx\nWait for the tx to be confirmed\n\nOur system checks the blockchain every couple minutes`,
                        autoStart: true,
                        delay: 35,
                      }}
                    />
                  </Paragraph>
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "#e0400f",
                      fontSize: ["22px", "26px"],
                      position: "absolute",
                      bottom: 25,
                      right: 25,
                      ml: "auto",
                      textAlign: "right",
                      width: "100%",
                      cursor: "pointer",
                      "& a": {
                        color: "#e0400f",
                      },
                    }}
                  >
                    <a href={`/`}>Back</a>
                  </Paragraph>
                </>
              )}

              {order && [null, "errors"].includes(order.tx_status) && (
                <Box sx={{}}>
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "#e0400f",
                      whiteSpace: "pre-wrap",
                      fontSize: ["22px", "26px"],
                      mb: 20,
                    }}
                  >
                    {`Open Order`}
                  </Paragraph>
                  <Img
                    src={image}
                    sx={{
                      width: 175,
                    }}
                  />
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "#e0400f",
                      whiteSpace: "pre-wrap",
                      fontSize: ["22px"],
                    }}
                  >
                    {`${activeOrder?.nft?.name}\n${parseInt(
                      order.total
                    )} ${deadName}`}
                  </Paragraph>

                  {!walletLoading &&
                    (!order.witness_buyer || order.tx_errors) && (
                      <Paragraph
                        className="marker"
                        sx={buttonStyles}
                        onClick={loading ? null : handleMintClick}
                      >
                        {loading
                          ? loadingText
                          : order.tx_errors
                          ? "Re-sign Tx"
                          : "Sign Tx"}
                      </Paragraph>
                    )}
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "#e0400f",
                      fontSize: ["22px", "26px"],
                      mt: 30,
                      ml: "auto",
                      textAlign: "right",
                      width: "100%",
                      cursor: "pointer",
                      "& a": {
                        color: "#e0400f",
                      },
                    }}
                  >
                    <a href={`/`}>Back</a>
                  </Paragraph>
                </Box>
              )}

              {orderCompleted && (
                <Box>
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "#e0400f",
                      whiteSpace: "pre-wrap",
                      fontSize: ["22px", "26px"],
                      mb: 30,
                    }}
                  >
                    <Typewriter
                      onInit={(typewriter) => {
                        typewriter
                          .typeString(
                            `Your order has been confirmed\n\n${order?.nft?.name} was sent to your wallet ... `
                          )
                          .callFunction(() => {
                            setTimeout(() => {
                              setTyping(false)
                            }, 500)
                          })
                          .start()
                      }}
                      options={{
                        delay: 35,
                      }}
                    />
                  </Paragraph>

                  {!typing && (
                    <Box>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "#e0400f",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px", "26px"],
                          mt: 30,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          cursor: "pointer",
                          "& a": {
                            color: "#27c124",
                          },
                        }}
                      >
                        {`Tx Hash: `}
                        <a
                          href={`https://cardanoscan.io/transaction/${order.tx_hash}`}
                          target="_blank"
                        >
                          {order.tx_hash}
                        </a>
                      </Paragraph>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "#e0400f",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px", "26px"],
                          mt: 30,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          cursor: "pointer",
                          "& a": {
                            color: "#27c124",
                          },
                        }}
                      >
                        {`Asset Sent Tx Hash: `}
                        {order.asset_tx_hash ? (
                          <a
                            href={`https://cardanoscan.io/transaction/${order.asset_tx_hash}`}
                            target="_blank"
                          >
                            {order.asset_tx_hash}
                          </a>
                        ) : (
                          "Asset Tx Pending"
                        )}
                      </Paragraph>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "#e0400f",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px", "26px"],
                          mt: 30,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          cursor: "pointer",
                          mb: 10,
                        }}
                      >
                        {`Tx Submitted: ${txSubmittedText}`}
                      </Paragraph>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "#e0400f",
                          fontSize: ["22px", "26px"],
                          mt: 30,
                          ml: "auto",
                          textAlign: "right",
                          width: "100%",
                          cursor: "pointer",
                          "& a": {
                            color: "#e0400f",
                          },
                        }}
                      >
                        <a href={`/`}>Back</a>
                      </Paragraph>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  color: "#e0400f !important",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "4px solid #1c1c1c",
  bg: "#1c1c1c",
  py: "4px",
  width: ["100%"],
  maxWidth: 400,
  cursor: "pointer",
  fontSize: "20px",
  userSelect: "none",
  display: "block",
  marginTop: 25,
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    bg: "#2f2f2f",
    borderColor: "#2f2f2f",
  },
}

export default DRRSStore
