import React, { useEffect, useState } from "react"
import { Box, Grid, Spinner, Image } from "theme-ui"
import CardanoWallet, { Cardano, HexToAscii } from "../nami-js/nami"
import { BLOCKFROST_API_KEY } from "./Constants"

const supportedWallets = [
  "nami",
  "eternl",
  "flint",
  "lace",
  "typhoncip30",
  "vespr",
]

export const ConnectWallet = (props) => {
  const blockfrostApiKey = BLOCKFROST_API_KEY
  const hidden = props.hidden
  const showAda = props.showAda
  const styles = props.styles || {}
  const popupStyles = props.popupStyles || {}
  const handleConnect = props.handleConnect || (() => {})
  const handleLoading = props.handleLoading || (() => {})
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(false)
  const [loading, setLoading] = useState(null)
  const [connected, setConnected] = useState(null)

  // real policies
  const deadPolicy = "6194158d24d71eca5cc5601c45de123bf78d02c297e851be2608810a"
  const deadName = "DEAD"

  // test policies
  // const deadPolicy = "a0028f350aaabe0545fdcb56b039bfb08e4bb4d8c4d7c3c7d481c235" // hosky
  // const deadName = "HOSKY"

  const deadAmount =
    connected?.balance?.assets?.find((nft) => nft.policy == deadPolicy)
      ?.quantity || 0

  // console.log(deadAmount)

  const setWallet = (walletName) => {
    connectWallet(walletName)
    setSelected(walletName)
    setOpen(false)
  }

  let icons = []
  supportedWallets.forEach((supportedWallet) => {
    if (window?.cardano?.[supportedWallet]) {
      icons.push(window?.cardano?.[supportedWallet]?.icon)
    }
  })

  const connectWallet = async (walletName) => {
    let wallet = null
    let walletData = window?.cardano?.[walletName]
    if (!walletData && walletName == "eternl") window?.cardano?.["ccvault"]

    if (walletData?.name) {
      setLoading(true)
      const S = await Cardano()
      try {
        await walletData.enable().then(async (enabledWallet) => {
          wallet = new CardanoWallet(S, enabledWallet, blockfrostApiKey)

          const address = wallet.getAddress()
          // console.log("address", address)
          const balance = await wallet.getBalance()
          // console.log("balance", balance)

          wallet.balance = balance
          wallet.address = address
          wallet.icon = walletData.icon
          sessionStorage.setItem("walletName", walletName)
          setConnected(wallet)
          handleConnect(wallet)
          setLoading(false)
        })
      } catch {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    const existingWallet = sessionStorage.getItem("walletName")
    if (existingWallet) {
      connectWallet(existingWallet)
      setSelected(existingWallet)
    }
  }, [])

  useEffect(() => {
    handleLoading(loading)
  }, [loading])

  return (
    <>
      {open && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "1000px",
            height: "100vh",
            zIndex: 42069,
          }}
          onClick={() => setOpen(false)}
        ></Box>
      )}

      <Box
        sx={{
          position: "relative",
          display: hidden ? "none" : "inline-block",
          zIndex: 42070,
        }}
      >
        <Box
          className="pulse"
          sx={{ ...buttonStyles, ...styles }}
          onClick={() => !loading && setOpen(!open)}
        >
          {loading && <Spinner size={28} sx={{ color: "#e0400f" }} />}
          {!loading && connected && (
            <Grid
              sx={{
                gridTemplateColumns: "1fr auto auto auto 1fr",
                alignItems: "center",
              }}
            >
              <span />
              <Image
                src={connected.icon}
                sx={{
                  width: "30px",
                }}
              />
              <span>
                {showAda
                  ? `${(connected?.balance?.lovelace / 1000000)?.toFixed(1)} ₳`
                  : `${deadAmount} ${deadName}`}
              </span>
              <span />
            </Grid>
          )}
          {!loading && !connected && "CONNECT WALLET"}
        </Box>
        {open && (
          <Box
            sx={{
              position: "absolute",
              top: "95px",
              bg: "black",
              width: ["100%", 300],
              border: "3px solid #e0400f",
              zIndex: 2000,
              p: "5px",
              ...popupStyles,
            }}
          >
            {supportedWallets.map((supportedWallet) => {
              const icon = window?.cardano?.[supportedWallet]?.icon

              if (
                window?.cardano?.[supportedWallet]?.experimental &&
                window?.cardano?.[supportedWallet]?.experimental
                  ?.vespr_compat === true
              ) {
                return
              }

              if (
                window?.cardano?.[supportedWallet]?.name?.includes("via VESPR")
              ) {
                return
              }

              if (!icon) return null

              return (
                <Grid
                  sx={selectStyles}
                  onClick={() => setWallet(supportedWallet)}
                >
                  <Image
                    src={icon}
                    sx={{
                      width: "30px",
                      mr: "auto",
                      textTransform: "capitalize",
                    }}
                  />
                  {supportedWallet?.replace("cip30", "")}
                </Grid>
              )
            })}
            {icons.length == 0 && (
              <Grid
                sx={{
                  ...selectStyles,
                  textAlign: "center",
                  py: 3,
                  gridTemplateColumns: "1fr",
                }}
              >
                No Wallets Detected
              </Grid>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

const selectStyles = {
  p: "8px",
  gridTemplateColumns: "auto 1fr",
  alignItems: "left",
  textAlign: "left",
  borderRadius: "3px",
  fontFamily: '"Electrolize", system-ui, sans-serif !important',

  cursor: "pointer",
  alignItems: "center",
  color: "#e0400f",
  textTransform: "uppercase",
  "&:hover": { bg: "#1c1c1c" },
}

const buttonStyles = {
  textAlign: "center",
  fontFamily: '"Electrolize", system-ui, sans-serif !important',

  position: "relative",
  minHeight: "60px",
  color: "#e0400f",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "3px solid #e0400f",
  bg: "black",
  py: "12px",
  px: 15,
  width: ["100%", 300],
  minWidth: "200px",
  cursor: "pointer",
  fontSize: "22px",
  userSelect: "none",
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "25px",
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    bg: "#1c1c1c",
  },
}

export default ConnectWallet
