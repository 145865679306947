import React, { useEffect, useState } from "react"
import { Box, Paragraph, Input } from "theme-ui"

export const PassesChecker = (props) => {
  const usedPasses = props.usedPasses
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(false)
  const [value, setValue] = useState("")

  useEffect(() => {
    //TBOIGenesis047
    if (value.length === 3) {
      const number = parseInt(value)
      if (number > 0 && number <= 100) {
        const passUsed = usedPasses.find(
          (pass) => pass === `TBOIGenesis${value}`
        )
        if (passUsed) {
          setMessage("This pass has already been used")
        } else {
          setMessage("This pass has not been used")
        }
      } else {
        setMessage("This Genesis Pass does not exist")
      }
    } else {
      setMessage("")
    }
  }, [value])

  return (
    <>
      {open && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "1000px",
            height: "100vh",
            zIndex: 2000,
          }}
          onClick={() => setOpen(false)}
        ></Box>
      )}

      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          zIndex: 2100,
          width: "100%",
        }}
      >
        <Box
          className="marker"
          sx={{ ...buttonStyles }}
          onClick={() => setOpen(!open)}
        >
          Check Genesis Passes
        </Box>
        {open && (
          <Box
            sx={{
              position: "absolute",
              top: "65px",
              bg: "white",
              width: ["100%", 500],
              borderRadius: "8px",
              zIndex: 2000,
              minHeight: "200px",
              p: "20px",
            }}
          >
            <Paragraph
              sx={{
                color: "black",
                whiteSpace: "pre-wrap",
                fontSize: ["18px", "20px"],
                lineHeight: ["24px"],
                cursor: "pointer",
              }}
              onClick={() => setShowTips(!showTips)}
            >
              Enter the 3 digit number of your Genesis Pass to see if it's been
              used
            </Paragraph>

            <Input
              sx={{
                color: "black",
                border: "1px solid black",
                my: "20px",
              }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />

            <Paragraph
              sx={{
                color: "black",
                whiteSpace: "pre-wrap",
                fontSize: ["18px", "20px"],
                lineHeight: ["22px", "24px"],
                cursor: "pointer",
              }}
              onClick={() => setShowTips(!showTips)}
            >
              {message}
            </Paragraph>
          </Box>
        )}
      </Box>
    </>
  )
}

const buttonStyles = {
  textAlign: "left",
  position: "relative",
  height: "60px",
  color: "white",
  whiteSpace: "nowrap",
  zIndex: 100005,
  bg: "transparent",
  py: "12px",
  width: ["100%", 300],
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "22px",
  userSelect: "none",
  display: "inline-block",
  textDecoration: "none",
}

export default PassesChecker
