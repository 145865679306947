import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Image as Img,
  Paragraph,
  Checkbox,
  Link,
} from "theme-ui"
import { theme } from "../theme"
import Image1 from "images/deadstore.jpg"
import Scramble from "react-scramble"

import AudioPlayer from "./AudioPlayer"
import useStickyState from "./StickyHook"
import { BLOCKFROST_API_KEY } from "../Constants"

const ArmoryKey = "/armory-key.gif"
const DepotKey = "/depot-key.gif"
const KismetKey = "/kismet-key.gif"
const MoleHq = "/mole-hq.gif"

const images = {
  "STORE-ARMORY": ArmoryKey,
  "STORE-DEPOT": DepotKey,
  "STORE-KISMET": KismetKey,
  "STORE-MOLE": MoleHq,
}

import axios from "axios"
import ConnectWallet from "../ConnectWalletDrrs"

export const DRRSStore = (props) => {
  const blockfrostApiKey = BLOCKFROST_API_KEY
  const userId = props.user_id
  const locked = parseInt(props.locked_coin || 0)
  let nfts = props.nfts ? JSON.parse(props.nfts) : []
  const csrf = props.csrf

  // real policies
  const deadPolicy = "6194158d24d71eca5cc5601c45de123bf78d02c297e851be2608810a"
  const deadName = "DEAD"

  // test policies
  // const deadPolicy = "a0028f350aaabe0545fdcb56b039bfb08e4bb4d8c4d7c3c7d481c235" // hosky
  // const deadName = "HOSKY"

  if (!blockfrostApiKey) return null
  if (!userId) return null

  const [connected, setConnected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [walletLoading, setWalletLoading] = useState(false)
  const [auctionTimes, setAuctionTimes] = useState({})
  const [openBid, setOpenBid] = useState(null)
  const [bidValue, setBidValue] = useState("")
  const [bidText, setBidText] = useState("")
  const [auctionNfts, setAuctionNfts] = useState(nfts)
  const [rescramble, setRescramble] = useState(1)
  const [lockedCoin, setLockedCoin] = useState(locked)

  const [selected, setSelected] = useStickyState("active", "selected")
  const [agree, setAgree] = useStickyState(false, "agree")

  let sortedNfts = auctionNfts
  if (selected == "active") {
    sortedNfts = auctionNfts.filter((nft) => {
      const now = new Date().getTime()
      const timeleft = Date.parse(nft.auction_end) - now
      const expired = timeleft <= 0
      return !expired
    })

    sortedNfts = sortedNfts.sort((a, b) => {
      return Date.parse(a.auction_end) - Date.parse(b.auction_end)
    })
  }

  if (selected == "won") {
    sortedNfts = auctionNfts.filter((nft) => {
      const now = new Date().getTime()
      const timeleft = Date.parse(nft.auction_end) - now
      const expired = timeleft <= 0
      const isUser = userId == nft?.top_bid?.user_id
      return expired && isUser
    })

    sortedNfts = sortedNfts.sort((a, b) => {
      return Date.parse(b.auction_end) - Date.parse(a.auction_end)
    })

    sortedNfts = sortedNfts.sort((a, b) => {
      return a.claimed === b.claimed ? 0 : a.claimed ? 1 : -1
    })
  }

  if (selected == "expired") {
    sortedNfts = auctionNfts.filter((nft) => {
      const now = new Date().getTime()
      const timeleft = Date.parse(nft.auction_end) - now
      const expired = timeleft <= 0
      return expired
    })

    sortedNfts = sortedNfts.sort((a, b) => {
      return Date.parse(b.auction_end) - Date.parse(a.auction_end)
    })

    sortedNfts = sortedNfts.sort((a, b) => {
      return a.claimed === b.claimed ? 0 : a.claimed ? 1 : -1
    })
  }

  // update auction times
  useEffect(() => {
    updateAuctionTimes()

    const interval = setInterval(() => {
      updateAuctionTimes()
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const updateAuctionTimes = () => {
    let newTimes = {}

    auctionNfts.forEach((nft) => {
      if (nft.auction_end) {
        const now = new Date().getTime()
        const timeleft = Date.parse(nft.auction_end) - now

        if (timeleft >= -3000) {
          const days = Math.floor(timeleft / (1000 * 60 * 60 * 24))
          const hours = Math.floor(
            (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          )
          const minutes = Math.floor(
            (timeleft % (1000 * 60 * 60)) / (1000 * 60)
          )
          const seconds = Math.floor((timeleft % (1000 * 60)) / 1000)

          // if an auction ends, refresh the data to get the top bid
          if (days == -1 && hours == -1 && minutes == -1 && seconds == -1) {
            // console.log(`refreshing for ${nft.name} auction end`)
            refreshAuctionData()
          }

          newTimes[nft.name] = {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
          }
        }
      }
    })

    setAuctionTimes(newTimes)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      refreshAuctionData()
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  const handleConnect = (connectedWallet) => {
    // console.log("connectedWallet", connectedWallet)
    setConnected(connectedWallet)
  }

  const handleBidClick = async (nft) => {
    setLoading(nft.id)

    const bid = parseInt(bidValue)
    const nftMinPrice = parseInt(nft.min_price || 0)
    const minPrice = parseInt(nft.top_bid?.price || 0)

    const deadAmount =
      (connected?.balance?.assets?.find((nft) => nft.policy == deadPolicy)
        ?.quantity || 0) - lockedCoin
    // console.log("deadAmount", deadAmount)

    if (!bid) {
      setBidText("Invalid value")
      return
    }

    if (!connected) {
      setBidText("Your wallet must be connected to place a bid")
      return
    }

    if (bid <= minPrice) {
      setBidText(`Bid must be higher than the top bid`)
      return
    }

    if (bid < nftMinPrice) {
      setBidText(`Bid must be higher than bid minimum`)
      return
    }

    if (bid > deadAmount) {
      setBidText(
        `The bid is higher than the amount of avialable ${deadName} in your wallet (${deadAmount})`
      )
      return
    }

    try {
      const resp = await axios.post(
        "/bids",
        {
          bid: {
            nft_id: nft.id,
            user_id: userId,
            price: bidValue,
          },
        },
        {
          headers: {
            "X-CSRF-Token": csrf,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      setLoading(false)
      refreshAuctionData()

      if (resp?.data?.id) {
        setBidText("CONFIRMED")

        setTimeout(() => {
          setOpenBid(null)
          setBidText("")
          setBidValue("")
        }, 1000)
      } else {
        setBidText(resp?.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleKeyDown = (event, nft) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()

      const bid = parseInt(bidValue)
      if (agree && bid) {
        handleBidClick(nft)
      }
    }
  }

  const refreshAuctionData = async () => {
    const resp = await axios.get(`/auction-data`, {
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

    const auctionData = resp?.data

    const isDifferent =
      JSON.stringify(auctionNfts) != JSON.stringify(auctionData)
    if (isDifferent && auctionData?.nfts) {
      setAuctionNfts(auctionData?.nfts)
      setLockedCoin(auctionData?.locked_coin)
    }
  }

  const handleBidDelete = async (id) => {
    if (confirm("Are you sure you want to delete this bid?") == true) {
      try {
        const resp = await axios.delete(`/bids/${id}`, {
          headers: {
            "X-CSRF-Token": csrf,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })

        if (resp?.data?.deleted == true) {
          refreshAuctionData()
        } else {
          alert(resp?.data?.error)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const isConfirmed = bidText == "CONFIRMED"
  const noAuctions = sortedNfts.length == 0
  const topPrice = openBid?.top_bid?.price || 0
  const minBidPrice = openBid?.min_price || 0
  const hasNoBids = topPrice == 0 && !isConfirmed
  const topBidPrice = isConfirmed ? bidValue : openBid?.top_bid?.price || 0

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "#000000",
          width: "100%",
          minHeight: "calc(100vh - 70px)",
          color: "#e0400f",
          px: 25,
          height: "100%",
        }}
      >
        <Img
          src={Image1}
          sx={{
            width: "100%",
            height: "calc(100vh - 70px)",
            position: "fixed",
            objectFit: "cover",
            objectPosition: "top",
            top: 70,
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        <Box
          sx={{
            bg: "transparent",
            maxWidth: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            borderRadius: "15px",
            fontSize: ["16px", "22px"],
            pb: "40px",
            zIndex: 10,
            height: "100%",
          }}
        >
          <Box sx={{ pt: 20, width: "100%", textAlign: "right" }}>
            <ConnectWallet
              blockfrostApiKey={blockfrostApiKey}
              handleConnect={handleConnect}
              handleLoading={setWalletLoading}
              styles={{ top: 0, mt: 0 }}
              popupStyles={{ top: "75px" }}
            />

            <Box>
              <Paragraph
                title="DEAD that is tied up in open bids"
                sx={{
                  fontSize: ["22px"],
                  width: "100%",
                  textAlign: "right",
                  mt: 1,
                  py: 12,
                  textTransform: "uppercase",
                }}
              >
                <Scramble
                  autoStart
                  text={`LOCKED: ${parseInt(lockedCoin)} DEAD`}
                  speed="slow"
                  steps={[
                    {
                      roll: 6,
                      action: "+",
                      type: "all",
                    },
                    {
                      action: "-",
                      type: "all",
                    },
                  ]}
                />
              </Paragraph>
            </Box>
          </Box>

          {openBid && (
            <Box>
              <Box
                sx={{
                  position: "fixed",
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  right: 0,
                  top: 0,
                  bottom: 0,
                  bg: "black",
                  opacity: 0.9,
                  zIndex: 999999,
                }}
                onClick={() => {
                  setOpenBid(null)
                  setBidText("")
                  setBidValue("")
                }}
              ></Box>
              <Box
                className="pulse-green"
                sx={{
                  position: "fixed",
                  width: ["90%", "600px"],
                  top: "200px",
                  minHeight: "300px",
                  left: 0,
                  right: 0,
                  mx: "auto",
                  textAlign: "center",
                  zIndex: 9999999,
                  bg: "black",
                  color: "#27c124",
                  padding: ["25px"],
                  border: "3px solid #27c124",
                }}
              >
                {bidText && (
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "#27c124",
                      whiteSpace: "pre-wrap",
                      fontSize: ["22px", "26px"],
                      mb: 30,
                      mt: 10,
                    }}
                  >
                    {bidText}
                  </Paragraph>
                )}

                <Paragraph
                  className="marker"
                  sx={{
                    color: "#27c124",
                    whiteSpace: "pre-wrap",
                    fontSize: ["22px", "26px"],
                    mb: 30,
                    mt: 10,
                  }}
                >
                  {hasNoBids
                    ? `Min Bid: ${parseInt(minBidPrice)} ${deadName}`
                    : `Top Bid: ${parseInt(topBidPrice)} ${deadName}`}
                </Paragraph>

                <Paragraph
                  className="marker"
                  sx={{
                    color: "#27c124",
                    whiteSpace: "pre-wrap",
                    fontSize: ["22px", "26px"],
                    mb: 30,
                    mt: 30,
                  }}
                >
                  {`Place your bid for ${openBid.name}`}
                </Paragraph>
                <input
                  style={{
                    fontFamily: "'Electrolize', system-ui, sans-serif",
                    width: "80%",
                    margin: "auto",
                    padding: "10px 15px",
                    border: "2px solid #27c124",
                    background: "black",
                    outline: "none",
                    color: "#27c124",
                    fontSize: "24px",
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                  type="number"
                  autoFocus
                  min={openBid.top_bid?.price}
                  value={bidValue}
                  onChange={(e) => setBidValue(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, openBid)}
                />

                <Box
                  sx={{
                    display: "grid",
                    mt: 20,
                    gridTemplateColumns: "1fr auto 70% 1fr",
                    "svg path": {
                      color: "#27c124",
                    },
                  }}
                >
                  <Box />
                  <Box onClick={() => setAgree(!agree)}>
                    <Checkbox
                      sx={{
                        "& input:checked~*": {
                          color: "#27c124",
                        },
                      }}
                      checked={agree}
                    />
                  </Box>

                  <Paragraph
                    className="marker"
                    sx={{
                      color: "#27c124",
                      whiteSpace: "pre-wrap",
                      fontSize: ["18px"],
                      mb: 0,
                    }}
                  >
                    If I win this auction, I pledge to claim it, or lose my
                    $DEADSTORE privileges.
                  </Paragraph>
                  <Box />
                </Box>

                <Paragraph
                  sx={{
                    width: "300px",
                    mx: "auto",
                    fontSize: ["22px", "26px"],
                    mt: "25px",
                    cursor: "pointer",
                    fontFamily: "'Electrolize', system-ui, sans-serif",
                    opacity: agree && bidValue ? 1 : 0.3,
                  }}
                  onClick={() => agree && bidValue && handleBidClick(openBid)}
                >
                  SUBMIT
                </Paragraph>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: ["1fr", "70% auto"],
              height: "100%",
              gridGap: 20,
              pb: "85vh",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: ["1fr"],
                gridGap: 20,
                mx: "auto",
                mt: 30,
                width: "100%",
                maxWidth: 800,
              }}
            >
              {noAuctions && (
                <Box
                  className="pulse"
                  key={rescramble}
                  sx={{
                    border: "3px solid #e0400f",
                    width: "100%",
                    p: 25,
                    position: "relative",
                    minHeight: 350,
                  }}
                >
                  <Paragraph
                    sx={{
                      fontSize: ["26px"],
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: "100%",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        bg: "black",
                        opacity: 0.9,
                        zIndex: -1,
                      }}
                    />
                    <Scramble
                      autoStart
                      text={"No auctions to display"}
                      speed="slow"
                      steps={[
                        {
                          roll: 6,
                          action: "+",
                          type: "all",
                        },
                        {
                          action: "-",
                          type: "all",
                        },
                      ]}
                    />
                  </Paragraph>
                </Box>
              )}
              {sortedNfts.map((nft, index) => {
                const image = nft?.image
                  ? nft?.image +
                    "?pinataGatewayToken=Z6fVNonkqUQxdxwNN1robHyrswH6-opcZo9G3ufqstE-dQfTej-YtpuO1zDaFm8C"
                  : images[nft.token_type]

                const days = auctionTimes[nft.name]?.days
                const hours = auctionTimes[nft.name]?.hours
                const minutes = auctionTimes[nft.name]?.minutes
                const seconds = auctionTimes[nft.name]?.seconds

                const now = new Date().getTime()
                const timeleft = Date.parse(nft.auction_end) - now
                const expired = timeleft <= 0
                const canClaim = timeleft <= -10000
                const hasTime = days || hours || minutes || seconds

                // console.log("nft.name", timeleft)

                let bidTime = ""
                if (days) bidTime += `${days} Days `
                if (hours) bidTime += `${hours} Hours `
                if (minutes) bidTime += `${minutes} Mins `
                bidTime += `${seconds} Sec`

                const userIsTopBid = userId == nft?.top_bid?.user_id && !expired

                return (
                  <Box
                    key={`${nft.id}-${rescramble}`}
                    className="pulse"
                    sx={{
                      border: "3px solid #e0400f",
                      width: "100%",
                      p: 25,
                      position: "relative",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: "100%",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        bg: "black",
                        opacity: 0.9,
                        zIndex: -1,
                      }}
                    />
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: ["1fr", "3fr 4fr"],
                        gridGap: ["10px", "25px"],
                      }}
                    >
                      <Box>
                        <Img
                          src={image}
                          sx={{
                            width: "100%",
                            maxWidth: 250,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{ cursor: userIsTopBid ? "not-allowed" : "auto" }}
                      >
                        <Paragraph
                          sx={{
                            fontSize: ["26px"],
                            width: "100%",
                            textAlign: "left",
                            py: 12,
                            textTransform: "uppercase",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <Scramble
                            autoStart={index <= 5}
                            text={nft.title || nft.name}
                            speed="slow"
                            steps={[
                              {
                                roll: 6,
                                action: "+",
                                type: "all",
                              },
                              {
                                action: "-",
                                type: "all",
                              },
                            ]}
                          />
                        </Paragraph>

                        <Box
                          onClick={() => {
                            if (userIsTopBid) {
                              handleBidDelete(nft?.top_bid?.id)
                            }
                          }}
                        >
                          <Paragraph
                            sx={{
                              fontSize: ["20px"],
                              width: "100%",
                              textAlign: "left",
                              whiteSpace: "pre-wrap",
                              pt: 12,
                            }}
                          >
                            <Scramble
                              autoStart={index <= 5}
                              text={
                                nft.top_bid
                                  ? `Top Bid:`
                                  : nft.min_price
                                  ? `Min Bid: ${parseInt(nft?.min_price)} DEAD`
                                  : "No Bids"
                              }
                              speed="slow"
                              steps={[
                                {
                                  roll: 6,
                                  action: "+",
                                  type: "all",
                                },
                                {
                                  action: "-",
                                  type: "all",
                                },
                              ]}
                            />
                          </Paragraph>

                          {nft.top_bid && (
                            <Paragraph
                              sx={{
                                fontSize: ["20px"],
                                width: "100%",
                                textAlign: "left",
                                whiteSpace: "pre-wrap",
                                pb: 12,
                              }}
                            >
                              <Scramble
                                autoStart={index <= 5}
                                text={`${parseInt(
                                  nft.top_bid.price
                                )} ${deadName} - ${nft.top_bid.user}`}
                                speed="slow"
                                steps={[
                                  {
                                    roll: 6,
                                    action: "+",
                                    type: "all",
                                  },
                                  {
                                    action: "-",
                                    type: "all",
                                  },
                                ]}
                              />
                            </Paragraph>
                          )}
                        </Box>

                        {!expired && hasTime && (
                          <Box>
                            <Paragraph
                              sx={{
                                fontSize: ["20px"],
                                width: "100%",
                                textAlign: "left",
                                whiteSpace: "pre-wrap",
                                pt: 12,
                              }}
                            >
                              <Scramble
                                autoStart={index <= 5}
                                text="Time Left:"
                                speed="slow"
                                steps={[
                                  {
                                    roll: 8,
                                    action: "+",
                                    type: "all",
                                  },
                                  {
                                    action: "-",
                                    type: "all",
                                  },
                                ]}
                              />
                            </Paragraph>
                            <Paragraph
                              sx={{
                                fontSize: ["20px"],
                                width: "100%",
                                textAlign: "left",
                                whiteSpace: "nowrap",
                                pb: 12,
                              }}
                            >
                              <Scramble
                                autoStart={index <= 5}
                                text={bidTime}
                                speed="slow"
                                steps={[
                                  {
                                    roll: 8,
                                    action: "+",
                                    type: "all",
                                  },
                                  {
                                    action: "-",
                                    type: "all",
                                  },
                                ]}
                              />
                            </Paragraph>

                            {connected && (
                              <Paragraph
                                className="marker"
                                sx={buttonStyles}
                                onClick={() => setOpenBid(nft)}
                              >
                                <Scramble
                                  autoStart={index <= 5}
                                  text="New Bid"
                                  speed="slow"
                                  steps={[
                                    {
                                      roll: 8,
                                      action: "+",
                                      type: "all",
                                    },
                                    {
                                      action: "-",
                                      type: "all",
                                    },
                                  ]}
                                />
                              </Paragraph>
                            )}
                          </Box>
                        )}
                        {expired && (
                          <Box>
                            <Paragraph
                              sx={{
                                fontSize: ["20px"],
                                width: "100%",
                                textAlign: "left",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              <Scramble
                                autoStart={index <= 5}
                                text={nft?.claimed ? "CLAIMED" : "UNCLAIMED"}
                                speed="slow"
                                steps={[
                                  {
                                    roll: 6,
                                    action: "+",
                                    type: "all",
                                  },
                                  {
                                    action: "-",
                                    type: "all",
                                  },
                                ]}
                              />
                            </Paragraph>
                          </Box>
                        )}
                        {expired &&
                          canClaim &&
                          userId == nft?.top_bid?.user_id && (
                            <Box sx={{ mt: 30 }}>
                              <Link
                                href={`/orders/store/${nft.id}`}
                                sx={{ textDecoration: "none" }}
                              >
                                <Paragraph className="marker" sx={buttonStyles}>
                                  <Scramble
                                    autoStart={index <= 5}
                                    text={
                                      nft?.claimed ? "See Details" : "Claim NFT"
                                    }
                                    speed="slow"
                                    steps={[
                                      {
                                        roll: 6,
                                        action: "+",
                                        type: "all",
                                      },
                                      {
                                        action: "-",
                                        type: "all",
                                      },
                                    ]}
                                  />
                                </Paragraph>
                              </Link>
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            </Box>
            <Box sx={{ height: "100%", width: "100%" }}>
              <Box
                className="pulse"
                sx={{
                  maxWidth: "100%",
                  width: "100%",
                  position: "sticky",
                  top: 50,
                  zIndex: 10,
                  color: "#e0400f",
                  padding: ["25px"],
                  border: "3px solid #e0400f",
                  mt: 30,
                  minHeight: 350,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bg: "black",
                    opacity: 0.9,
                    zIndex: -1,
                  }}
                />
                <Paragraph
                  sx={{
                    ...linkStyles,
                    color: selected == "active" ? "#e0400f" : "#962a0c",
                  }}
                  onClick={() => {
                    setSelected("active")
                    setRescramble(rescramble + 1)
                  }}
                >
                  <Scramble
                    autoStart
                    text="Active Auctions"
                    speed="slow"
                    steps={[
                      {
                        roll: 5,
                        action: "+",
                        type: "all",
                      },
                      {
                        action: "-",
                        type: "all",
                      },
                    ]}
                  />
                </Paragraph>
                <Paragraph
                  sx={{
                    ...linkStyles,
                    color: selected == "won" ? "#e0400f" : "#962a0c",
                  }}
                  onClick={() => {
                    setSelected("won")
                    setRescramble(rescramble + 1)
                  }}
                >
                  <Scramble
                    autoStart
                    text="Won Auctions"
                    speed="slow"
                    steps={[
                      {
                        roll: 7,
                        action: "+",
                        type: "all",
                      },
                      {
                        action: "-",
                        type: "all",
                      },
                    ]}
                  />
                </Paragraph>
                <Paragraph
                  sx={{
                    ...linkStyles,
                    color: selected == "expired" ? "#e0400f" : "#962a0c",
                  }}
                  onClick={() => {
                    setSelected("expired")
                    setRescramble(rescramble + 1)
                  }}
                >
                  <Scramble
                    autoStart
                    text="Expired Auctions"
                    speed="slow"
                    steps={[
                      {
                        roll: 6,
                        action: "+",
                        type: "all",
                      },
                      {
                        action: "-",
                        type: "all",
                      },
                    ]}
                  />
                </Paragraph>
                <AudioPlayer />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const linkStyles = {
  fontSize: ["22px", "26px"],
  textTransform: "uppercase",
  mb: 20,
  cursor: "pointer",
  color: "#962a0c",
  "&:hover": {
    color: "#e0400f",
  },
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  whiteSpace: "nowrap",
  bg: "#962a0c",
  width: ["100%"],
  cursor: "pointer",
  fontSize: "20px",
  userSelect: "none",
  display: "block",
  marginTop: "10px",
  textDecoration: "none",
  maxWidth: 200,
  color: "black",
  borderRadius: 5,
  py: 2,
  "&:hover": {
    bg: "#e0400f",
  },
}

export default DRRSStore
