import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Grid,
  Image as Img,
  Paragraph,
  Select,
} from "theme-ui"
import { theme } from "../theme"
import SGSLogo from "images/sgs-logo-trans.png"

import axios from "axios"
import ConnectWallet from "../ConnectWallet"

import MintingTips from "../MintingTips"
import projectMetadata from "../metadata/famouswolf-metadata.json"

const soldOutGifs = [
  "https://c.tenor.com/sKg02SlxaoQAAAAC/red-goobler-solar-opposites.gif",
  "https://c.tenor.com/0hNIHb95iDMAAAAC/no-more-rebel-wilson.gif",
  "https://c.tenor.com/WejWsIlk3_cAAAAd/thats-enough-okay-jason-bateman.gif",
  "https://c.tenor.com/hKsgsuQ5N8MAAAAC/cymbals-monkey-brain.gif",
  "https://c.tenor.com/98QJc8KJsMkAAAAC/gimme-no-more.gif",
  "https://c.tenor.com/N2ibf5aQj2gAAAAC/dragrace-bitch-you-done.gif",
  "https://c.tenor.com/3DGYbblZUasAAAAd/youre-done-done-son.gif",
  "https://c.tenor.com/ig55vSh4xHwAAAAC/throw-dog.gif",
  "https://c.tenor.com/VJz7OLQ8DkQAAAAM/heather-dubrow-shannon-beador.gif",
  "https://c.tenor.com/MPsGPP25j70AAAAC/good-day-fez.gif",
  "https://c.tenor.com/J3mNIbj6A4wAAAAd/empty-shelves-john-travolta.gif",
  "https://c.tenor.com/UbGii9oGosoAAAAC/money-wallet.gif",
  "https://c.tenor.com/nd2wxsrTXYEAAAAd/level-up-next-level.gif",
  "https://c.tenor.com/WS9TNssQALkAAAAd/roughneck-rabbits-roughneck.gif",
  "https://c.tenor.com/Q6BU4RC5zQsAAAAd/sold-out-what.gif",
  "https://c.tenor.com/TK5hcjkNr_cAAAAC/gordon-ramsey-you-are-done.gif",
  "https://c.tenor.com/MC7K9jo7V0YAAAAC/survivor-jeff-probst.gif",
  "https://c.tenor.com/qYrv7LSWFFIAAAAC/finn-adventure-time.gif",
  "https://c.tenor.com/gKcLWQAVD8sAAAAC/done-annoyed.gif",
  "https://c.tenor.com/jhJp2A-K5HwAAAAd/gollum-leave-now.gif",
  "https://c.tenor.com/TbGZDsVxx0wAAAAC/i-dont-need-you-anymore-kyle-broflovski.gif",
  "https://c.tenor.com/VGmgyUMNP88AAAAC/toy-story-woody.gif",
  "https://c.tenor.com/FqdTy04G6m4AAAAC/i-dont-need-you-anymore-satan.gif",
  "https://c.tenor.com/JLpNtvfer7wAAAAM/you-need-to-go-saturday-night-live.gif",
  "https://c.tenor.com/JWTW52xCLycAAAAd/i-think-you-should-leave-before-i-call-the-police-eric-cartman.gif",
  "https://c.tenor.com/6aZotD1Q0SkAAAAC/tigger-sad.gif",
  "https://c.tenor.com/ipXfNs5DnFIAAAAd/get-the-hell-out-of-here-sung-won-cho.gif",
  "https://c.tenor.com/0Ar7aEuIPT4AAAAC/get-the-fudge-out-eric-cartman.gif",
  "https://c.tenor.com/Wl0r-dwasLYAAAAC/sad-the-simpsons.gif",
  "https://c.tenor.com/L2T_fqCrWyMAAAAC/theres-no-more-scared.gif",
  "https://c.tenor.com/dlJSiLUJNmsAAAAC/math-calculate.gif",
  "https://c.tenor.com/18sqCWkrm9oAAAAC/confused-wtf.gif",
  "https://c.tenor.com/3yyytNGYC7cAAAAC/empty-tank.gif",
  "https://c.tenor.com/q-E5wj1K6OYAAAAC/monsters-inc-sully.gif",
  "https://c.tenor.com/65QP-lH9krMAAAAC/no-money-donald-duck.gif",
  "https://c.tenor.com/lQNf6C6HDYQAAAAC/angry-kid.gif",
  "https://c.tenor.com/sGzHvagmSugAAAAC/staredown-forreal.gif",
]

const defaultWallet =
  "addr1qyrke3hmuw29c56c8f0vmg0vzarcht70m6a2788lyp7et22mlcy7y7703za4cy7zh789jnjffstkgsgk5vhr5ks6vj5qrsvlkh"

export const MintWolf = (props) => {
  const blockfrostApiKey = props.apiKey
  const userId = props.user_id
  const mintId = props.mint_id
  const userIsVip = props.is_vip
  const userIsWl = props.is_wl
  const isOverride = props.is_override
  const orders = props.orders ? JSON.parse(props.orders) : []
  const csrf = props.csrf
  const mintCount = props.mintCount
  const mint = props.mint ? JSON.parse(props.mint) : null
  const publicStart = new Date(mint?.token_start)
  const wlStart = mint?.wl_start ? new Date(mint?.wl_start) : null
  const vipStart = mint?.vip_start ? new Date(mint?.vip_start) : null

  const isPreMint = new Date() < publicStart
  const isPostVip = new Date() > vipStart
  const isPostWl = new Date() > wlStart

  const isVip = userIsVip && ((isPostVip && isPreMint) || isOverride)
  const isWl = userIsWl && ((isPostWl && isPreMint) || isOverride)

  let tokenPrice = mint.price || 80
  if (isWl) tokenPrice = mint.wl_price
  if (isVip) tokenPrice = mint.vip_price

  const walletAddress = mint.payment_wallet || defaultWallet
  const policyId = mint.policy_id
  const policyScript = mint.policy_script

  let activeOrder = null
  const sortedOrders = orders?.sort((a, b) => (a.id > b.id ? -1 : 1))
  const lastOrder = sortedOrders?.[0]
  if (lastOrder && !lastOrder?.confirmed_on_blockchain) {
    activeOrder = lastOrder
  }
  const successfulOrders = orders?.filter((order) => order.tx_hash)

  // console.log(`Patment wallet - ${walletAddress}`)
  // console.log(`Policy - ${policyId}`)
  // console.log("loaded")
  // console.log(successfulOrders)

  if (!blockfrostApiKey) return null
  if (!userId) return null

  const [connected, setConnected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [walletLoading, setWalletLoading] = useState(false)
  const [loadingText, setLoadingText] = useState("")
  const [quantity, setQuanity] = useState(1)
  const [order, setOrder] = useState(activeOrder)
  const [successOrders, setSucessOrders] = useState(successfulOrders)
  const [soldOutGif, setSoldOutGif] = useState(null)
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(null)
  const [tokenAmount, setTokenAmount] = useState(mintCount)

  const sortedSuccessOrders = successOrders?.sort((a, b) =>
    a.id > b.id ? -1 : 1
  )
  const hasPendingTx =
    order && !order?.confirmed_on_blockchain && order?.tx_hash

  const orderCreated = order?.created_at ? new Date(order?.created_at) : null
  const allowResetOrder = orderCreated?.setMinutes(
    orderCreated?.getMinutes() + 30
  )

  let orderOptions = []

  if (isPreMint) {
    orderOptions = [1, 2, 3]
  } else {
    orderOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  }

  const total = quantity * tokenPrice
  const priceText = `${quantity} x ${tokenPrice} = ${total} ADA`

  useEffect(() => {
    setSoldOutGif(soldOutGifs[Math.floor(Math.random() * soldOutGifs.length)])

    if (
      order &&
      !order.confirmed_on_blockchain &&
      order.tx_status != "failed"
    ) {
      setTimeout(refresh, 5000)
    }

    if (order && order.tx_errors) {
      const newErrors = order.tx_errors?.split(";;") || []
      setErrors([
        "Oh no! Something went wrong and the tx couldn't be created.",
        ...newErrors,
      ])
    }
  }, [])

  const handleConnect = (connectedWallet) => {
    setConnected(connectedWallet)
  }

  const handleOrderClick = async () => {
    setLoading(true)
    let notesText = "WOLF"
    if (isWl) notesText = "WL"
    if (isVip) notesText = "VIP"
    try {
      const resp = await axios.post(
        "/orders/mint-wolf",
        {
          order: {
            quantity: quantity,
            user_id: userId,
            total: total,
            notes: `${notesText} - ${priceText}`,
            order_type: "WOLF",
            mint_id: mintId,
            address: connected?.address,
          },
        },
        {
          headers: {
            "X-CSRF-Token": csrf,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      setLoading(false)

      if (resp?.data?.id) {
        setOrder(resp?.data)
        setTokenAmount(tokenAmount - quantity)
        setErrors(null)
      } else {
        setErrors(resp?.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleOrderDelete = async (o) => {
    setLoading(true)
    try {
      const resp = await axios.delete(`/orders/${o.id}`, {
        headers: {
          "X-CSRF-Token": csrf,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })

      setLoading(false)

      if (resp?.data?.deleted == true) {
        setOrder(null)
        window?.location?.reload()
      } else {
        setErrors([resp?.data?.error])
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function refresh() {
    const resp = await axios.get(`/orders/${order.id}`, {
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

    const orderData = resp?.data

    const isDifferent = JSON.stringify(order) != JSON.stringify(orderData)

    if (isDifferent) {
      setOrder(orderData)

      if (orderData.tx_hash != order.tx_hash) {
        const newOrder = {
          ...order,
          tx_hash: orderData.tx_hash,
          tx_created_at: new Date(),
        }
        setSucessOrders([...successOrders, newOrder])
        setSuccess(
          "Rad!\n\nYour transaction was successfully submitted to the blockchain.\n\nYou can find the tx below. It is pending but should show up in your wallet in a few minutes"
        )
      }

      if (orderData.confirmed_on_blockchain != order.confirmed_on_blockchain) {
        const index = successOrders.findIndex(
          (order) => order.id == orderData.id
        )
        let newOrders = [...successOrders]
        if (index >= 0) {
          newOrders[index] = orderData
        } else {
          newOrders = [...successOrders, orderData]
        }

        setSucessOrders(newOrders?.sort((a, b) => (a.id > b.id ? -1 : 1)))

        setSuccess(
          "Awesome!\nYour transaction is confirmed and on the blockchain. You can mint again!"
        )
      }

      if (orderData.tx_errors) {
        const newErrors = orderData.tx_errors?.split(";;") || []
        setSuccess("")
        setErrors([
          "Oh no! Something went wrong and the tx couldn't be created.",
          ...newErrors,
        ])
      }
    }

    if (!orderData.confirmed_on_blockchain) {
      const delay = orderData.tx_hash ? 60000 : 10000
      setTimeout(refresh, delay)
    }
  }

  const handleMintClick = () => {
    setLoading(true)
    setLoadingText("Building TX ...")

    const createTransaction = async () => {
      let paymentAddress = await connected.getAddress() //  wallet address
      let paymentAmount = String(order.total)
      const paymentWallet = walletAddress
      const userWallet = paymentAddress

      let recipients = [
        {
          address: paymentWallet,
          amount: paymentAmount,
        }, // Seller Wallet, NFT price 2ADA
        {
          address: userWallet,
          amount: "0",
          mintedAssets: order?.nfts?.map((nft) => {
            return {
              assetName: nft,
              quantity: "1",
              policyId: policyId,
              policyScript: policyScript,
            }
          }),
        }, // NFTs to be minted
      ] // list of recipients

      let nftsMeta = {}
      let dummyNftsMeta = {}

      order?.nfts?.forEach((nft, index) => {
        let dummyMeta = JSON.parse(JSON.stringify(projectMetadata[nft]))

        Object.keys(dummyMeta?.attributes).forEach((attribute) => {
          dummyMeta.attributes[attribute] =
            "FamousWolfFamousWolfFamousWolfFamousWolfFamousWolfFamousWolf"
        })
        Object.keys(dummyMeta?.fwc).forEach((attribute) => {
          dummyMeta.fwc[attribute] =
            "FamousWolfFamousWolfFamousWolfFamousWolfFamousWolfFamousWolf"
        })
        dummyMeta?.files?.forEach((file, index) => {
          Object.keys(dummyMeta.files[index]).forEach((attribute) => {
            dummyMeta.files[index][attribute] =
              "FamousWolfFamousWolfFamousWolfFamousWolfFamousWolfFamousWolf"
          })
        })

        dummyMeta.description =
          "FamousWolfFamousWolfFamousWolfFamousWolfFamousWolfFamousWolf"
        dummyMeta.image =
          "FamousWolfFamousWolfFamousWolfFamousWolfFamousWolfFamousWolf"
        dummyMeta.mediaType =
          "FamousWolfFamousWolfFamousWolfFamousWolfFamousWolfFamousWolf"
        dummyMeta.Publisher =
          "FamousWolfFamousWolfFamousWolfFamousWolfFamousWolfFamousWolf"
        dummyMeta.Twitter =
          "FamousWolfFamousWolfFamousWolfFamousWolfFamousWolfFamousWolf"

        nftsMeta[nft] = projectMetadata[nft]
        dummyNftsMeta[nft] = dummyMeta
      })

      let metadata = {
        721: {
          [policyId]: nftsMeta,
        },
      }

      let dummyMetadata = {
        721: {
          [policyId]: dummyNftsMeta,
        },
      }

      const metadataHash = connected.hashMetadata(metadata)

      let utxos = null
      let netId = null
      let transaction = null

      try {
        utxos = await connected.getUtxosHex()
        netId = await connected.getNetworkId()
        transaction = await connected.transaction({
          PaymentAddress: paymentAddress,
          recipients: recipients,
          metadata: dummyMetadata,
          metadataHash: metadataHash,
          addMetadata: false,
          utxosRaw: utxos,
          networkId: netId?.id || 1,
          ttl: 7200,
          multiSig: true,
        })
      } catch (err) {
        let errMessage = err?.message
        if (errMessage == "INPUTS_EXHAUSTED")
          errMessage =
            "INPUTS_EXHAUSTED - You don't have enough funds or utxos available"
        console.error(err)
        setLoading(false)
        setErrors(["Oh no! There was an error building the tx.", errMessage])
      }

      let witnessBuyer = null
      try {
        witnessBuyer = await connected.signTx(transaction, true)
        setLoadingText("Validating TX ...")
      } catch {
        // if user clicks cancel or Nami throws an error
        setLoading(false)
      }

      if (witnessBuyer) {
        try {
          let resp = null
          resp = await axios.post(
            `/orders/process/${order.id}`,
            {
              transaction,
              witness_buyer: witnessBuyer,
              user_wallet: userWallet,
            },
            {
              headers: {
                "X-CSRF-Token": csrf,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )

          if (resp?.data?.success) {
            setSuccess(
              "Awesome!\nYour transaction has been submitted to be processed. This page will update automatically."
            )
            setLoading(false)
            setErrors([])
            const newOrder = {
              ...order,
              tx_status: "submitted",
              witness_buyer: witnessBuyer,
            }
            setOrder(newOrder)

            // start polling for order status
            setTimeout(refresh, 5000)
          } else {
            console.log(resp?.data)
            const backendErrors = resp?.data?.errors || []
            setLoading(false)
            setErrors([
              "Oh no! Something went wrong and Nami couldn't create the transaction. Cancel the order and try again. Or contact the SGS team to let them know.",
              ...backendErrors,
            ])
          }
        } catch (err) {
          console.error(err)
          setLoading(false)
          setErrors([
            "Oh no! Something went wrong. Try it again or contact the SGS team to let them know.",
            err,
          ])
        }
      } else {
        console.error(witnessBuyer)
        setLoading(false)
      }
    }
    if (!loading) createTransaction()
    // send transaction, witnessBuyer, order, and user to backend
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "#000000",
          width: "100%",
          minHeight: "100vh",
          pb: [400],
          color: "white",
        }}
      >
        {/* <Img
          src={Image1}
          sx={{
            width: ["250px", "500px"],
            position: "fixed",
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        /> */}
        <Box
          sx={{
            bg: "transparent",
            width: ["90%", "600px"],
            maxWidth: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            ml: "auto",
            top: "15px",
            right: [20, 30],
            borderRadius: "15px",
            p: "20px",
            fontSize: ["16px", "22px"],
            pb: "40px",
            zIndex: 10,
          }}
        >
          <ConnectWallet
            blockfrostApiKey={blockfrostApiKey}
            handleConnect={handleConnect}
            handleLoading={setWalletLoading}
            styles={{ top: 0, mt: 0 }}
            popupStyles={{ top: "75px" }}
          />
          {errors?.map((error) => (
            <Paragraph
              className="marker"
              sx={{
                color: "#f25100",
                whiteSpace: "pre-wrap",
                fontSize: ["22px", "26px"],
                mb: 0,
                mt: 30,
              }}
            >
              {error}
            </Paragraph>
          ))}

          {success && (
            <Box>
              {" "}
              <Paragraph
                className="marker"
                sx={{
                  color: "white",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  my: 20,
                }}
              >
                {success}
              </Paragraph>
              {order.tx_status == "confirmed" && (
                <Paragraph
                  className="marker"
                  sx={{ ...buttonStyles, mb: "25px", mt: 0, top: 0 }}
                  onClick={() => window?.location?.reload()}
                >
                  {"Mint Again"}
                </Paragraph>
              )}
            </Box>
          )}

          {hasPendingTx && !success && (
            <>
              <Paragraph
                className="marker"
                sx={{
                  color: "white",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mb: 30,
                  mt: 30,
                }}
              >
                {`You have a pending tx/order. Wait or handle that order first before creating another.\n\nOur system checks the blockchain every couple of minutes.`}
              </Paragraph>
              <MintingTips />
            </>
          )}

          {!order && !success && !hasPendingTx && (
            <Box>
              {connected && (
                <Box>
                  {tokenAmount && tokenAmount > 0 ? (
                    <Box sx={{ mt: 30 }}>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "white",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px", "26px"],
                          lineHeight: ["24px", "32px"],
                        }}
                      >
                        {`There are ${tokenAmount} tokens available\nThey are ${tokenPrice} ADA each\n\n`}
                      </Paragraph>

                      <MintingTips subdomain="deadrabbits" />

                      <Paragraph
                        className="marker"
                        sx={{
                          color: "white",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px", "26px"],
                          lineHeight: ["24px", "32px"],
                        }}
                      >
                        {`How many do you want?`}
                      </Paragraph>
                      <Select
                        sx={{
                          border: "none",
                          bg: "white",
                          color: "black",
                          borderRadius: "10px",
                          position: "relative",
                          maxWidth: "200px",
                          zIndex: 10069,
                          fontSize: "30px",
                          outline: "none !important",
                          fontFamily:
                            '"Open Sans", system-ui, sans-serif !important',
                          py: "5px",
                          px: "25px",
                          mt: "25px",
                          textAlign: "center",
                        }}
                        value={quantity}
                        onChange={(e) => setQuanity(e.target.value)}
                      >
                        {orderOptions?.map((option) => (
                          <option>{option}</option>
                        ))}
                      </Select>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "white",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px", "26px"],
                          my: "20px",
                        }}
                      >
                        {priceText}
                      </Paragraph>
                      <Paragraph
                        className="marker"
                        sx={buttonStyles}
                        onClick={loading ? null : handleOrderClick}
                      >
                        {loading ? "Creating Order ..." : "Submit"}
                      </Paragraph>
                    </Box>
                  ) : (
                    <Box>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "white",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px", "26px"],
                          lineHeight: ["24px", "32px"],
                          mb: 20,
                          mt: 30,
                        }}
                      >
                        {`ALL THE TOKENS HAVE BEEN RESERVED!\n\nSOLD OUT!`}
                      </Paragraph>
                      <Paragraph
                        className="marker"
                        sx={{ ...buttonStyles, mb: 30, mt: 0, top: 0 }}
                        onClick={() =>
                          setSoldOutGif(
                            soldOutGifs[
                              Math.floor(Math.random() * soldOutGifs.length)
                            ]
                          )
                        }
                      >
                        {"Hit Me"}
                      </Paragraph>
                      <Img
                        src={soldOutGif}
                        sx={{
                          width: ["100%"],
                          position: "relative",
                          zIndex: 5,
                          maxWidth: "400px",
                          maxHeight: "400px",
                          objectFit: "contain",
                          objectPosition: "left",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}

          {order && [null, "errors"].includes(order.tx_status) && (
            <Box sx={{ mt: 30 }}>
              <MintingTips subdomain="deadrabbits" />

              <Paragraph
                className="marker"
                sx={{
                  color: "white",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mt: order.tx_errors ? 0 : 35,
                }}
              >
                {`Active Order\nQuantity: ${order.quantity}\nTotal: ${Number(
                  order.total
                )}\nNotes: ${order.notes}`}
              </Paragraph>
              {connected &&
                !walletLoading &&
                (!order.witness_buyer || order.tx_errors) && (
                  <Paragraph
                    className="marker"
                    sx={buttonStyles}
                    onClick={loading ? null : handleMintClick}
                  >
                    {loading
                      ? loadingText
                      : order.tx_errors
                      ? "Re-sign Tx"
                      : "Sign Tx"}
                  </Paragraph>
                )}
              {new Date() > allowResetOrder && !order.tx_hash && (
                <Paragraph
                  className="marker"
                  sx={buttonStyles}
                  onClick={() => {
                    // handleNamiSuccess("123456abc")
                    handleOrderDelete(order)
                  }}
                >
                  Cancel
                </Paragraph>
              )}
            </Box>
          )}

          {successOrders?.length > 0 && (
            <Box
              sx={{ bg: "white", p: "15px", borderRadius: "10px", mt: "35px" }}
            >
              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  pt: 0,
                }}
              >
                {`Successful Orders\n--------------------`}
              </Paragraph>
              {sortedSuccessOrders?.map((successfulOrder, index) => {
                const txSubmitted = new Date(successfulOrder.tx_created_at)
                const txSubmittedCopy = new Date(successfulOrder.tx_created_at)

                const allowResetTime = txSubmittedCopy.setHours(
                  txSubmitted.getHours() + 3
                )
                const allowResetTx =
                  new Date() > allowResetTime &&
                  successfulOrder.tx_hash &&
                  !successfulOrder.confirmed_on_blockchain

                const txSubmittedText = `${txSubmitted.toLocaleDateString()} - ${txSubmitted.toLocaleTimeString()}`
                let txStatus = successfulOrder.confirmed_on_blockchain
                  ? "Confirmed"
                  : "Pending"
                if (allowResetTx) txStatus = "Failed"
                return (
                  <Box key={successfulOrder?.id}>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        fontSize: ["22px", "26px"],
                        mt: index == 0 ? 10 : 35,
                      }}
                    >
                      {`Quantity: ${successfulOrder.quantity}`}
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                      }}
                    >
                      {`Total: ${Number(successfulOrder.total)}`}
                    </Paragraph>
                    {txStatus == "Confirmed" && (
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "black",
                          fontSize: ["22px", "26px"],
                          mt: 10,
                        }}
                      >
                        {`NFTs: ${successfulOrder.nfts.join(", ")}`}
                      </Paragraph>
                    )}

                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {`Notes: ${successfulOrder.notes}`}
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      {`Tx ${txStatus}`}
                      {txStatus == "Failed" && (
                        <>
                          {` - `}
                          <a
                            onClick={() => {
                              if (
                                window?.confirm(
                                  "Are you sure you want to reset the TX hash? This should only be done for broken or failed TXs. Only do this if it's been more than 2 hours since you submitted the tx"
                                ) &&
                                window.location
                              ) {
                                window.location.href = `/reset-order-tx/${successfulOrder.id}`
                              }
                            }}
                          >
                            Reset Order Tx
                          </a>
                          {` - Only do this if it's been more than 3 hrs since you submitted the tx`}
                        </>
                      )}
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      {`Tx Hash: `}
                      <a
                        href={`https://cardanoscan.io/transaction/${successfulOrder.tx_hash}`}
                        target="_blank"
                      >
                        {successfulOrder.tx_hash}
                      </a>
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      {`Tx Submitted: ${txSubmittedText}`}
                    </Paragraph>
                  </Box>
                )
              })}
              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mt: 10,
                }}
              >
                {`--------------------`}
              </Paragraph>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 15,
            left: ["unset", -250],
            right: 0,
            zIndex: 5,
            maxWidth: "200px",
            mx: "auto",
          }}
        >
          <Grid
            sx={{
              gridTemplateColumns: "1fr auto auto 1fr",
              alignItems: "center",
            }}
          >
            <span></span>
            <Paragraph sx={{ color: "white", whiteSpace: "pre-wrap" }}>
              {"Powered by\nSGS Minting"}
            </Paragraph>
            <Img
              src={SGSLogo}
              sx={{
                width: ["60px"],
              }}
            />
            <span></span>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  top: 10,
  color: "white !important",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "4px solid #1c1c1c",
  bg: "#1c1c1c",
  py: "12px",
  width: ["100%", 300],
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "24px",
  userSelect: "none",
  display: "block",
  marginTop: "25px",
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    bg: "#2f2f2f",
    borderColor: "#2f2f2f",
  },
}

export default MintWolf
