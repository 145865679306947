import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Grid,
  Image as Img,
  Paragraph,
  Link,
} from "theme-ui"
import { theme } from "../theme"
import Image1 from "images/rabbit-8.png"
import SGSLogo from "images/sgs-logo-trans.png"

export const HomeDRRS = (props) => {
  const mints = JSON.parse(props.mints)
  const userMints = JSON.parse(props.userMints)

  const drrsMint = mints?.find((mint) => mint.name == "DRRS")
  const drrsUserMint = userMints?.find((uMint) => uMint.mint_id == drrsMint?.id)
  const isWl = drrsUserMint?.is_wl
  const showLink = props.showLink

  let message = "\nSupremes are coming ...\n\n"
  // if (isWl) message += "You're on the WL"

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "white",
          width: "100%",
          height: ["calc(100vh + 100px)", "100vh"],
          bg: "#000000",
        }}
      >
        <Img
          src={Image1}
          sx={{
            width: ["250px", "500px"],
            position: "fixed",
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        <Box
          sx={{
            bg: "transparent",
            width: ["90%", "600px"],
            maxWidth: "100%",
            position: "absolute",
            top: ["75px", "100px"],
            right: [20, 30],
            borderRadius: "15px",
            p: "20px",
            fontSize: ["18px", "22px"],
            mx: ["auto", 0],
            pb: "10px",
            zIndex: 10,
          }}
        >
          <Paragraph
            className="marker"
            sx={{
              color: "white",
              whiteSpace: "pre-wrap",
              fontSize: ["26px", "32px"],
              lineHeight: ["22px", "32px"],
            }}
          >
            {message}
          </Paragraph>

          {showLink && (
            <Link
              className="marker"
              sx={{
                textAlign: "center",
                position: "relative",
                top: 0,
                color: "#f25100 !important",
                whiteSpace: "nowrap",
                zIndex: 1005,
                border: "4px solid #1c1c1c",
                bg: "#1c1c1c",
                py: "12px",
                width: ["100%", 350],
                borderRadius: "8px",
                cursor: "pointer",
                fontSize: "24px",
                userSelect: "none",
                display: "block",
                marginTop: "25px",
                textDecoration: "none",
                textTransform: "uppercase",
                "&:hover": {
                  bg: "#2f2f2f",
                  borderColor: "#2f2f2f",
                },
              }}
              href={`drrs-supremes`}
            >
              {`RABBIT SUPREMES`}
            </Link>
          )}
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 15,
            left: ["unset", 0],
            right: 0,
            zIndex: 5,
            maxWidth: "200px",
            mx: "auto",
          }}
        >
          <Grid
            sx={{
              gridTemplateColumns: "1fr auto auto 1fr",
              alignItems: "center",
            }}
          >
            <span></span>
            <Paragraph sx={{ color: "white", whiteSpace: "pre-wrap" }}>
              {"Powered by\nSGS Minting"}
            </Paragraph>
            <Img
              src={SGSLogo}
              sx={{
                width: ["60px"],
              }}
            />
            <span></span>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default HomeDRRS
