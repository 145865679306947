import React, { useState } from "react"
import { ThemeProvider, Box, Image as Img, Text, Link, Grid } from "theme-ui"
import { theme } from "../theme"
import AudioPlayer from "./AudioPlayer"
import Image1 from "images/arcade-bg-2.jpg"
import Goateroids from "images/goateroids.png"
import GoatInvaders from "images/goatinvaders.jpg"
import Breakout from "images/breakout.png"
import Snake from "images/snake-2.jpg"
import Pacman from "images/pacman-2.jpg"
import MortalKombat from "images/mortal-kombat.jpeg"
import SuperSmashBros from "images/super-smash-bros.png"
import Sonic from "images/sonic.png"
import MarvelVsCapcom from "images/marvel-vs-capcom.png"
import CrashTeamRacing from "images/crash-team-racing.jpeg"
import Pokemon from "images/pokemon.png"
import Bomberman from "images/bomberman.gif"
import GoldenEye from "images/goldeneye.jpg"
import Conker from "images/conkers-bad-fur-day.jpeg"
import Diddy from "images/diddy-kong-racing.jpg"
import Doom from "images/doom.jpeg"
import MarioKart from "images/mario-kart.jpeg"
import StreetFighter from "images/street-fighter-2.webp"
import Mario64 from "images/mario-64.jpeg"
import Yugi from "images/yugi.jpeg"
import TwistedMetal from "images/twisted-metal.jpg"
import Crash from "images/crash-bandicoot.jpg"
import Dbz from "images/dbz-supersonic-warriors.png"
import SuperMario from "images/super-mario-snes.png"
import DonkeyKong from "images/donkey-kong-country.jpeg"
import TonyHawk from "images/tony-hawk-pro-skater.jpeg"
import Xmen from "images/xmen.png"
import NinjaTurtles from "images/tmnt.png"

import KillerInstinct from "images/killer-instinct.jpeg"
import PixelGoat1 from "images/pixel-goat-1.png"
import PixelGoat2 from "images/pixel-goat-2.png"
import PixelGoat4 from "images/pixel-goat-4.png"
import ComicBg from "images/comics-bg.jpg"
import ComicBgMobile from "images/comics-bg-mobile.jpg"
import Comic1 from "images/comic-cover-1.jpg"

import ConnectWallet from "../ConnectWalletGt"
import GrassRegister from "./GrassRegister"
import { BLOCKFROST_API_KEY } from "../Constants"

export const arcadeSongs = [
  "/songs/arcade-champ.mp3",
  "/songs/game-time.mp3",
  "/songs/synthwave.mp3",
  "/songs/cyberpunk.mp3",
  "/songs/drop-it.mp3",
  "/songs/neon-gaming.mp3",
  "/songs/no-limits.mp3",
  "/songs/next.mp3",
  "/songs/lfgoat.mp3",
  "/songs/get-funky.mp3",
  "/songs/be-chill.mp3",
  "/songs/dream.mp3",
  "/songs/cyber-war.mp3",
  "/songs/bounce.mp3",
  "/songs/last-one.mp3",
]

export const songTitles = [
  "Arcade Champ",
  "Game Time",
  "Synthwave",
  "Cyberpunk",
  "Drop It",
  "Neon Gaming",
  "No Limits",
  "Send It",
  "LFGOAT",
  "Get Funky",
  "Be Chill",
  "Dream",
  "Cyber War",
  "Bounce",
  "Last One",
]

const payToPlay = [
  {
    name: "GOATEROIDS",
    link: "/goateroids",
    image: Goateroids,
    gameName: "goateriods",
  },
  {
    name: "GOAT INVADERS",
    link: "/goatinvaders",
    image: GoatInvaders,
    gameName: "invaders",
  },
  { name: "SNAKE", link: "/snake", image: Snake, gameName: "snake" },
  {
    name: "BREAKOUT",
    link: "/breakout",
    image: Breakout,
    gameName: "breakout",
  },
  { name: "PACMAN", link: "/pacman", image: Pacman, gameName: "pacman" },
]
const freeToPlay = [
  { name: "MORTAL KOMBAT 3", link: "/mortal-kombat", image: MortalKombat },
  {
    name: "SUPER SMASH BROS",
    link: "/super-smash-bros",
    image: SuperSmashBros,
  },
  { name: "CRASH BANDICOOT", link: "/crash-bandicoot", image: Crash },

  {
    name: "CRASH TEAM RACING",
    link: "/crash-team-racing",
    image: CrashTeamRacing,
  },
  {
    name: "MARVEL VS CAPCOM",
    link: "/marvel-vs-capcom",
    image: MarvelVsCapcom,
  },
  { name: "NEO BOMBERMAN", link: "/bomberman", image: Bomberman },
  {
    name: "KILLER INSTINCT 2",
    link: "/killer-instinct",
    image: KillerInstinct,
  },
  { name: "DOOM", link: "/doom", image: Doom },

  { name: "POKEMON FIRE X RED", link: "/pokemon", image: Pokemon },

  { name: "SONIC THE HEDGEHOG", link: "/sonic", image: Sonic },

  { name: "DIDDY KONG RACING", link: "/diddy-kong-racing", image: Diddy },

  { name: "STREETFIGHTER II", link: "/streetfighter", image: StreetFighter },

  { name: "TWISTED METAL", link: "/twisted-metal", image: TwistedMetal },

  { name: "SUPER MARIO BROS", link: "/super-mario-snes", image: SuperMario },

  { name: "CONKERS BAD FUR DAY", link: "/conkers-bad-fur-day", image: Conker },
  {
    name: "Ninja Turtles",
    link: "/ninja-turtles",
    image: NinjaTurtles,
  },
  { name: "007 Golden Eye", link: "/goldeneye", image: GoldenEye },
  { name: "MARIO KART", link: "/mario-kart", image: MarioKart },
  {
    name: "DBZ SUPERSONIC WARRIORS",
    link: "/dbz-supersonic-warriors",
    image: Dbz,
  },
  { name: "YU-GI-OH SACRED CARDS", link: "/yu-gi-oh", image: Yugi },
  {
    name: "DONKEY KONG COUNTRY",
    link: "/donkey-kong-country",
    image: DonkeyKong,
  },
  { name: "SUPER MARIO 64", link: "/mario-64", image: Mario64 },
  {
    name: "TONY HAWK PRO SKATER",
    link: "/tony-hawk-pro-skater",
    image: TonyHawk,
  },
  {
    name: "X-MEN",
    link: "/x-men",
    image: Xmen,
  },
]

const comics = [
  {
    title: "001",
    link: "https://drive.google.com/file/d/1z3UFbBZmjOSdX2zxpaxG7f-iUYcaOSsx/view",
    image: Comic1,
  },
]

export const Goatcade = (props) => {
  const goateriods = props.goateriods ? JSON.parse(props.goateriods) : []
  const invaders = props.invaders ? JSON.parse(props.invaders) : []
  const snake = props.snake ? JSON.parse(props.snake) : []
  const breakout = props.breakout ? JSON.parse(props.breakout) : []
  const pacman = props.pacman ? JSON.parse(props.pacman) : []
  const show_admin = props.show_admin
  let activeOrder = props.active_order || {}

  const [connected, setConnected] = useState(null)
  const [walletLoading, setWalletLoading] = useState(false)
  const [walletRefresh, setWalletRefresh] = useState(1)

  const handleConnect = (connectedWallet) => {
    setConnected(connectedWallet)
  }

  const ogPolicy = "c281975562f394761771f13f599881517fa8455946e7e30454de22da"
  const goatiaPolicy =
    "bbb6c713c497b8a9c3390a2157bac72d730ce842b27b5672d3b3d51f"

  const ogs =
    connected?.balance?.assets?.filter((nft) => nft.policy == ogPolicy) || []

  const goatia =
    connected?.balance?.assets?.filter((nft) => nft.policy == goatiaPolicy) ||
    []

  const hasGoats = connected && (ogs?.length > 0 || goatia?.length > 0)

  // console.log("goateriods", goateriods)
  // console.log("invaders", invaders)
  // console.log("snake", snake)
  // console.log("breakout", breakout)
  // console.log("pacman", pacman)

  const scores = {
    goateriods,
    invaders,
    snake,
    breakout,
    pacman,
  }

  const [accepted, setAccepted] = useState(() => {
    let defaultValue = !!activeOrder?.id
    const alreadyAccepted = parseInt(window.localStorage.getItem("accepted"))
    if (alreadyAccepted) defaultValue = true
    return defaultValue
  })
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [showRules, setShowRules] = useState(false)
  const [showPrizes, setShowPrizes] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "#000000",
          width: "100%",
          minHeight: "calc(100vh - 70px)",
          color: "#e0400f",
          px: [18, 25],
          height: "100%",
          maxWidth: "1440px",
          mx: "auto",
        }}
      >
        <audio id="agree-button" src={"/collect-ring.mp3"}></audio>
        <audio id="og-button" src={"/screaming-goat.mp3"}></audio>
        <audio id="goatia-button" src={"/goat-yeah.mp3"}></audio>
        <audio id="paper-button" src={"/paper-throw.mp3"}></audio>
        <Img
          src={Image1}
          sx={{
            width: "100%",
            height: "calc(100vh - 0px)",
            position: "fixed",
            objectFit: "cover",
            objectPosition: "top",
            top: 0,
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        {!accepted && (
          <Box
            sx={{
              bg: "black",
              width: "100%",
              height: "calc(100vh - 0px)",
              position: "fixed",
              top: 0,
              bottom: 0,
              left: [0],
              zIndex: 42069,
            }}
            className="press_start"
          >
            <Box
              sx={{
                width: ["90%", 550],
                border: "5px solid white",
                bg: "black",
                minHeight: "350px",
                position: "relative",
                top: "15%",
                mx: "auto",
                p: [15, 25],
                color: "white",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <Box>
                I agree to abide by all arcade{" "}
                <span
                  onClick={() => setShowRules(true)}
                  style={{ color: "yellow" }}
                >
                  RULES
                </span>{" "}
                and believe GOAT Tribe is a{" "}
                <span style={{ color: "#5b5bff" }}>BLUE CHIP</span>.
              </Box>
              <Img
                src={PixelGoat4}
                sx={{
                  mt: 30,
                  maxWidth: 169,
                  mx: "auto",
                  position: "relative",
                }}
              />
              <Box
                sx={{
                  mt: 50,
                  color: "black",
                  bg: "white",
                  padding: "12px 40px",
                  display: "inline-block",
                  cursor: "pointer",
                  border: "4px solid black",
                  "&:hover": {
                    color: "white",
                    bg: "black",
                    border: "4px solid white",
                  },
                }}
                onClick={() => {
                  document.getElementById("agree-button").play()
                  window.localStorage.setItem("accepted", 1)
                  setAccepted(true)
                }}
              >
                LFGOAT
              </Box>
            </Box>
          </Box>
        )}
        {showDisclaimer && (
          <Box
            sx={{
              bg: "black",
              width: "100%",
              height: "calc(100vh - 0px)",
              position: "fixed",
              top: 0,
              bottom: 0,
              left: [0],
              zIndex: 42069,
            }}
            onClick={() => {
              setShowDisclaimer(false)
            }}
            className="press_start"
          >
            <Box
              sx={{
                width: ["90%", 550],
                border: "5px solid white",
                bg: "black",
                minHeight: "350px",
                position: "relative",
                top: [33, 69],
                mx: "auto",
                p: [15, 25],
                color: "white",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <Box mt={30}>
                All of the free games are provided via retrogames.cc
              </Box>
              <Box mt={30}>
                We have no affliation with retrogames or the games found on
                their site.
              </Box>
              <Box mt={30}>
                Many titles may resemble popular games but are actually
                recreated versions online.
              </Box>
              <Box mt={30}>
                Retrogames relies on ads to run their site. If you don't want to
                view the ads, we recommend installing a simple Ad Blocker
                extension
              </Box>
              <Box
                sx={{
                  mt: 50,
                  color: "black",
                  bg: "white",
                  padding: "12px 40px",
                  display: "inline-block",
                  cursor: "pointer",
                  border: "4px solid black",
                  "&:hover": {
                    color: "white",
                    bg: "black",
                    border: "4px solid white",
                  },
                }}
              >
                LFGOAT
              </Box>
            </Box>
          </Box>
        )}
        {showRules && (
          <Box
            sx={{
              bg: "black",
              width: "100%",
              height: "calc(100vh - 0px)",
              position: "fixed",
              top: 0,
              bottom: 0,
              left: [0],
              zIndex: 42069,
            }}
            className="press_start"
          >
            <Box
              sx={{
                width: ["90%", 700],
                border: "5px solid white",
                bg: "black",
                minHeight: "350px",
                position: "relative",
                top: [33, 69],
                fontSize: ["14px", "16px"],
                mx: "auto",
                p: [15, 35],
                color: "white",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <Box mt={0}>Rules</Box>
              <Box mt={30}>
                1. Do not try to cheat or use the Goatcade in unintended ways.
                Anyone caught cheating will immediately have their account
                blacklisted
              </Box>
              <Box mt={30}>
                2. The free games and popular titles are provided via
                retrogames.cc. We have no affiliation with retrogames or the
                titles on their site
              </Box>
              <Box mt={30}>3. GOAT Tribe is a blue chip</Box>
              <Box
                sx={{
                  mt: 50,
                  color: "black",
                  bg: "white",
                  padding: "12px 40px",
                  display: "inline-block",
                  cursor: "pointer",
                  border: "4px solid black",
                  "&:hover": {
                    color: "white",
                    bg: "black",
                    border: "4px solid white",
                  },
                }}
                onClick={() => {
                  document.getElementById("goatia-button").play()
                  setShowRules(false)
                }}
              >
                I AGREE
              </Box>
            </Box>
          </Box>
        )}
        {showPrizes && (
          <Box
            sx={{
              bg: "black",
              width: "100%",
              height: "calc(100vh - 0px)",
              position: "fixed",
              top: 0,
              bottom: 0,
              left: [0],
              zIndex: 42069,
            }}
            onClick={() => {
              setShowPrizes(false)
            }}
            className="press_start"
          >
            <Box
              sx={{
                width: ["90%", 700],
                border: "5px solid white",
                bg: "black",
                minHeight: "350px",
                position: "relative",
                top: [33, 33],
                fontSize: ["14px", "16px"],
                mx: "auto",
                p: [15, 35],
                color: "white",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <Box mt={0} sx={{ whiteSpace: "pre-wrap" }}>
                {"JUNE REWARDS POOL\n\n5000 $GRASS\n5 DNA Kits\n5 GOATIA"}
              </Box>
              <Grid mt={30} sx={{ justifyContent: "center" }}>
                <Box
                  sx={{
                    display: "inline-block",
                    whiteSpace: "pre-wrap",
                    textAlign: "left",
                  }}
                >
                  {
                    "1st - 30% + DNA Kit\n2nd - 25% + Goatia\n3rd - 20%\n4th - 15%\n5th - 10%"
                  }
                </Box>
              </Grid>
              <Box mt={30}>
                Arcade prizes will be distributed at the end of every month
              </Box>
              <Box mt={30}>
                Monthly rewards vary, but generally the top of the leaderboard
                gets a higher % GRASS or valuable NFTs
              </Box>
              <Box
                sx={{
                  mt: 50,
                  color: "black",
                  bg: "white",
                  padding: "12px 40px",
                  display: "inline-block",
                  cursor: "pointer",
                  border: "4px solid black",
                  "&:hover": {
                    color: "white",
                    bg: "black",
                    border: "4px solid white",
                  },
                }}
              >
                LFGOAT
              </Box>
            </Box>
          </Box>
        )}

        <Grid
          sx={{
            ml: [0, 50],
            mt: [10, 0],
            gridTemplateColumns: ["1fr", "auto auto"],
            position: "relative",
          }}
        >
          <Box>
            <Box sx={{ position: "relative", zIndex: 6969 }}>
              <h1 className="neon" data-text="U">
                G<span className="flicker-slow">O</span>
                ATC
                <span className="flicker-fast">ADE</span>
              </h1>
              <Box
                sx={{
                  display: ["grid", "flex"],
                  alignItems: ["center"],
                  justifyContent: "space-between",
                  gridTemplateColumns: "1fr",
                }}
                className="wallet-grid"
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      ml: [0, "15px"],
                      mt: "20px",
                      textTransform: "uppercase",
                      fontSize: ["26px", "30px"],
                      "& i": {
                        fontSize: "40px",
                      },
                    }}
                    className="neon2"
                  >
                    <AudioPlayer src={arcadeSongs} songTitles={songTitles} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      mt: "15px",
                    }}
                    className="neon2"
                  >
                    <Box
                      sx={{
                        ml: [0, "10px"],
                        cursor: "pointer",
                        fontSize: ["26px", "30px"],
                      }}
                      onClick={() => setShowRules(true)}
                    >
                      RULES
                    </Box>
                  </Box>
                  {show_admin && (
                    <Box
                      sx={{
                        display: "flex",
                        mt: "15px",
                      }}
                    >
                      <a href="/gt-admin" className="neon2">
                        <Box
                          sx={{
                            ml: [0, "10px"],
                            cursor: "pointer",
                            fontSize: ["26px", "30px"],
                          }}
                        >
                          GT ADMIN
                        </Box>
                      </a>
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    ml: [0, "auto"],
                    mt: [30, 15],
                    position: "relative",
                    right: [0, 50],
                    textAlign: "center",
                  }}
                  className="wallet-wrapper"
                >
                  <ConnectWallet
                    blockfrostApiKey={BLOCKFROST_API_KEY}
                    handleConnect={handleConnect}
                    handleLoading={setWalletLoading}
                    styles={{ top: 0, mt: 0 }}
                    popupStyles={{ top: "75px" }}
                    walletRefresh={walletRefresh}
                  />
                </Box>
              </Box>
            </Box>

            {connected && !hasGoats && (
              <Box pb={50}>
                <Text
                  className="press_start"
                  sx={{
                    color: "white",
                    display: "block",
                    position: "relative",
                    zIndex: 9,
                    pt: ["30px", "50px"],
                    pb: "25px",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  You must have an unstaked OG GOAT or Goatia to access the
                  arcade
                </Text>
                <Grid
                  sx={{
                    gridTemplateColumns: ["1fr", "1fr 1fr"],
                    position: "relative",
                    zIndex: 69,
                    textAlign: "center",
                    color: "white",
                  }}
                  className="press_start"
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      document.getElementById("og-button").play()
                      window.open("https://www.jpg.store/collection/goattribe")
                    }}
                  >
                    <Img
                      src={PixelGoat2}
                      sx={{
                        mt: 30,
                        maxWidth: 169,
                        mx: "auto",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    />
                    <Box mt={3}>OG</Box>
                  </Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      document.getElementById("og-button").play()
                      window.open(
                        "https://www.jpg.store/collection/goattribegoatia"
                      )
                    }}
                  >
                    <Img
                      src={PixelGoat1}
                      sx={{
                        mt: 30,
                        maxWidth: 169,
                        mx: "auto",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    />
                    <Box mt={3}>GOATIA</Box>
                  </Box>
                </Grid>
              </Box>
            )}

            {connected && hasGoats && (
              <Box sx={{ position: "relative", zIndex: 69 }}>
                <Text
                  className="press_start"
                  sx={{
                    color: "white",
                    display: "block",
                    position: "relative",
                    zIndex: 9,
                    mt: ["30px", "100px"],
                    pb: "25px",
                    fontSize: "16px",
                    textAlign: "center",
                    cursor: "pointer",
                    "&:hover": {
                      color: "yellow",
                    },
                  }}
                  onClick={() => {
                    document.getElementById("agree-button").play()
                    setShowPrizes(true)
                  }}
                >
                  PAY TO PLAY
                </Text>
                <Grid sx={{ gridGap: ["50px", "15px"] }}>
                  {payToPlay.map((game) => {
                    return (
                      <Box
                        key={game.name}
                        sx={{ maxWidth: "900px", mr: "auto", ml: [0, "20px"] }}
                      >
                        <Grid
                          sx={{
                            gridTemplateColumns: ["1fr", "1fr 1fr"],
                            gridGap: ["25px", "40px"],
                          }}
                        >
                          <Box
                            className="scale-hover"
                            sx={{
                              textAlign: "center",
                              pb: [0, "35px"],
                              width: "100%",
                            }}
                            onClick={() => {
                              const player = document.getElementById("audio")
                              window.localStorage.setItem(
                                "trackTime",
                                player?.currentTime
                              )
                            }}
                          >
                            <Link
                              href={`${game.link}`}
                              sx={{ textDecoration: "none" }}
                            >
                              <Img
                                src={game.image}
                                sx={{
                                  width: ["100vw", "100%"],
                                  position: "relative",
                                  zIndex: 9,
                                  border: "3px solid white",
                                  borderRadius: "15px",
                                }}
                              />
                              <Text
                                className="press_start"
                                sx={{
                                  color: "white",
                                  display: "block",
                                  position: "relative",
                                  zIndex: 9,
                                  mt: "15px",
                                  fontSize: "16px",
                                }}
                              >
                                {game.name}
                              </Text>
                            </Link>
                          </Box>
                          <Box>
                            <Grid className="press_start">
                              <Text
                                className="press_start"
                                sx={{
                                  color: "white",
                                  display: "block",
                                  position: "relative",
                                  zIndex: 9,
                                  pb: "3px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                  borderBottom: "2px solid white",
                                }}
                              >
                                High Scores
                              </Text>
                              {[0, 1, 2, 3, 4].map((index) => {
                                const gameScores = scores[game.gameName]
                                return (
                                  <Grid
                                    sx={{
                                      gridTemplateColumns: "auto 1fr auto",
                                      color: "white",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <Text
                                      sx={{
                                        maxWidth: "100%",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {gameScores?.[index]?.user || "LFGOAT"}
                                    </Text>
                                    <span />
                                    <Text>
                                      {gameScores?.[index]?.score || 0}
                                    </Text>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )
                  })}
                </Grid>
              </Box>
            )}
          </Box>
          {connected && hasGoats && (
            <GrassRegister
              {...{
                connected,
                walletLoading,
                walletRefresh,
                setWalletRefresh,
                ...props,
              }}
            />
          )}
        </Grid>

        <Box sx={{ position: "relative", zIndex: 69 }}>
          {connected && hasGoats && (
            <Box>
              <Text
                className="press_start"
                sx={{
                  color: "white",
                  display: "block",
                  position: "relative",
                  zIndex: 9,
                  mt: ["30px", "100px"],
                  mb: "25px",
                  fontSize: "16px",
                  textAlign: "center",
                  cursor: "pointer",
                  "&:hover": {
                    color: "yellow",
                  },
                }}
                onClick={() => {
                  setShowDisclaimer(true)
                }}
              >
                FREE TO PLAY
              </Text>
              <Box className="masonry-with-columns">
                {freeToPlay.map((game) => {
                  return (
                    <Box
                      key={game.name}
                      className="scale-hover"
                      sx={{ textAlign: "center", pb: "35px", width: "100%" }}
                      onClick={() => {
                        const player = document.getElementById("audio")
                        window.localStorage.setItem(
                          "trackTime",
                          player?.currentTime
                        )
                      }}
                    >
                      <Link
                        href={`/games${game.link}`}
                        sx={{ textDecoration: "none" }}
                      >
                        <Img
                          src={game.image}
                          sx={{
                            width: ["100%", "100%"],
                            position: "relative",
                            zIndex: 9,
                            border: "3px solid white",
                            borderRadius: "15px",
                          }}
                        />
                        <Text
                          className="press_start"
                          sx={{
                            color: "white",
                            display: "block",
                            position: "relative",
                            zIndex: 9,
                            mt: "15px",
                            fontSize: "16px",
                          }}
                        >
                          {game.name}
                        </Text>
                      </Link>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ position: "relative", zIndex: 69 }}>
          {connected && hasGoats && (
            <Box>
              <Text
                className="press_start"
                sx={{
                  color: "white",
                  display: "block",
                  position: "relative",
                  zIndex: 9,
                  mt: ["30px", "100px"],
                  mb: "25px",
                  fontSize: "16px",
                  textAlign: "center",
                  cursor: "pointer",
                  "&:hover": {
                    color: "yellow",
                  },
                }}
                onClick={() => {
                  document.getElementById("paper-button").play()
                  window.open(
                    "https://www.jpg.store/collection/goatgeneration001"
                  )
                }}
              >
                COMICS
              </Text>
              <Box sx={{ pb: 30, position: "relative", width: "100%" }}>
                <Img
                  src={ComicBg}
                  sx={{
                    width: ["100%", "100%"],
                    zIndex: 9,
                    borderRadius: "15px",
                    display: ["none", "block"],
                  }}
                />
                <Img
                  src={ComicBgMobile}
                  sx={{
                    width: ["100%", "100%"],
                    zIndex: 9,
                    borderRadius: "15px",
                    display: ["block", "none"],
                    pb: "25px",
                  }}
                />
                <Grid
                  sx={{
                    position: "absolute",
                    gridTemplateColumns: ["1fr 1fr", "repeat(4, 150px)"],
                    gridGap: "25px",
                    top: ["20%", "25%"],
                    left: [50, "15%"],
                    right: [50, "15%"],
                  }}
                >
                  {comics.map((comic) => {
                    return (
                      <Box>
                        <Box
                          onClick={() => {
                            document.getElementById("paper-button").play()
                            window.open(comic.link)
                          }}
                        >
                          <Box
                            sx={{
                              textAlign: "center",
                              transition: "all 250ms",
                              cursor: "pointer",

                              "&:hover": {
                                transform: "scale(1.15)",
                              },
                            }}
                          >
                            <Img
                              src={comic.image}
                              sx={{
                                width: ["100%", 150],
                                zIndex: 9,
                                left: 0,
                                right: 0,
                                cursor: "pointer",
                              }}
                            />
                            <Box
                              sx={{
                                textAlign: "center",
                                color: "white",
                                mt: 2,
                              }}
                              className="press_start"
                            >
                              {comic.title}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })}
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Goatcade
