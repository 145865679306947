import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Grid,
  Image as Img,
  Paragraph,
  Select,
} from "theme-ui"
import { theme } from "../theme"
import Image1 from "images/rabbit-9.png"
import SGSLogo from "images/sgs-logo-trans.png"

import axios from "axios"
import ConnectWallet from "../ConnectWallet"

import MintingTips from "../MintingTips"
// import projectMetadata from "../metadata/drrs-metadata.json"
const projectMetadata = {}

// import supremeMetadata from "../metadata/drrs-metadata-supreme-dev.json"
// import supremeMetadata from "../metadata/drs-metadata.json"
const supremeMetadata = {}

// enable dummy attributes below
// enable real ticket instead of HTP

export const MintDRRSSupreme = (props) => {
  const blockfrostApiKey = props.apiKey
  const userId = props.user_id
  const mintId = props.mint_id
  const orders = props.orders ? JSON.parse(props.orders) : []
  const csrf = props.csrf
  const mintCount = props.mintCount
  const isProduction = props.is_production
  const usedPasses = props.nft_passes

  let activeOrder = null
  const sortedOrders = orders?.sort((a, b) => (a.id > b.id ? -1 : 1))
  const lastOrder = sortedOrders?.[0]
  if (lastOrder && !lastOrder?.confirmed_on_blockchain) {
    activeOrder = lastOrder
  }

  const tradeWallet =
    "addr1qxyl8jrhf4sj8dzsz4ytrks2xp8ldhxpu2mscju9epzvk9yaq56l7ncnayt495593p8ed2c4hxwcvqv4qhmxpwu27n5seuw76s"

  const ticketWallet =
    "addr1qyygvqk9uk52cdchmvdrsd53tjymjrcpnrhwl2j66h64valeke7eylgpw4x84frdg2fgh9juydtsevr6cxchuam7qtjqhvcy6t"

  // set dev vs prod attributes
  // const policyId = isProduction
  //   ? "544e179570e18d2b1bc39a752bd3cb693840b0612a88a48557176a34"
  //   : "544e179570e18d2b1bc39a752bd3cb693840b0612a88a48557176a34"
  // const policyScript = isProduction
  //   ? "8201828200581cd61d3656a176f8541db88d17af1806b096aa12bb4d68c619b046dc3482051a072b8151"
  //   : "8201828200581cd61d3656a176f8541db88d17af1806b096aa12bb4d68c619b046dc3482051a072b8151"

  // real policy and script
  const policyId = isProduction
    ? "85c6a29c993863d020ccce88c8a5dfc114392da6f5949a3cd462216c"
    : "544e179570e18d2b1bc39a752bd3cb693840b0612a88a48557176a34"
  const policyScript = isProduction
    ? "8201828200581cd61d3656a176f8541db88d17af1806b096aa12bb4d68c619b046dc3482051a07d6e04f"
    : "8201828200581cd61d3656a176f8541db88d17af1806b096aa12bb4d68c619b046dc3482051a072b8151"

  // real
  const drrsPolicy = "de2340edc45629456bf695200e8ea32f948a653b21ada10bc6f0c554"
  const ticketPolicy =
    "6e7bd53adc62886cb404a7368c54c7940f5838e0a456512df04e96e7"

  // test
  // const ticketPolicy =
  //   "9f5fd88debd4c78b9fef94610db89f8a547679b057cfb03a2ef44c6b"

  const successfulOrders = orders?.filter((order) => order.tx_hash)

  if (!blockfrostApiKey) return null
  if (!userId) return null

  const [connected, setConnected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [walletLoading, setWalletLoading] = useState(false)
  const [loadingText, setLoadingText] = useState("")
  const [quantity, setQuanity] = useState(1)
  const [order, setOrder] = useState(activeOrder)
  const [successOrders, setSucessOrders] = useState(successfulOrders)
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(null)
  const [tokenAmount, setTokenAmount] = useState(mintCount)

  const [selectedRabbits, setSelectedRabbits] = useState([])
  const [selectedTicket, setSelectedTicket] = useState(null)

  const sortedSuccessOrders = successOrders?.sort((a, b) =>
    a.id > b.id ? -1 : 1
  )
  const hasPendingTx =
    order && !order?.confirmed_on_blockchain && order?.tx_hash

  const orderCreated = order?.created_at ? new Date(order?.created_at) : null
  const allowResetOrder = orderCreated?.setMinutes(
    orderCreated?.getMinutes() + 30
  )

  let priceText = "No ticket"
  if (selectedRabbits.length) priceText += ` + ${selectedRabbits.join(" + ")}`

  // tickets policy - 6e7bd53adc62886cb404a7368c54c7940f5838e0a456512df04e96e7
  const allTickets = connected?.balance?.assets?.filter(
    (nft) => nft.policy == ticketPolicy
  )
  const tickets =
    allTickets?.filter((nft) => !usedPasses.includes(nft.name)) || []

  // drrs policy - de2340edc45629456bf695200e8ea32f948a653b21ada10bc6f0c554
  const rabbits = connected?.balance?.assets?.filter(
    (nft) => nft.policy == drrsPolicy
  )
  // const rabbits =
  //   allRabbits?.filter((nft) => !usedPasses.includes(nft.name)) || []

  const hasRabbits = rabbits?.length >= 2

  useEffect(() => {
    if (
      order &&
      !order.confirmed_on_blockchain &&
      order.tx_status != "failed"
    ) {
      setTimeout(refresh, 5000)
    }

    if (order && order.tx_errors) {
      const newErrors = order.tx_errors?.split(";;") || []
      setErrors([
        "Oh no! Something went wrong and the tx couldn't be created. Re-sign the tx and try again.",
        ...newErrors,
      ])
    }
  }, [])

  // useEffect(() => {
  //   localStorage.setItem("selectedTicket", selectedTicket)
  //   localStorage.setItem("selectedRabbits", selectedRabbits.join("|"))
  // }, [selectedTicket, selectedRabbits])

  const handleConnect = (connectedWallet) => {
    setConnected(connectedWallet)

    // const prevSelectedTicket = localStorage.getItem("selectedTicket")
    // const selectedRabbits = localStorage.getItem("selectedRabbits").split("|")

    // const assets =
    //   connectedWallet?.balance?.assets?.map((asset) => asset.name) || []
    // const hasTicket =
    //   assets.includes(prevSelectedTicket) && !usedPasses.includes(rabbit)
    // if (hasTicket) {
    //   setSelectedTicket(prevSelectedTicket)
    // }

    // const hasSelectedRabbits = selectedRabbits.filter(
    //   (rabbit) => assets.includes(rabbit) && !usedPasses.includes(rabbit)
    // )
    // if (hasSelectedRabbits.length) {
    //   setSelectedRabbits(hasSelectedRabbits)
    // }

    // debugger
  }

  const handleOrderClick = async () => {
    setLoading(true)
    const passes = [...selectedRabbits]
    try {
      const resp = await axios.post(
        "/orders/mint-drrs-supreme",
        {
          order: {
            quantity: quantity,
            user_id: userId,
            total: 0,
            notes: `DRRS Supreme - ${priceText}`,
            order_type: "SUPREME",
            mint_id: mintId,
            address: connected?.address,
            passes: passes?.join("|"),
          },
        },
        {
          headers: {
            "X-CSRF-Token": csrf,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      setLoading(false)

      if (resp?.data?.id) {
        setOrder(resp?.data)
        setTokenAmount(tokenAmount - quantity)
        setErrors(null)
      } else {
        setErrors(resp?.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleOrderDelete = async (o) => {
    setLoading(true)
    try {
      const resp = await axios.delete(`/orders/${o.id}`, {
        headers: {
          "X-CSRF-Token": csrf,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })

      setLoading(false)

      if (resp?.data?.deleted == true) {
        setOrder(null)
        window?.location?.reload()
      } else {
        setErrors([resp?.data?.error])
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function refresh() {
    const resp = await axios.get(`/orders/${order.id}`, {
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

    const orderData = resp?.data

    const isDifferent = JSON.stringify(order) != JSON.stringify(orderData)

    if (isDifferent) {
      setOrder(orderData)

      if (orderData.tx_hash != order.tx_hash) {
        const newOrder = {
          ...order,
          tx_hash: orderData.tx_hash,
          tx_created_at: new Date(),
        }
        setSucessOrders([...successOrders, newOrder])
        setSuccess(
          "Rad!\n\nYour transaction was successfully submitted to the blockchain.\n\nYou can find the tx below. It is pending but should show up in your wallet in a few minutes"
        )
      }

      if (orderData.confirmed_on_blockchain != order.confirmed_on_blockchain) {
        const index = successOrders.findIndex(
          (order) => order.id == orderData.id
        )
        let newOrders = [...successOrders]
        if (index >= 0) {
          newOrders[index] = orderData
        } else {
          newOrders = [...successOrders, orderData]
        }

        setSucessOrders(newOrders?.sort((a, b) => (a.id > b.id ? -1 : 1)))

        setSuccess(
          "Awesome!\n\nYour transaction is confirmed and on the blockchain. You can mint again!"
        )
      }

      if (orderData.tx_errors) {
        const newErrors = orderData.tx_errors?.split(";;") || []
        setSuccess("")
        setErrors([
          "Oh no! Something went wrong and the tx couldn't be created. Re-sign the tx and try again.",
          ...newErrors,
        ])
      }
    }

    if (!orderData.confirmed_on_blockchain) {
      const delay = orderData.tx_hash ? 60000 : 10000
      setTimeout(refresh, delay)
    }
  }

  const handleMintClick = () => {
    setLoading(true)
    setLoadingText("Building TX ...")

    const createTransaction = async () => {
      let paymentAddress = await connected.getAddress() //  wallet address
      const userWallet = paymentAddress

      const passes = order.access_nfts || []
      let assets = []
      passes.forEach((pass) => {
        const asset = connected?.balance?.assets?.find(
          (item) => item.name == pass
        )
        if (asset) {
          if (asset.policy == drrsPolicy) {
            assets.push({ unit: `${asset.policy}.${asset.name}`, quantity: 1 })
          }
        }
      })

      const rabbits = assets.filter((asset) =>
        asset?.unit?.includes(drrsPolicy)
      )
      const assetsValid = rabbits.length == 2

      let recipients = [
        {
          address: tradeWallet,
          amount: "0",
          assets: assets,
        },
        {
          address: userWallet,
          amount: "0",
          mintedAssets: order?.nfts?.map((nft) => {
            return {
              assetName: nft,
              quantity: "1",
              policyId: policyId,
              policyScript: policyScript,
            }
          }),
        },
      ]

      let nftsMeta = {}
      let dummyNftsMeta = {}

      order?.nfts?.forEach((nft, index) => {
        let dummyMeta = JSON.parse(JSON.stringify(supremeMetadata[nft]))
        Object.keys(dummyMeta.Attributes).forEach((attribute) => {
          dummyMeta.Attributes[attribute] =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabb"
        })
        dummyMeta.name =
          "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabb"
        dummyMeta.image =
          "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabb"
        dummyMeta.mediaType =
          "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabb"
        dummyMeta.description = [
          "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabb",
          "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabb",
          "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabb",
          "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabb",
        ]
        dummyMeta.Publisher =
          "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabb"
        dummyMeta.Twitter =
          "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabb"

        nftsMeta[nft] = supremeMetadata[nft]
        dummyNftsMeta[nft] = dummyMeta
      })

      let metadata = {
        721: {
          [policyId]: nftsMeta,
        },
      }

      let dummyMetadata = {
        721: {
          [policyId]: dummyNftsMeta,
        },
      }

      const metadataHash = connected.hashMetadata(metadata)

      let utxos = null
      let netId = null
      let transaction = null

      if (assetsValid) {
        try {
          utxos = await connected.getUtxosHex()
          netId = await connected.getNetworkId()
          transaction = await connected.transaction({
            PaymentAddress: paymentAddress,
            recipients: recipients,
            metadata: dummyMetadata,
            metadataHash: metadataHash,
            addMetadata: false,
            utxosRaw: utxos,
            networkId: netId?.id || 1,
            ttl: 7200,
            multiSig: true,
          })
        } catch (err) {
          let errMessage = err?.message
          if (errMessage == "INPUTS_EXHAUSTED")
            errMessage =
              "INPUTS_EXHAUSTED - You don't have enough funds or utxos available"
          console.error(err)
          setLoading(false)
          setErrors(["Oh no! There was an error building the tx.", errMessage])
        }
      } else {
        setLoading(false)
        setErrors([
          "Oh no! There was an error building the tx. You need 2 rabbits and 1 ticket in your wallet to claim a Supreme",
        ])
      }

      let witnessBuyer = null
      try {
        witnessBuyer = await connected.signTx(transaction, true)
        setLoadingText("Validating TX ...")
      } catch {
        // if user clicks cancel or Nami throws an error
        setLoading(false)
      }

      if (witnessBuyer) {
        try {
          let resp = null
          resp = await axios.post(
            `/orders/process/${order.id}`,
            {
              transaction,
              witness_buyer: witnessBuyer,
              user_wallet: userWallet,
            },
            {
              headers: {
                "X-CSRF-Token": csrf,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )

          if (resp?.data?.success) {
            setSuccess(
              "Awesome!\n\nYour transaction has been submitted to be processed. This page will update automatically."
            )
            setLoading(false)
            setErrors([])
            const newOrder = {
              ...order,
              tx_status: "submitted",
              witness_buyer: witnessBuyer,
            }
            setOrder(newOrder)

            // start polling for order status
            setTimeout(refresh, 5000)
          } else {
            console.log(resp?.data)
            const backendErrors = resp?.data?.errors || []
            setLoading(false)
            setErrors([
              "Oh no! Something went wrong and Nami couldn't create the transaction. Cancel the order and try again. Or contact the SGS team to let them know.",
              ...backendErrors,
            ])
          }
        } catch (err) {
          console.error(err)
          setLoading(false)
          setErrors([
            "Oh no! Something went wrong. Try it again or contact the SGS team to let them know.",
            err,
          ])
        }
      } else {
        console.error(witnessBuyer)
        setLoading(false)
      }
    }
    if (!loading) createTransaction()
    // send transaction, witnessBuyer, order, and user to backend
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "#000000",
          width: "100%",
          minHeight: "100vh",
          pb: [400],
          color: "white",
        }}
      >
        <Img
          src={Image1}
          sx={{
            width: ["250px", "500px"],
            position: "fixed",
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        <Box
          sx={{
            bg: "transparent",
            width: ["90%", "600px"],
            maxWidth: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            ml: "auto",
            top: "15px",
            right: [20, 30],
            borderRadius: "15px",
            p: "20px",
            fontSize: ["16px", "22px"],
            pb: "40px",
            zIndex: 10,
          }}
        >
          <ConnectWallet
            blockfrostApiKey={blockfrostApiKey}
            handleConnect={handleConnect}
            handleLoading={setWalletLoading}
            styles={{ top: 0, mt: 0 }}
            popupStyles={{ top: "75px" }}
          />
          {errors?.map((error) => (
            <Paragraph
              className="marker"
              sx={{
                color: "#f25100",
                whiteSpace: "pre-wrap",
                fontSize: ["22px", "26px"],
                mb: 0,
                mt: 30,
              }}
            >
              {error}
            </Paragraph>
          ))}

          {success && (
            <Box>
              {" "}
              <Paragraph
                className="marker"
                sx={{
                  color: "white",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  my: 20,
                }}
              >
                {success}
              </Paragraph>
              {order.tx_status == "confirmed" && (
                <Paragraph
                  className="marker"
                  sx={{ ...buttonStyles, mb: "25px", mt: 0, top: 0 }}
                  onClick={() => window?.location?.reload()}
                >
                  {"Mint Again"}
                </Paragraph>
              )}
            </Box>
          )}

          {hasPendingTx && !success && (
            <>
              <Paragraph
                className="marker"
                sx={{
                  color: "white",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mb: 30,
                  mt: 30,
                }}
              >
                {`You have a pending tx/order. Wait or handle that order first before creating another.\n\nOur system checks the blockchain every couple of minutes.`}
              </Paragraph>
              <MintingTips />
            </>
          )}

          {!order && !success && !hasPendingTx && (
            <Box>
              {connected && hasRabbits && (
                <Box>
                  <Box sx={{ mt: 30 }}>
                    {
                      <Box>
                        {/* <Paragraph
                          className="marker"
                          sx={{
                            color: "white",
                            whiteSpace: "pre-wrap",
                            fontSize: ["22px", "26px"],
                            lineHeight: ["24px", "32px"],
                            mt: "20px",
                          }}
                        >
                          {`Select the ticket you want to use`}
                        </Paragraph>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            mt: "20px",
                          }}
                        >

                        </Box> */}
                      </Box>
                    }

                    <Box sx={{ color: "white" }}>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "white",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px", "26px"],
                          lineHeight: ["24px", "32px"],
                          mt: "30px",
                        }}
                      >
                        {`Select the rabbits you wish to sacrifice`}
                      </Paragraph>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          mt: "20px",
                        }}
                      >
                        {rabbits.map((rabbit) => {
                          const ipfs = projectMetadata[rabbit?.name]
                          const ipfsHash = ipfs.image.replace("ipfs://", "")
                          const imageUrl = `https://infura-ipfs.io/ipfs/${ipfsHash}`
                          return (
                            <Box
                              onClick={() => {
                                const newRabbits = [...selectedRabbits]
                                if (selectedRabbits.includes(rabbit.name)) {
                                  // remove rabbit
                                  const index = newRabbits.indexOf(
                                    (name) => name == rabbit.name
                                  )
                                  newRabbits.splice(index, 1)
                                } else if (selectedRabbits.length < 2) {
                                  // add rabbit
                                  newRabbits.push(rabbit.name)
                                }
                                setSelectedRabbits(newRabbits)
                              }}
                              sx={{
                                cursor: "pointer",
                                color: selectedRabbits.includes(rabbit.name)
                                  ? "white"
                                  : "#707070",
                                "&:hover": { color: "white" },
                              }}
                            >
                              <Paragraph>{rabbit?.name}</Paragraph>
                              <Img
                                src={imageUrl}
                                sx={{
                                  width: ["80%"],
                                  maxWidth: "160px",
                                  borderRadius: "8px",
                                }}
                              />
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>

                    {priceText && (
                      <Paragraph
                        sx={{
                          color: "white",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px", "26px"],
                          my: "20px",
                        }}
                      >
                        {`Selected:\n${priceText}`}
                      </Paragraph>
                    )}

                    {selectedRabbits.length == 2 && (
                      <Paragraph
                        className="marker"
                        sx={buttonStyles}
                        onClick={loading ? null : handleOrderClick}
                      >
                        {loading ? "Creating Order ..." : "Submit"}
                      </Paragraph>
                    )}
                  </Box>
                </Box>
              )}
              {connected && !hasRabbits && (
                <Paragraph
                  className="marker"
                  sx={{
                    color: "white",
                    whiteSpace: "pre-wrap",
                    fontSize: ["22px", "26px"],
                    lineHeight: ["24px", "32px"],
                    mt: "30px",
                  }}
                >
                  {`You must have 1 Dead Rabbit Supreme Ticket and 2 Dead Rabbits to burn`}
                </Paragraph>
              )}
            </Box>
          )}

          {connected && order && [null, "errors"].includes(order.tx_status) && (
            <Box sx={{ mt: 30 }}>
              <MintingTips subdomain="deadrabbits" />

              <Paragraph
                className="marker"
                sx={{
                  color: "white",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mt: order.tx_errors ? 0 : 35,
                }}
              >
                {`Active Order\n${order.notes}`}
              </Paragraph>
              {connected &&
                !walletLoading &&
                (!order.witness_buyer || order.tx_errors) && (
                  <Paragraph
                    className="marker"
                    sx={buttonStyles}
                    onClick={loading ? null : handleMintClick}
                  >
                    {loading
                      ? loadingText
                      : order.tx_errors
                      ? "Re-sign Tx"
                      : "Sign Tx"}
                  </Paragraph>
                )}
              {new Date() > allowResetOrder && !order.tx_hash && (
                <Paragraph
                  className="marker"
                  sx={buttonStyles}
                  onClick={() => {
                    // handleNamiSuccess("123456abc")
                    handleOrderDelete(order)
                  }}
                >
                  Cancel
                </Paragraph>
              )}
            </Box>
          )}

          {successOrders?.length > 0 && (
            <Box
              sx={{ bg: "white", p: "15px", borderRadius: "10px", mt: "35px" }}
            >
              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  pt: 0,
                }}
              >
                {`Successful Orders\n--------------------`}
              </Paragraph>
              {sortedSuccessOrders?.map((successfulOrder, index) => {
                const txSubmitted = new Date(successfulOrder.tx_created_at)
                const txSubmittedCopy = new Date(successfulOrder.tx_created_at)

                const allowResetTime = txSubmittedCopy.setHours(
                  txSubmitted.getHours() + 3
                )
                const allowResetTx =
                  new Date() > allowResetTime &&
                  successfulOrder.tx_hash &&
                  !successfulOrder.confirmed_on_blockchain

                const txSubmittedText = `${txSubmitted.toLocaleDateString()} - ${txSubmitted.toLocaleTimeString()}`
                let txStatus = successfulOrder.confirmed_on_blockchain
                  ? "Confirmed"
                  : "Pending"
                if (allowResetTx) txStatus = "Failed"
                return (
                  <Box key={successfulOrder?.id}>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        fontSize: ["22px", "26px"],
                        mt: index == 0 ? 10 : 35,
                      }}
                    >
                      {`Quantity: ${successfulOrder.quantity}`}
                    </Paragraph>
                    {txStatus == "Confirmed" && (
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "black",
                          fontSize: ["22px", "26px"],
                          mt: 10,
                        }}
                      >
                        {`NFTs: ${successfulOrder.nfts.join(", ")}`}
                      </Paragraph>
                    )}

                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {`Notes: ${successfulOrder.notes}`}
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      {`Tx ${txStatus}`}
                      {txStatus == "Failed" && (
                        <>
                          {` - `}
                          <a
                            onClick={() => {
                              if (
                                window?.confirm(
                                  "Are you sure you want to reset the TX hash? This should only be done for broken or failed TXs. Only do this if it's been more than 2 hours since you submitted the tx"
                                ) &&
                                window.location
                              ) {
                                window.location.href = `/reset-order-tx/${successfulOrder.id}`
                              }
                            }}
                          >
                            Reset Order Tx
                          </a>
                          {` - Only do this if it's been more than 3 hrs since you submitted the tx`}
                        </>
                      )}
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      {`Tx Hash: `}
                      <a
                        href={`https://cardanoscan.io/transaction/${successfulOrder.tx_hash}`}
                        target="_blank"
                      >
                        {successfulOrder.tx_hash}
                      </a>
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      {`Tx Submitted: ${txSubmittedText}`}
                    </Paragraph>
                  </Box>
                )
              })}
              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mt: 10,
                }}
              >
                {`--------------------`}
              </Paragraph>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 15,
            left: ["unset", -250],
            right: 0,
            zIndex: 5,
            maxWidth: "200px",
            mx: "auto",
          }}
        >
          <Grid
            sx={{
              gridTemplateColumns: "1fr auto auto 1fr",
              alignItems: "center",
            }}
          >
            <span></span>
            <Paragraph sx={{ color: "white", whiteSpace: "pre-wrap" }}>
              {"Powered by\nSGS Minting"}
            </Paragraph>
            <Img
              src={SGSLogo}
              sx={{
                width: ["60px"],
              }}
            />
            <span></span>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  top: 10,
  color: "#f25100 !important",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "4px solid #1c1c1c",
  bg: "#1c1c1c",
  py: "12px",
  width: ["100%", 300],
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "24px",
  userSelect: "none",
  display: "block",
  marginTop: "25px",
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    bg: "#2f2f2f",
    borderColor: "#2f2f2f",
  },
}

export default MintDRRSSupreme
