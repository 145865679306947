import React, { useState, useEffect } from "react"
import { Box } from "theme-ui"

const AudioPlayer = ({ src = "/Stranger_Danger.wav" }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const [autoPlay, setAutoPlay] = useState(() => {
    const value = window.localStorage.getItem("autoPlay")
    let defaultValue = false
    if (value == "true") defaultValue = true
    if (value === null) defaultValue = true
    return defaultValue
  })

  useEffect(() => {
    setTimeout(() => {
      checkPlaying()
    }, 100)

    setTimeout(() => {
      checkPlaying()
    }, 250)

    setTimeout(() => {
      checkPlaying()
    }, 500)

    setTimeout(() => {
      checkPlaying()
    }, 750)

    setTimeout(() => {
      checkPlaying()
    }, 1000)

    if (autoPlay) {
      let playAttempt = setInterval(() => {
        const player = document.getElementById("audio")

        player
          .play()
          .then(() => {
            clearInterval(playAttempt)
          })
          .catch((error) => {
            console.log(
              "Unable to play the video, User has not interacted yet."
            )
          })

        checkPlaying()
      }, 1000)
    }
  }, [])

  const checkPlaying = () => {
    const player = document.getElementById("audio")
    setIsPlaying(!player?.paused)
  }

  const handleClick = () => {
    setTimeout(() => {
      checkPlaying()
    }, 250)

    setTimeout(() => {
      checkPlaying()
    }, 500)

    if (!isPlaying) {
      setAutoPlay(true)
      document.getElementById("audio").play()
      window.localStorage.setItem("autoPlay", true)
    } else {
      setAutoPlay(false)
      document.getElementById("audio").pause()
      window.localStorage.setItem("autoPlay", null)
    }
  }

  return (
    <Box key={autoPlay} sx={{ cursor: "pointer" }}>
      <audio id="audio" src={src} autoPlay={autoPlay} loop></audio>
      {isPlaying ? (
        <i
          onClick={handleClick}
          className="fa fa-pause"
          style={{ fontSize: "25px" }}
        ></i>
      ) : (
        <i
          onClick={handleClick}
          className="fa fa-play"
          style={{ fontSize: "25px" }}
        ></i>
      )}
    </Box>
  )
}

export default AudioPlayer
