import React, { useState, useEffect } from "react"
import { Box } from "theme-ui"

const AudioPlayer = ({ src = "/Stranger_Danger.wav", content, songTitles }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [loop, setLoop] = useState(() => {
    return !Array.isArray(src)
  })

  const [trackNumber, setTrackNumber] = useState(() => {
    const value = parseInt(window.localStorage.getItem("trackNumber"))
    return value || 0
  })

  const song = Array.isArray(src) ? src[trackNumber] : src

  const [autoPlay, setAutoPlay] = useState(() => {
    const value = window.localStorage.getItem("autoPlay")
    let defaultValue = false
    if (value == "true") defaultValue = true
    if (value === null) defaultValue = true
    return defaultValue
  })

  useEffect(() => {
    setTimeout(() => {
      checkPlaying()
    }, 100)

    setTimeout(() => {
      checkPlaying()
    }, 250)

    setTimeout(() => {
      checkPlaying()
    }, 500)

    setTimeout(() => {
      checkPlaying()
    }, 750)

    setTimeout(() => {
      checkPlaying()
    }, 1000)

    setTimeout(() => {
      checkPlaying()
    }, 1500)

    if (autoPlay) {
      let playAttempt = setInterval(() => {
        const player = document.getElementById("audio")

        player
          .play()
          .then(() => {
            clearInterval(playAttempt)
          })
          .catch((error) => {
            console.log(
              "Unable to play the video, User has not interacted yet."
            )
          })

        checkPlaying()
      }, 1000)
    }
  }, [])

  const checkPlaying = () => {
    const player = document.getElementById("audio")
    setIsPlaying(!player?.paused)
  }

  const handleClick = () => {
    setTimeout(() => {
      checkPlaying()
    }, 250)

    setTimeout(() => {
      checkPlaying()
    }, 500)

    const player = document.getElementById("audio")

    if (!isPlaying) {
      setAutoPlay(true)
      const trackTime =
        parseFloat(window.localStorage.getItem("trackTime")) || 0
      player.currentTime = trackTime
      player.play()
      window.localStorage.setItem("autoPlay", true)
    } else {
      setAutoPlay(false)
      player.pause()
      window.localStorage.setItem("trackTime", player.currentTime)
      window.localStorage.setItem("autoPlay", "false")
    }
  }

  const handleNext = () => {
    let nextTrack = trackNumber + 1
    window.localStorage.setItem("trackTime", 0)
    const trackCount = Array.isArray(src) ? src.length : 1
    if (nextTrack > trackCount - 1) nextTrack = 0
    setTrackNumber(nextTrack)
  }

  const handlePrevious = () => {
    let nextTrack = trackNumber - 1
    window.localStorage.setItem("trackTime", 0)
    const trackCount = Array.isArray(src) ? src.length - 1 : 1
    if (nextTrack < 0) nextTrack = trackCount
    setTrackNumber(nextTrack)
  }

  useEffect(() => {
    const player = document.getElementById("audio")
    // window.localStorage.setItem("trackTime", 0)

    const handleEnded = () => {
      window.localStorage.setItem("trackTime", 0)
      const currentTrack =
        parseInt(window.localStorage.getItem("trackNumber")) || 0
      let nextTrack = currentTrack + 1
      const trackCount = Array.isArray(src) ? src.length : 1
      if (nextTrack > trackCount - 1) nextTrack = 0
      setTrackNumber(nextTrack)
    }

    player.addEventListener("ended", handleEnded)

    return () => player.removeEventListener("ended", handleEnded)
  }, [])

  useEffect(() => {
    if (autoPlay) handleTrackChange()
  }, [trackNumber])

  const handleTrackChange = () => {
    window.localStorage.setItem("trackNumber", trackNumber)
    const player = document.getElementById("audio")
    const trackTime = parseFloat(window.localStorage.getItem("trackTime")) || 0
    player.currentTime = trackTime
    player.pause()
    player.src = Array.isArray(src) ? src[trackNumber] : src
    player.load()
    player.play()

    setTimeout(() => {
      checkPlaying()
    }, 100)

    setTimeout(() => {
      checkPlaying()
    }, 250)

    setTimeout(() => {
      checkPlaying()
    }, 500)

    setTimeout(() => {
      checkPlaying()
    }, 750)

    setTimeout(() => {
      checkPlaying()
    }, 1000)
  }

  const hasMultipleTracks = Array.isArray(src)

  return (
    <Box>
      <Box sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
        <audio id="audio" src={song} autoPlay={autoPlay} loop={loop}></audio>
        {hasMultipleTracks && (
          <i
            onClick={handlePrevious}
            className="fa fa-backward"
            style={{ fontSize: "25px", marginRight: "10px" }}
          ></i>
        )}
        <i
          onClick={handleClick}
          className={isPlaying ? `fa fa-pause` : "fa fa-play"}
          style={{ fontSize: "25px" }}
        ></i>

        {content ? <Box onClick={handleClick}>{content}</Box> : null}
        {hasMultipleTracks && (
          <i
            onClick={handleNext}
            className="fa fa-forward"
            style={{ fontSize: "25px", marginLeft: "10px" }}
          ></i>
        )}

        {hasMultipleTracks && (
          <i
            onClick={() => {
              setLoop(!loop)
            }}
            className="fa fa-retweet"
            style={{
              fontSize: "25px",
              marginLeft: "10px",
              color: loop ? "white" : "#ffd5ff",
            }}
          ></i>
        )}
      </Box>
      {songTitles?.[trackNumber] && (
        <Box>
          <Box sx={{ mt: "15px", whiteSpace: "normal" }}>
            {songTitles?.[trackNumber]}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default AudioPlayer
