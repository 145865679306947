import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Grid,
  Text,
  Image as Img,
  Paragraph,
  Select,
} from "theme-ui"
import { theme } from "../theme"
import Image1 from "images/drrs-armory-interior.jpg"
import Typewriter from "typewriter-effect"

import axios from "axios"
import AudioPlayer from "./AudioPlayer"
import ConnectWallet from "../ConnectWalletDrrs"
import MintingTips from "../MintingTips"
import { BLOCKFROST_API_KEY } from "../Constants"

// dev
// import weaponsMetadata from "../metadata/drrs-metadata-weapon-dev.json"
// import ccMetadata from "../metadata/drrs-metadata-cc-dev.json"
// prod
import weaponsMetadata from "../metadata/drrs-metadata-weapon.json"
import ccMetadata from "../metadata/drrs-metadata-cc.json"

// console.log("weaponsMetadata", weaponsMetadata)
// console.log("ccMetadata", ccMetadata)
// console.log("weaponsMetadata2", weaponsMetadata2)
// console.log("ccMetadata2", ccMetadata2)

export const MintDRRSWeapons = (props) => {
  const blockfrostApiKey = BLOCKFROST_API_KEY
  const userId = props.user_id
  const mintId = props.mint_id
  const mint = props.mint ? JSON.parse(props.mint) : {}
  const orders = props.orders ? JSON.parse(props.orders) : []
  const csrf = props.csrf
  const mintCount = props.mintCount
  const userMintCount = props.user_mint_count

  let activeOrder = null
  const sortedOrders = orders?.sort((a, b) => (a.id > b.id ? -1 : 1))
  const lastOrder = sortedOrders?.[0]
  if (lastOrder && !lastOrder?.confirmed_on_blockchain) {
    activeOrder = lastOrder
  }
  const successfulOrders = orders?.filter((order) => order.tx_hash)

  const [connected, setConnected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [walletLoading, setWalletLoading] = useState(false)
  const [loadingText, setLoadingText] = useState("")
  const [order, setOrder] = useState(activeOrder)
  const [quantity, setQuanity] = useState(activeOrder?.quantity || 1)
  const [successOrders, setSucessOrders] = useState(successfulOrders)
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(null)
  const [tokenAmount, setTokenAmount] = useState(mintCount)
  const [typing, setTyping] = useState(0)

  // console.log("successOrders", successOrders)

  // console.log("orders", orders)

  const wlStart = props.wl_start
  const publicStart = props.public_start
  const isWl1 = props.is_wl1
  const isWl2 = props.is_wl2
  const isWl3 = props.is_wl3

  let discordWlCount = 0
  if (isWl1) discordWlCount = 1
  if (isWl2) discordWlCount = 2
  if (isWl3) discordWlCount = 3

  const isWlStart = new Date() >= new Date(wlStart)
  const isPublicStart = new Date() >= new Date(publicStart)

  const createdDate = order?.created_at ? new Date(order.created_at) : null
  const allowDeleteTime = createdDate
    ? new Date(createdDate.getTime() + 1 * 60000)
    : null
  const canDelete = allowDeleteTime && order && new Date() >= allowDeleteTime

  const moleKey = "8dc65bb6a57ef18b7e97a1ee379b5c0af003cb92f01c4c3910281bb6"
  const depotKey = "f60ee3ef108c483e03e4c59109e4438bed8d3d2138a6350a58fcf906"

  const moleKeys =
    connected?.balance?.assets?.filter((nft) => nft.policy == moleKey) || []

  const depotKeys =
    connected?.balance?.assets?.filter((nft) => nft.policy == depotKey) || []

  const passes = [...moleKeys, ...depotKeys]?.map((pass) => pass.name)
  const passesCount = passes?.length
  const newPassesCount = passesCount * 3

  // console.log("connected", connected)
  // console.log("moleKeys", moleKeys)
  // console.log("depotKeys", depotKeys)
  // console.log("passes", passes)
  // console.log("order", order)

  // const genesisMaxAllowed = usedPassesCount + newPassesCount
  // const wlMaxAllowed = 5
  // const maxAllowed = isGen ? genesisMaxAllowed : wlMaxAllowed
  // // const userMaxMinted = userMintCount >= maxAllowed && isPreMint
  // const userMaxMinted = !isGenesis

  const tokenPrice = mint?.price ? parseInt(mint?.price) : 165

  const drrsAddress =
    "addr1q8v3mpjrzqdwua3fthjyvzkv4csvw6c6aczd8xewlgvsth9fj23yg27m90rrjcyj2yhldergq6msrfl9gg29svwhccgszmkxgt"
  const dAddress =
    "addr1q8sf8uw8a4necvj7a3r46ccup8vm6qd03chl5yqfqk4tye7pse5aqz3nnxs4468te22v6h6lpvs7h8axvk77nrv08y7qva07hv"

  // dev
  // const weaponsPolicyId =
  //   "9b3e06ad68b423e950db9a303ec26f6cc5a77f4f321b3d33bc881c54"
  // const weaponsPolicyScript =
  //   "8201828200581cd61d3656a176f8541db88d17af1806b096aa12bb4d68c619b046dc3482051a091ee10f"

  // const ccPolicyId = "6003515f307f4a27336c2be57b27e5da83e820e0e110f60cf7a45b72"
  // const ccPolicyScript =
  //   "8201828200581cd61d3656a176f8541db88d17af1806b096aa12bb4d68c619b046dc3482051a091ee0de"

  // prod
  const weaponsPolicyId =
    "9335141aeacd6a707b5768d47183e60bc0bb810b2262786dfb1fa92a"
  const weaponsPolicyScript =
    "8201828200581cd61d3656a176f8541db88d17af1806b096aa12bb4d68c619b046dc3482051a091ee0ef"

  const ccPolicyId = "cc0d8f431afe7bc4ad9a2cb8c285fa221642f42df4b57ad72cc7c837"
  const ccPolicyScript =
    "8201828200581cd61d3656a176f8541db88d17af1806b096aa12bb4d68c619b046dc3482051a091ee03d"

  // console.log(`drrsAddress - ${drrsAddress}`)
  // console.log(`dAddress - ${dAddress}`)
  // console.log(`weaponsPolicyId - ${weaponsPolicyId}`)
  // console.log(`ccPolicyId - ${ccPolicyId}`)

  if (!blockfrostApiKey) return null
  if (!userId) return null

  const sortedSuccessOrders = successOrders?.sort((a, b) =>
    a.id > b.id ? -1 : 1
  )

  const hasPendingTx =
    order && order?.tx_hash && !order?.confirmed_on_blockchain
  const orderCompleted =
    order && order?.tx_hash && order?.confirmed_on_blockchain

  // console.log("successOrders", successOrders)

  let orderOptions = []
  let max_amount = 0
  let discord_wl_amount = 0
  let wlOwed = 0
  if (!isWlStart) {
    orderOptions = []
  } else if (isWlStart && !isPublicStart) {
    if (isWl1) discord_wl_amount = 1
    if (isWl2) discord_wl_amount = 2
    if (isWl3) discord_wl_amount = 3

    wlOwed = discord_wl_amount - userMintCount
    if (wlOwed < 0) wlOwed = 0
    max_amount = wlOwed
    max_amount += newPassesCount
    if (max_amount > 12) max_amount = 12
    orderOptions = Array.from(Array(max_amount).keys()).map((x) => x + 1)
  } else if (isPublicStart) {
    orderOptions = [1, 2, 3, 4, 5, 6]
  }

  const total = quantity * tokenPrice
  let priceText = `${quantity} x ${tokenPrice} = ${total} ADA`

  let pricingText = `WEAPONS ARE ${tokenPrice} ADA EACH`
  const usingWlCount = quantity > wlOwed ? wlOwed : quantity

  if (!isPublicStart && (isWl1 || isWl2 || isWl3)) {
    if (isWl3 && wlOwed) {
      priceText += `\nUsing Discord WL3 [${usingWlCount}/3]`
    } else if (isWl2 && wlOwed) {
      priceText += `\nUsing Discord WL2 [${usingWlCount}/2]`
    } else if (isWl1 && wlOwed) {
      priceText += `\nUSING Discord WL1`
    }
  }

  if (!isPublicStart) {
    pricingText += "\nMax of 12 per tx"
  }

  const passesQuantity = quantity - wlOwed
  let usingPasses =
    passesQuantity > 0
      ? newPassesCount
        ? passesQuantity % 3 == 0
          ? Math.trunc(passesQuantity / 3)
          : Math.trunc(passesQuantity / 3 + 1)
        : 0
      : 0

  if (usingPasses && !isPublicStart)
    priceText += `\nUsing ${usingPasses} WL ${
      usingPasses == 1 ? "Key" : "Keys"
    } [${passes?.slice(0, usingPasses)?.join(", ")}]`

  const ccCount = order?.nfts?.filter((nft) => nft.includes("CC")).length

  useEffect(() => {
    if (
      order &&
      !order.confirmed_on_blockchain &&
      order.tx_status != "failed"
    ) {
      setTimeout(refresh, 5000)
    }

    if (order && order.tx_errors) {
      const newErrors = order.tx_errors?.split(";;") || []
      setErrors([...newErrors])
    }
  }, [])

  const handleConnect = (connectedWallet) => {
    setConnected(connectedWallet)
  }

  const handleOrderClick = async () => {
    setLoading(true)
    try {
      const resp = await axios.post(
        "/orders/mint-drrs",
        {
          order: {
            quantity: quantity,
            user_id: userId,
            total: total,
            notes: `${priceText}`,
            order_type: "WEAPONS",
            mint_id: mintId,
            address: connected?.address,
            passes:
              passes.length > 0 && !isPublicStart
                ? passes?.slice(0, usingPasses)?.join("|")
                : null,
          },
        },
        {
          headers: {
            "X-CSRF-Token": csrf,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      setLoading(false)

      if (resp?.data?.id) {
        setOrder(resp?.data)
        setTokenAmount(tokenAmount - quantity)
        setErrors(null)
      } else {
        setErrors(resp?.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleOrderDelete = async (o) => {
    setLoading(true)

    try {
      const resp = await axios.delete(`/orders/${o.id}`, {
        headers: {
          "X-CSRF-Token": csrf,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })

      setLoading(false)

      if (resp?.data?.deleted == true) {
        // setTokenAmount(tokenAmount + o.quantity)
        // setOrder(null)
        // setTyping(1)
        window.location.reload()
      } else {
        setErrors([resp?.data?.error])
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function refresh() {
    const resp = await axios.get(`/orders/${order.id}`, {
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

    const orderData = resp?.data

    const isDifferent = JSON.stringify(order) != JSON.stringify(orderData)

    if (isDifferent) {
      setOrder(orderData)

      if (orderData.tx_hash != order.tx_hash) {
        const newOrder = {
          ...order,
          tx_hash: orderData.tx_hash,
          tx_created_at: new Date(),
        }
        setSucessOrders([...successOrders, newOrder])
        setSuccess(
          "Your transaction was successfully submitted to the blockchain.\n\nYou can find the tx below. It is pending but should show up in your wallet in a few minutes"
        )
      }

      if (orderData.confirmed_on_blockchain != order.confirmed_on_blockchain) {
        setSuccess(
          "SUCCESS\nYour transaction has been verified on the blockchain"
        )
        setTimeout(() => {
          window.location = "/mint-success"
        }, 5000)
      }

      if (orderData.tx_errors) {
        const newErrors = orderData.tx_errors?.split(";;") || []
        setSuccess("")
        setErrors([...newErrors])
      }
    }

    if (!orderData.confirmed_on_blockchain) {
      const delay = orderData.tx_hash ? 60000 : 10000
      setTimeout(refresh, delay)
    }
  }

  const handleMintClick = () => {
    setLoading(true)
    setLoadingText("Building TX ...")

    const createTransaction = async () => {
      let paymentAddress = await connected.getAddress() //  wallet address

      const dAmount = String(order.total * 0.05)
      let paymentAmount = String(order.total - dAmount)
      const userWallet = paymentAddress

      const passes = order.access_nfts || []
      let assets = []
      passes.forEach((pass) => {
        const asset = connected?.balance?.assets?.find(
          (item) => item.name == pass
        )
        if (asset) {
          if (asset.policy == moleKey || asset.policy == depotKey) {
            assets.push({ unit: `${asset.policy}.${asset.name}`, quantity: 1 })
          }
        }
      })

      const drrsRecipient = {
        address: drrsAddress,
        amount: paymentAmount,
      }

      const dRecipient = assets?.length
        ? {
            address: dAddress,
            amount: dAmount,
            assets: assets,
          }
        : {
            address: dAddress,
            amount: dAmount,
          }

      let recipients = [
        drrsRecipient,
        dRecipient,
        {
          address: userWallet,
          amount: "0",
          mintedAssets: order?.nfts?.map((nft) => {
            const policyId = nft.includes("CC") ? ccPolicyId : weaponsPolicyId
            const policyScript = nft.includes("CC")
              ? ccPolicyScript
              : weaponsPolicyScript
            return {
              assetName: nft,
              quantity: "1",
              policyId: policyId,
              policyScript: policyScript,
            }
          }),
        },
      ]

      let nftsMeta = {}
      let dummyNftsMeta = {}
      let nftsMetaCC = {}
      let dummyNftsMetaCC = {}

      order?.nfts?.forEach((nft, index) => {
        if (nft.includes("CC")) {
          const projectMetadata = ccMetadata
          let dummyMeta = JSON.parse(JSON.stringify(projectMetadata[nft]))
          dummyMeta.image =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.name =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.mediaType =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.Project =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.Twitter =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.Publisher =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.description = [
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
          ]
          nftsMetaCC[nft] = projectMetadata[nft]
          dummyNftsMetaCC[nft] = dummyMeta
        } else {
          const projectMetadata = weaponsMetadata
          let dummyMeta = JSON.parse(JSON.stringify(projectMetadata[nft]))
          dummyMeta.image =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.name =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.mediaType =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.Project =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.Twitter =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.Publisher =
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          dummyMeta.description = [
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
            "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead",
          ]
          Object.keys(dummyMeta.Attributes).forEach((attribute) => {
            dummyMeta.Attributes[attribute] =
              "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbits"
          })
          if (dummyMeta.files?.[0]?.name) {
            dummyMeta.files[0].mediaType =
              "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
            dummyMeta.files[0].name =
              "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
            dummyMeta.files[0].src =
              "DeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDeadRabbitsDead"
          }

          nftsMeta[nft] = projectMetadata[nft]
          dummyNftsMeta[nft] = dummyMeta
        }
      })

      const hasCC = Object.keys(nftsMetaCC).length > 0

      let metadata = hasCC
        ? {
            721: {
              [ccPolicyId]: nftsMetaCC,
              [weaponsPolicyId]: nftsMeta,
            },
          }
        : {
            721: {
              [weaponsPolicyId]: nftsMeta,
            },
          }

      let dummyMetadata = hasCC
        ? {
            721: {
              [ccPolicyId]: dummyNftsMetaCC,
              [weaponsPolicyId]: dummyNftsMeta,
            },
          }
        : {
            721: {
              [weaponsPolicyId]: dummyNftsMeta,
            },
          }

      const metadataHash = connected.hashMetadata(metadata)

      let utxos = null
      let netId = null
      let transaction = null

      try {
        utxos = await connected.getUtxosHex()
        netId = await connected.getNetworkId()
        transaction = await connected.transaction({
          PaymentAddress: paymentAddress,
          recipients: recipients,
          metadata: dummyMetadata,
          metadataHash: metadataHash,
          addMetadata: false,
          utxosRaw: utxos,
          networkId: netId?.id || 1,
          ttl: 7200,
          multiSig: true,
        })
      } catch (err) {
        let errMessage = err?.message
        if (errMessage == "INPUTS_EXHAUSTED")
          errMessage =
            "INPUTS_EXHAUSTED - You don't have enough funds or utxos available"
        console.error(err)
        setLoading(false)
        setErrors(["Oh no! There was an error building the tx.", errMessage])
      }

      let witnessBuyer = null
      try {
        witnessBuyer = await connected.signTx(transaction, true)
        setLoadingText("Validating TX ...")
      } catch {
        // if user clicks cancel or Nami throws an error
        setLoading(false)
      }

      if (witnessBuyer) {
        try {
          let resp = null
          resp = await axios.post(
            `/orders/process/${order.id}`,
            {
              transaction,
              witness_buyer: witnessBuyer,
              user_wallet: userWallet,
            },
            {
              headers: {
                "X-CSRF-Token": csrf,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )

          if (resp?.data?.success) {
            setSuccess(
              "Your transaction has been submitted to be processed.\nThis page will update automatically."
            )
            setLoading(false)
            setErrors([])
            const newOrder = {
              ...order,
              tx_status: "submitted",
              witness_buyer: witnessBuyer,
            }
            setOrder(newOrder)

            // start polling for order status
            setTimeout(refresh, 5000)
          } else {
            console.log(resp?.data)
            const backendErrors = resp?.data?.errors || []
            setLoading(false)
            setErrors([
              "Oh no! Something went wrong and Nami couldn't create the transaction. Cancel the order and try again. Or contact the team to let them know.",
              ...backendErrors,
            ])
          }
        } catch (err) {
          console.error(err)
          setLoading(false)
          setErrors([
            "Oh no! Something went wrong. Try it again or contact the SGS team to let them know.",
            err,
          ])
        }
      } else {
        console.error(witnessBuyer)
        setLoading(false)
      }
    }
    if (!loading) createTransaction()
    // send transaction, witnessBuyer, order, and user to backend
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "white",
          width: "100%",
          height: ["calc(100vh - 70px)"],
          bg: "#000000",
          backgroundImage: `url(${Image1})`,
          backgroundRepeat: "no-repeat",
          objectFit: "cover",
          backgroundSize: ["auto 100%", "100% auto"],
          // overflow: "hidden",
        }}
      >
        <Box
          sx={{
            p: "20px",
            px: "40px",
            width: "100%",
            textAlign: "right",
            position: "relative",
            zIndex: 20,
          }}
        >
          <ConnectWallet
            blockfrostApiKey={blockfrostApiKey}
            handleConnect={handleConnect}
            handleLoading={setWalletLoading}
            showAda
            styles={{ top: 0, mt: 0 }}
            popupStyles={{ top: "75px" }}
          />
        </Box>
        <Box sx={{ px: ["20px", "40px"] }}>
          <Box
            className="pulse"
            sx={{
              maxWidth: "100%",
              position: "relative",
              display: "flex",
              maxWidth: ["100%", 700],
              maxHeight: 500,
              overflow: "auto",
              ml: "auto",
              flexDirection: "column",
              fontSize: ["16px", "22px"],
              pb: "40px",
              zIndex: 10,
              color: "#e0400f",
              padding: ["25px"],
              border: "3px solid #e0400f",
              mt: 20,
              minHeight: 350,
              bg: "rgba(0, 0, 0, .9)",
            }}
          >
            <Box sx={{ position: "absolute", right: 25, top: 25 }}>
              <AudioPlayer src="/old-nuclear-factory.mp3" />
            </Box>
            {!connected && (
              <Box key={walletLoading}>
                <Paragraph
                  className="marker"
                  sx={{
                    fontSize: ["22px", "22px"],
                    textTransform: "uppercase",
                  }}
                >
                  {walletLoading
                    ? "Connecting wallet ..."
                    : "You must connect your wallet ..."}
                </Paragraph>
              </Box>
            )}

            {!order && !success && !hasPendingTx && (
              <Box>
                {connected && (
                  <Box>
                    {tokenAmount && tokenAmount > 0 ? (
                      <Box
                        sx={{
                          mt: 0,
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px"],
                          textTransform: "uppercase",
                        }}
                      >
                        <span>{pricingText}</span>

                        {isWlStart && !isPublicStart && max_amount <= 0 && (
                          <Box>
                            <Box
                              mt={4}
                              sx={{
                                fontFamily:
                                  '"Electrolize", system-ui, sans-serif !important',
                              }}
                            >
                              {`You've minted the max amount until public mint`}
                            </Box>
                          </Box>
                        )}

                        {(isPublicStart || (isWlStart && max_amount > 0)) && (
                          <Box>
                            <Box mt={3}>
                              <Text>{`HOW MANY DO YOU WANT?`}</Text>
                            </Box>

                            <Box>
                              <Select
                                sx={{
                                  border: "none",
                                  color: "#e0400f",
                                  border: "3px solid #e0400f",
                                  bg: "black",
                                  position: "relative",
                                  maxWidth: "150px",
                                  zIndex: 10069,
                                  fontSize: "24px",
                                  outline: "none !important",
                                  fontFamily:
                                    '"Electrolize", system-ui, sans-serif !important',
                                  py: "0px",
                                  px: "25px",
                                  mt: "10px",
                                  textAlign: "center",
                                }}
                                value={quantity}
                                onChange={(e) => setQuanity(e.target.value)}
                              >
                                {orderOptions?.map((option) => (
                                  <option key={option}>{option}</option>
                                ))}
                              </Select>
                              <Paragraph
                                className="marker"
                                sx={{
                                  color: "#e0400f",
                                  whiteSpace: "pre-wrap",
                                  fontSize: ["22px"],
                                  mt: "15px",
                                  mt: 25,
                                }}
                              >
                                {priceText}
                              </Paragraph>
                              <Paragraph
                                sx={{
                                  ...buttonStyles,
                                }}
                                onClick={loading ? null : handleOrderClick}
                              >
                                {loading ? "Creating Order ..." : "Submit"}
                              </Paragraph>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box>
                        <Typewriter
                          options={{
                            strings: `ALL THE TOKENS HAVE BEEN RESERVED`,
                            autoStart: true,
                            delay: 35,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}

            {connected && errors && errors.length > 0 && (
              <Box>
                {errors?.map((error) => (
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "#e0400f",
                      whiteSpace: "pre-wrap",
                      fontSize: ["22px"],
                      textAlign: "left",
                      mb: 30,
                      mt: 20,
                      mx: "auto",
                      maxWidth: 1000,
                    }}
                  >
                    {error}
                  </Paragraph>
                ))}
              </Box>
            )}

            {connected && order?.id && (
              <Box>
                {success && (
                  <Box>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "#e0400f",
                        fontSize: ["22px"],
                        whiteSpace: "pre-wrap",
                        my: 20,
                      }}
                    >
                      <Text>{success}</Text>
                    </Paragraph>
                  </Box>
                )}

                {hasPendingTx && !success && (
                  <>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "#e0400f",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px"],
                        mb: 30,
                      }}
                    >
                      <Text>{`You have a pending tx\nWait for the tx to be confirmed\n\nOur system checks the blockchain every minute`}</Text>
                    </Paragraph>
                  </>
                )}

                {order && [null, "errors"].includes(order.tx_status) && (
                  <Box sx={{}}>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "#e0400f",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px"],
                        mb: 20,
                        textTransform: "uppercase",
                      }}
                    >
                      {`Open Order`}
                    </Paragraph>
                    {/* <Img
                      src={image}
                      sx={{
                        width: 175,
                      }}
                    /> */}
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "#e0400f",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px"],
                      }}
                    >
                      {`${order?.quantity} ${
                        order?.quantity == 1 ? "WEAPON" : "WEAPONS"
                      } ${
                        ccCount
                          ? `+ ${ccCount} ${
                              ccCount == 1
                                ? "CRAFTING COMPONENT"
                                : "CRAFTING COMPONENTS"
                            }`
                          : ""
                      }\n${priceText}`}
                    </Paragraph>

                    {!walletLoading &&
                      (!order.witness_buyer || order.tx_errors) && (
                        <Paragraph
                          className="marker"
                          sx={{ ...buttonStyles, mt: 60 }}
                          onClick={loading ? null : handleMintClick}
                        >
                          {loading
                            ? loadingText || "Sign Tx"
                            : order.tx_errors
                            ? "Re-sign Tx"
                            : "Sign Tx"}
                        </Paragraph>
                      )}
                    {canDelete && (
                      <Box>
                        <Paragraph
                          sx={buttonStyles}
                          onClick={() => handleOrderDelete(order)}
                        >
                          {"Delete Order"}
                        </Paragraph>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}

            {connected && successOrders?.length > 0 && (
              <Box
                sx={{
                  bg: "black",
                  py: "15px",
                  borderRadius: "10px",
                  mt: "60px",
                  color: "#e0400f",
                }}
              >
                <Paragraph
                  className="marker"
                  sx={{
                    color: "#e0400f",
                    whiteSpace: "pre-wrap",
                    fontSize: ["22px"],
                    pt: 0,
                  }}
                >
                  {`Previous Orders\n--------------------`}
                </Paragraph>
                {sortedSuccessOrders?.map((successfulOrder, index) => {
                  const txSubmitted = new Date(successfulOrder?.tx_created_at)
                  const txSubmittedCopy = new Date(
                    successfulOrder?.tx_created_at
                  )

                  const allowResetTime = txSubmittedCopy.setHours(
                    txSubmitted.getHours() + 3
                  )
                  const allowResetTx =
                    new Date() > allowResetTime &&
                    successfulOrder.tx_hash &&
                    !successfulOrder.confirmed_on_blockchain

                  let txStatus = successfulOrder?.confirmed_on_blockchain
                    ? "Confirmed"
                    : "Pending"
                  if (allowResetTx) txStatus = "Failed"
                  return (
                    <Box key={successfulOrder?.id}>
                      {txStatus != "Confirmed" && (
                        <Paragraph
                          className="marker"
                          sx={{
                            color: "#e0400f",
                            fontSize: ["22px"],
                            mt: 10,
                          }}
                        >
                          Pending
                        </Paragraph>
                      )}
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "#e0400f",
                          fontSize: ["22px"],
                          mt: 10,
                        }}
                      >
                        {`Quantity: ${successfulOrder?.quantity}`}
                      </Paragraph>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "#e0400f",
                          fontSize: ["22px"],
                          mt: 10,
                        }}
                      >
                        {`Total: ${Number(successfulOrder?.total)}`}
                      </Paragraph>
                      {txStatus == "Confirmed" && (
                        <Paragraph
                          className="marker"
                          sx={{
                            color: "#e0400f",
                            fontSize: ["22px"],
                            mt: 10,
                          }}
                        >
                          {`NFTs: ${successfulOrder?.nfts?.join(", ")}`}
                        </Paragraph>
                      )}

                      <Paragraph
                        className="marker"
                        sx={{
                          color: "#e0400f",
                          fontSize: ["22px"],
                          mt: 10,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {`Notes: ${successfulOrder?.notes}`}
                      </Paragraph>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "#e0400f",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px"],
                          mt: 10,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          cursor: "pointer",
                          color: "#e0400f",
                        }}
                      >
                        {`Tx Hash: `}
                        <a
                          href={`https://cardanoscan.io/transaction/${successfulOrder?.tx_hash}`}
                          target="_blank"
                        >
                          {successfulOrder.tx_hash}
                        </a>
                      </Paragraph>
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "#e0400f",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px"],
                          mt: 10,
                        }}
                      >
                        {`--------------------`}
                      </Paragraph>
                    </Box>
                  )
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  minHeight: "60px",
  color: "#e0400f",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "3px solid #e0400f",
  bg: "rgba(0, 0, 0, 0.7)",
  py: "10px",
  px: 15,
  mx: "auto",
  width: ["80%", 250],
  minWidth: ["unset", "200px"],
  cursor: "pointer",
  fontSize: ["20px"],
  userSelect: "none",
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "25px",
  fontFamily: '"Electrolize", system-ui, sans-serif !important',
  textDecoration: "none",
  textTransform: "uppercase",
  // transition: "all 750ms",
  "&:hover": {
    bg: "#e0400f",
    color: "black",
  },
}

export default MintDRRSWeapons
