import React, { useEffect, useState } from "react"
import { ThemeProvider, Box, Image as Img, Paragraph, Select } from "theme-ui"
import { theme } from "../theme"
import Goat2 from "images/goat-2.png"
import Goat3 from "images/goat-3.png"
import Goat4 from "images/goat-4.png"
import NamiWalletApi, { Cardano } from "../../nami-js/nami"
import axios from "axios"

import RandomMemes from "../RandomMemes"
import MintingTips from "../MintingTips"
// import sgsMetadata from "../metadata/sgs-metadata.json"
const sgsMetadata = {}

function preloadImage(url) {
  var img = new Image()
  img.src = url
}

const bgColors = [
  "#8fabdc",
  "#fb1420",
  "#1d77eb",
  "#ae2644",
  "#3c45b2",
  "#873cc4",
  "#c344d4",
  "#d86039",
  "#35ec80",
  "#7be53c",
]
const goats = [
  Goat2,
  Goat3,
  Goat4,

]

const soldOutGifs = [
  "https://c.tenor.com/sKg02SlxaoQAAAAC/red-goobler-solar-opposites.gif",
  "https://c.tenor.com/0hNIHb95iDMAAAAC/no-more-rebel-wilson.gif",
  "https://c.tenor.com/WejWsIlk3_cAAAAd/thats-enough-okay-jason-bateman.gif",
  "https://c.tenor.com/hKsgsuQ5N8MAAAAC/cymbals-monkey-brain.gif",
  "https://c.tenor.com/98QJc8KJsMkAAAAC/gimme-no-more.gif",
  "https://c.tenor.com/N2ibf5aQj2gAAAAC/dragrace-bitch-you-done.gif",
  "https://c.tenor.com/3DGYbblZUasAAAAd/youre-done-done-son.gif",
  "https://c.tenor.com/ig55vSh4xHwAAAAC/throw-dog.gif",
  "https://c.tenor.com/VJz7OLQ8DkQAAAAM/heather-dubrow-shannon-beador.gif",
  "https://c.tenor.com/MPsGPP25j70AAAAC/good-day-fez.gif",
  "https://c.tenor.com/J3mNIbj6A4wAAAAd/empty-shelves-john-travolta.gif",
  "https://c.tenor.com/UbGii9oGosoAAAAC/money-wallet.gif",
  "https://c.tenor.com/nd2wxsrTXYEAAAAd/level-up-next-level.gif",
  "https://c.tenor.com/WS9TNssQALkAAAAd/roughneck-rabbits-roughneck.gif",
  "https://c.tenor.com/Q6BU4RC5zQsAAAAd/sold-out-what.gif",
  "https://c.tenor.com/TK5hcjkNr_cAAAAC/gordon-ramsey-you-are-done.gif",
  "https://c.tenor.com/MC7K9jo7V0YAAAAC/survivor-jeff-probst.gif",
  "https://c.tenor.com/qYrv7LSWFFIAAAAC/finn-adventure-time.gif",
  "https://c.tenor.com/gKcLWQAVD8sAAAAC/done-annoyed.gif",
  "https://c.tenor.com/jhJp2A-K5HwAAAAd/gollum-leave-now.gif",
  "https://c.tenor.com/TbGZDsVxx0wAAAAC/i-dont-need-you-anymore-kyle-broflovski.gif",
  "https://c.tenor.com/VGmgyUMNP88AAAAC/toy-story-woody.gif",
  "https://c.tenor.com/FqdTy04G6m4AAAAC/i-dont-need-you-anymore-satan.gif",
  "https://c.tenor.com/JLpNtvfer7wAAAAM/you-need-to-go-saturday-night-live.gif",
  "https://c.tenor.com/JWTW52xCLycAAAAd/i-think-you-should-leave-before-i-call-the-police-eric-cartman.gif",
  "https://c.tenor.com/6aZotD1Q0SkAAAAC/tigger-sad.gif",
  "https://c.tenor.com/ipXfNs5DnFIAAAAd/get-the-hell-out-of-here-sung-won-cho.gif",
  "https://c.tenor.com/0Ar7aEuIPT4AAAAC/get-the-fudge-out-eric-cartman.gif",
  "https://c.tenor.com/Wl0r-dwasLYAAAAC/sad-the-simpsons.gif",
  "https://c.tenor.com/L2T_fqCrWyMAAAAC/theres-no-more-scared.gif",
  "https://c.tenor.com/dlJSiLUJNmsAAAAC/math-calculate.gif",
  "https://c.tenor.com/18sqCWkrm9oAAAAC/confused-wtf.gif",
  "https://c.tenor.com/3yyytNGYC7cAAAAC/empty-tank.gif",
  "https://c.tenor.com/q-E5wj1K6OYAAAAC/monsters-inc-sully.gif",
  "https://c.tenor.com/65QP-lH9krMAAAAC/no-money-donald-duck.gif",
  "https://c.tenor.com/lQNf6C6HDYQAAAAC/angry-kid.gif",
  "https://c.tenor.com/sGzHvagmSugAAAAC/staredown-forreal.gif",
]

const getRandomNumber = (previous) => {
  let num = Math.floor(Math.random() * goats.length)
  if (num == previous) num = Math.floor(Math.random() * goats.length)
  if (num == previous) num = Math.floor(Math.random() * goats.length)
  return num
}

export const MintSgs = (props) => {
  const blockfrostApiKey = props.apiKey
  const userId = props.user_id
  const accessMintToken = props.mint_access_token
  const orders = props.orders ? JSON.parse(props.orders) : []
  const csrf = props.csrf
  const mintCount = props.mintCount
  const esgsCount = props.esgsCount
  const sgsCount = props.sgsCount
  const userMintCount = props.user_mint_count
  const maxTokensAmount = props.user_mint_max
  const isProduction = props.is_production
  const sendToNami = true

  let activeOrder = null
  const sortedOrders = orders?.sort((a, b) => (a.id > b.id ? -1 : 1))
  const lastOrder = sortedOrders?.[0]
  if (lastOrder && !lastOrder?.confirmed_on_blockchain) {
    activeOrder = lastOrder
  }

  console.log(`max - ${maxTokensAmount}`)

  const tokenPrice = isProduction ? 60 : 1

  const hasNami = window?.cardano?.nami

  const walletAddress = isProduction
    ? "addr1qxteyf82c6ywfl6u9vjzd55dkju5frqmrwxvaansfj3u7zq7jcwua87zadf0gt6tcqh9uug9mjdk8qqwyayntl92kpwq0a4mrz"
    : "addr1qyrke3hmuw29c56c8f0vmg0vzarcht70m6a2788lyp7et22mlcy7y7703za4cy7zh789jnjffstkgsgk5vhr5ks6vj5qrsvlkh"

  // set dev vs prod attributes
  const policyId = isProduction
    ? "6531354d2f96f3b6d8436c563a550edbf2ad810cf2109a1b5897d609"
    : "908ae3f484818d4b2e36322af628c3617cb7023fe956376d79496bbf"
  const policyScript = isProduction
    ? "8201828200581c60d681f9d5f802510b75c57e09255eff09c95ef12be9e7a51341293c82051a06dc130a"
    : "8201828200581c395f56ad222119a33b3db31b61d4f1ccf8af2a266731be3973d4e41582051a04f0c01e"

  console.log(`Patment wallet - ${walletAddress}`)
  console.log(`Policy - ${policyId}`)
  // console.log(`Send to Nami - ${sendToNami}`)
  // console.log(nftMetadata)

  const successfulOrders = orders?.filter((order) => order.tx_hash)

  console.log("loaded")

  if (!blockfrostApiKey) return null
  if (!userId) return null

  const [randomNumber, setRandomNumber] = useState(getRandomNumber())
  const [connected, setConnected] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState("")
  const [quantity, setQuanity] = useState(1)
  const [order, setOrder] = useState(activeOrder)
  const [successOrders, setSucessOrders] = useState(successfulOrders)
  const [soldOutGif, setSoldOutGif] = useState(null)
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(null)
  const [tokenAmount, setTokenAmount] = useState(mintCount)

  const sortedSuccessOrders = successOrders?.sort((a, b) =>
    a.id > b.id ? -1 : 1
  )
  const hasPendingTx =
    order && !order?.confirmed_on_blockchain && order?.tx_hash

  const orderCreated = order?.created_at ? new Date(order?.created_at) : null
  const allowResetOrder = orderCreated?.setMinutes(
    orderCreated?.getMinutes() + 30
  )

  let orderOptions = []
  const allowedToMint = maxTokensAmount - userMintCount
  if (allowedToMint > 5) {
    orderOptions = [1, 2, 3, 4, 5]
  } else {
    for (var i = 1; i <= allowedToMint; i++) orderOptions.push(i)
  }

  const total = quantity * tokenPrice
  const priceText = `${quantity} x ${tokenPrice} = ${total} ADA`

  const randomGoat = goats[randomNumber]
  const randomBg = bgColors[randomNumber]

  useEffect(() => {
    setSoldOutGif(soldOutGifs[Math.floor(Math.random() * soldOutGifs.length)])

    if (
      order &&
      !order.confirmed_on_blockchain &&
      order.tx_status != "failed"
    ) {
      setTimeout(refresh, 5000)
    }

    if (order && order.tx_errors) {
      const newErrors = order.tx_errors?.split(";;") || []
      setErrors([
        "Oh BLEEEAT! Something went wrong and the tx couldn't be created.",
        ...newErrors,
      ])
    }
  }, [])

  let nami
  useEffect(() => {
    async function t() {
      const S = await Cardano()
      nami = new NamiWalletApi(S, window?.cardano, blockfrostApiKey)

      if (await nami.isInstalled()) {
        await nami.isEnabled().then(async (result) => {
          if (result) {
            setConnected(nami)
          } else {
            await nami.enable().then((result2) => {
              setConnected(nami)
            })
          }
        })
      }
    }

    t()
  }, [])

  const handleOrderClick = async () => {
    setLoading(true)
    try {
      const resp = await axios.post(
        "/orders/mint-sgs",
        {
          order: {
            quantity: quantity,
            user_id: userId,
            total: total,
            notes: `SGS - ${priceText}`,
            order_type: "SGS",
          },
        },
        {
          headers: {
            "X-CSRF-Token": csrf,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      setLoading(false)

      if (resp?.data?.id) {
        setOrder(resp?.data)
        setTokenAmount(tokenAmount - quantity)
        setErrors(null)
      } else {
        setErrors(resp?.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleNamiSuccess = async (txHash) => {
    try {
      const resp = await axios.post(
        `/orders/success/${order.id}`,
        {
          tx_hash: txHash,
        },
        {
          headers: {
            "X-CSRF-Token": csrf,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      setLoading(false)

      if (resp?.data?.success) {
        const newOrder = {
          ...order,
          tx_hash: txHash,
          tx_created_at: new Date(),
        }
        setSucessOrders([...successOrders, newOrder])

        setOrder(null)

        setSuccess(
          "LFGOAT!\nYour transaction has been submitted to the blockchain. It should show up in your wallet in a few minutes/hours depending on network load"
        )
      } else {
        setErrors([resp?.data?.error])
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleOrderDelete = async (o) => {
    setLoading(true)
    try {
      const resp = await axios.delete(`/orders/${o.id}`, {
        headers: {
          "X-CSRF-Token": csrf,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })

      setLoading(false)

      if (resp?.data?.deleted == true) {
        setOrder(null)
        window?.location?.reload()
      } else {
        setErrors([resp?.data?.error])
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function refresh() {
    const resp = await axios.get(`/orders/${order.id}`, {
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

    const orderData = resp?.data

    const isDifferent = JSON.stringify(order) != JSON.stringify(orderData)

    if (isDifferent) {
      setOrder(orderData)

      if (orderData.tx_hash != order.tx_hash) {
        const newOrder = {
          ...order,
          tx_hash: orderData.tx_hash,
          tx_created_at: new Date(),
        }
        setSucessOrders([...successOrders, newOrder])
        setSuccess(
          "BLEEEAT!\n\nYour transaction was successfully submitted to the blockchain.\n\nYou can find the tx below. It is pending but should show up in your wallet in a few minutes"
        )
      }

      if (orderData.confirmed_on_blockchain != order.confirmed_on_blockchain) {
        const index = successOrders.findIndex(
          (order) => order.id == orderData.id
        )
        let newOrders = [...successOrders]
        if (index >= 0) {
          newOrders[index] = orderData
        } else {
          newOrders = [...successOrders, orderData]
        }

        setSucessOrders(newOrders?.sort((a, b) => (a.id > b.id ? -1 : 1)))

        setSuccess(
          "LFGOAT!\nYour transaction is confirmed and on the blockchain. You can mint again!"
        )
      }

      if (orderData.tx_errors) {
        const newErrors = orderData.tx_errors?.split(";;") || []
        setSuccess("")
        setErrors([
          "Oh BLEEEAT! Something went wrong and the tx couldn't be created.",
          ...newErrors,
        ])
      }
    }

    if (!orderData.confirmed_on_blockchain) {
      const delay = orderData.tx_hash ? 60000 : 10000
      setTimeout(refresh, delay)
    }
  }

  const handleMintClick = () => {
    setLoadingText("Building TX ...")
    const createTransaction = async () => {
      setLoading(true)
      let paymentAddress = await connected.getAddress() // nami wallet address

      // let paymentAmount = String(
      //   Math.round(tokenPrice * order.quantity * 100000) / 100000
      // )

      let paymentAmount = String(order.total)

      const paymentWallet = walletAddress

      const userWallet = paymentAddress

      let recipients = [
        {
          address: paymentWallet,
          amount: paymentAmount,
        }, // Seller Wallet, NFT price 2ADA
        {
          address: userWallet,
          amount: "0",
          mintedAssets: order?.nfts?.map((nft) => {
            return {
              assetName: nft,
              quantity: "1",
              policyId: policyId,
              policyScript: policyScript,
            }
          }),
        }, // NFTs to be minted
      ] // list of recipients

      let nftsMeta = {}

      order?.nfts?.forEach((nft, index) => {
        nftsMeta[nft] = sgsMetadata[nft]
      })

      let metadata = {
        721: {
          [policyId]: nftsMeta,
        },
      }

      const metadataHash = connected.hashMetadata(metadata)

      let utxos = await connected.getUtxosHex()
      let netId = await connected.getNetworkId()
      let transaction = await connected.transaction({
        PaymentAddress: paymentAddress,
        recipients: recipients,
        metadata: metadata,
        metadataHash: metadataHash,
        addMetadata: false,
        utxosRaw: utxos,
        networkId: netId?.id || 1,
        ttl: 10800,
        multiSig: true,
      })

      let witnessBuyer = null
      try {
        witnessBuyer = await connected.signTx(transaction, true)
        setLoadingText("Validating TX ...")
      } catch {
        // if user clicks cancel or Nami throws an error
        setLoading(false)
      }

      const mintData = {
        transaction,
        witnessBuyer,
        userWallet,
        user_id: userId,
        order_id: order?.id,
        nfts: order?.nfts,
        mint_access_token: accessMintToken,
      }

      if (witnessBuyer) {
        try {
          let resp = null
          if (sendToNami) {
            resp = await axios.post(
              `/orders/process/${order.id}`,
              {
                transaction,
                witness_buyer: witnessBuyer,
                user_wallet: userWallet,
              },
              {
                headers: {
                  "X-CSRF-Token": csrf,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              }
            )
          } else {
            resp = { data: { success: true } }
          }

          if (resp?.data?.success) {
            setSuccess(
              "LFGOAT!\nYour transaction has been submitted to be processed. This page will update automatically."
            )
            setLoading(false)
            setErrors([])
            const newOrder = {
              ...order,
              tx_status: "submitted",
              witness_buyer: witnessBuyer,
            }
            setOrder(newOrder)

            // start polling for order status
            setTimeout(refresh, 5000)
          } else {
            console.log(resp?.data)
            const backendErrors = resp?.data?.errors || []
            setLoading(false)
            setErrors([
              "Oh BLEEEAT! Something went wrong and Nami couldn't create the transaction. Cancel the order and try again. Or contact the SGS team to let them know.",
              ...backendErrors,
            ])
          }
        } catch (err) {
          console.error(err)
          setLoading(false)
          setErrors([
            "Oh BLEEEAT! Something went wrong. Try it again or contact the SGS team to let them know.",
            err,
          ])
        }
      } else {
        console.error(witnessBuyer)
        setLoading(false)
      }
    }
    createTransaction()
    // send transaction, witnessBuyer, order, and user to backend
  }

  return (
    <ThemeProvider theme={theme}>
      {/* <div id="overlay"></div> */}

      <Box
        sx={{
          bg: "white",
          width: "100%",
          minHeight: "100vh",
          pb: [400],
          bg: randomBg,
        }}
      >
        <Img
          src={randomGoat}
          sx={{
            width: ["250px", "500px"],
            position: "fixed",
            bottom: 0,
            left: [0],
            zIndex: 5,
          }}
        />
        <Box
          sx={{
            bg: "transparent",
            width: ["90%", "600px"],
            maxWidth: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            ml: "auto",
            top: "15px",
            right: [20, 30],
            borderRadius: "15px",
            p: "20px",
            fontSize: ["16px", "22px"],
            pb: "40px",
            zIndex: 10,
          }}
        >
          {errors?.map((error) => (
            <Paragraph
              className="marker"
              sx={{
                color: "black",
                whiteSpace: "pre-wrap",
                fontSize: ["22px", "26px"],
                mb: 30,
              }}
            >
              {error}
            </Paragraph>
          ))}

          {success && (
            <Box>
              {" "}
              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  my: 20,
                }}
              >
                {success}
              </Paragraph>
              {order.tx_status == "confirmed" && (
                <Paragraph
                  className="marker"
                  sx={{ ...buttonStyles, mb: "25px", mt: 0, top: 0 }}
                  onClick={() => window?.location?.reload()}
                >
                  {"LFGOAT"}
                </Paragraph>
              )}
            </Box>
          )}

          {hasPendingTx && !success && (
            <>
              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mb: 30,
                }}
              >
                {`You have a pending tx/order. Wait or handle that order first before creating another.\n\nOur system checks the blockchain every 2 minutes.`}
              </Paragraph>
              <MintingTips />
            </>
          )}

          {!order && !success && !hasPendingTx && (
            <Box>
              {tokenAmount &&
              tokenAmount > 0 &&
              maxTokensAmount - userMintCount > 0 ? (
                <Box>
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "black",
                      whiteSpace: "pre-wrap",
                      fontSize: ["22px", "26px"],
                      lineHeight: ["24px", "32px"],
                    }}
                  >
                    {`There are ${tokenAmount} tokens available\nThey are ${tokenPrice} ADA each\n${esgsCount} ESGS\n${sgsCount} SGS\n\n`}
                  </Paragraph>

                  <MintingTips />

                  <Paragraph
                    className="marker"
                    sx={{
                      color: "black",
                      whiteSpace: "pre-wrap",
                      fontSize: ["22px", "26px"],
                      lineHeight: ["24px", "32px"],
                    }}
                  >
                    {`How many you want?`}
                  </Paragraph>
                  <Select
                    sx={{
                      border: "none",
                      bg: "white",
                      borderRadius: "10px",
                      position: "relative",
                      maxWidth: "200px",
                      zIndex: 10069,
                      fontSize: "30px",
                      variant: "text.marker",
                      outline: "none !important",
                      py: "5px",
                      px: "25px",
                      mt: "25px",
                      textAlign: "center",
                    }}
                    value={quantity}
                    onChange={(e) => setQuanity(e.target.value)}
                  >
                    {orderOptions?.map((option) => (
                      <option>{option}</option>
                    ))}
                  </Select>
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "black",
                      whiteSpace: "pre-wrap",
                      fontSize: ["22px", "26px"],
                      my: "20px",
                    }}
                  >
                    {priceText}
                  </Paragraph>
                  <Paragraph
                    className="marker"
                    sx={buttonStyles}
                    onClick={loading ? null : handleOrderClick}
                  >
                    {loading ? "Creating Order ..." : "Submit"}
                  </Paragraph>
                </Box>
              ) : (
                <Box>
                  <Paragraph
                    className="marker"
                    sx={{
                      color: "black",
                      whiteSpace: "pre-wrap",
                      fontSize: ["22px", "26px"],
                      lineHeight: ["24px", "32px"],
                      mb: 20,
                    }}
                  >
                    {userMintCount >= maxTokensAmount && tokenAmount > 0
                      ? `You've reach the max limit of tokens for your account`
                      : `ALL THE TOKENS HAVE BEEN MINTED!\n\nSOLD OUT BLEEEATCHES`}
                  </Paragraph>
                  <Paragraph
                    className="marker"
                    sx={{ ...buttonStyles, mb: 30, mt: 0, top: 0 }}
                    onClick={() =>
                      setSoldOutGif(
                        soldOutGifs[
                          Math.floor(Math.random() * soldOutGifs.length)
                        ]
                      )
                    }
                  >
                    {"LFGOAT"}
                  </Paragraph>
                  <Img
                    src={soldOutGif}
                    sx={{
                      width: ["100%"],
                      position: "relative",
                      zIndex: 5,
                      maxWidth: "400px",
                      maxHeight: "400px",
                      objectFit: "contain",
                      objectPosition: "left",
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
          {success && [null, "submitted", "pending"].includes(order.tx_status) && (
            <>
              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mb: 30,
                }}
              >
                Take a meme while you wait
              </Paragraph>

              <RandomMemes {...{ success }} />
            </>
          )}

          {order && [null, "errors"].includes(order.tx_status) && (
            <Box>
              <MintingTips />

              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mt: order.tx_errors ? 0 : 35,
                }}
              >
                {`Active Order\nQuantity: ${order.quantity}\nTotal: ${Number(
                  order.total
                )}\nNotes: ${order.notes}`}
              </Paragraph>
              {!hasNami && (
                <Paragraph
                  className="marker"
                  sx={{
                    color: "black",
                    whiteSpace: "pre-wrap",
                    fontSize: ["22px", "26px"],
                    mt: 35,
                  }}
                >
                  You need Nami to mint
                </Paragraph>
              )}
              {hasNami && (!order.witness_buyer || order.tx_errors) && (
                <Paragraph
                  className="marker"
                  sx={buttonStyles}
                  onClick={loading ? null : handleMintClick}
                >
                  {loading
                    ? loadingText
                    : order.tx_errors
                    ? "Re-sign with Nami"
                    : "Sign with Nami"}
                </Paragraph>
              )}
              {new Date() > allowResetOrder && !order.tx_hash && (
                <Paragraph
                  className="marker"
                  sx={buttonStyles}
                  onClick={() => {
                    // handleNamiSuccess("123456abc")
                    handleOrderDelete(order)
                  }}
                >
                  Cancel
                </Paragraph>
              )}
            </Box>
          )}

          {successOrders?.length > 0 && (
            <Box
              sx={{ bg: "white", p: "15px", borderRadius: "10px", mt: "35px" }}
            >
              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  pt: 0,
                }}
              >
                {`Successful Orders\n--------------------`}
              </Paragraph>
              {sortedSuccessOrders?.map((successfulOrder, index) => {
                const txSubmitted = new Date(successfulOrder.tx_created_at)
                const txSubmittedCopy = new Date(successfulOrder.tx_created_at)

                const allowResetTime = txSubmittedCopy.setHours(
                  txSubmitted.getHours() + 3
                )
                const allowResetTx =
                  new Date() > allowResetTime &&
                  successfulOrder.tx_hash &&
                  !successfulOrder.confirmed_on_blockchain

                const txSubmittedText = `${txSubmitted.toLocaleDateString()} - ${txSubmitted.toLocaleTimeString()}`
                let txStatus = successfulOrder.confirmed_on_blockchain
                  ? "Confirmed"
                  : "Pending"
                if (allowResetTx) txStatus = "Failed"
                return (
                  <Box key={successfulOrder?.id}>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        fontSize: ["22px", "26px"],
                        mt: index == 0 ? 10 : 35,
                      }}
                    >
                      {`Quantity: ${successfulOrder.quantity}`}
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                      }}
                    >
                      {`Total: ${Number(successfulOrder.total)}`}
                    </Paragraph>
                    {txStatus == "Confirmed" && (
                      <Paragraph
                        className="marker"
                        sx={{
                          color: "black",
                          fontSize: ["22px", "26px"],
                          mt: 10,
                        }}
                      >
                        {`NFTs: ${successfulOrder.nfts.join(", ")}`}
                      </Paragraph>
                    )}

                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {`Notes: ${successfulOrder.notes}`}
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      {`Tx ${txStatus}`}
                      {txStatus == "Failed" && (
                        <>
                          {` - `}
                          <a
                            onClick={() => {
                              if (
                                window?.confirm(
                                  "Are you sure you want to reset the TX hash? This should only be done for broken or failed TXs. Only do this if it's been more than 2 hours since you submitted the tx"
                                ) &&
                                window.location
                              ) {
                                window.location.href = `/reset-order-tx/${successfulOrder.id}`
                              }
                            }}
                          >
                            Reset Order Tx
                          </a>
                          {` - Only do this if it's been more than 3 hrs since you submitted the tx`}
                        </>
                      )}
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      {`Tx Hash: `}
                      <a
                        href={`https://cardanoscan.io/transaction/${successfulOrder.tx_hash}`}
                        target="_blank"
                      >
                        {successfulOrder.tx_hash}
                      </a>
                    </Paragraph>
                    <Paragraph
                      className="marker"
                      sx={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        fontSize: ["22px", "26px"],
                        mt: 10,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        color: "black",
                      }}
                    >
                      {`Tx Submitted: ${txSubmittedText}`}
                    </Paragraph>
                  </Box>
                )
              })}
              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mt: 10,
                }}
              >
                {`--------------------`}
              </Paragraph>
            </Box>
          )}

          {!success && successOrders?.length > 0 && (
            <Box sx={{ mt: 25 }}>
              <Paragraph
                className="marker"
                sx={{
                  color: "black",
                  whiteSpace: "pre-wrap",
                  fontSize: ["22px", "26px"],
                  mb: 30,
                }}
              >
                Back for more huh?
              </Paragraph>
              <RandomMemes key={2} {...{ success }} />
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  top: 10,
  color: "white !important",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "4px solid black",
  bg: "black",
  py: "12px",
  width: ["100%", 300],
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "24px",
  userSelect: "none",
  display: "block",
  marginTop: "25px",
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    bg: "#1c1c1c",
    borderColor: "#1c1c1c",
  },
}

export default MintSgs
