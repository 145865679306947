import React, { useEffect, useState } from "react"
import {
  ThemeProvider,
  Box,
  Grid,
  Image as Img,
  Paragraph,
  Link,
} from "theme-ui"
import { theme } from "../theme"
import Image1 from "images/post-mint-bg.jpg"
import Scramble from "react-scramble"
import Typewriter from "typewriter-effect"
import AudioPlayer from "./AudioPlayer"
import ConnectWallet from "../ConnectWalletDrrs"

const stren =
  "<span class='weapon-title'>WEAPON FOCUS  |  STR</span>\n\
WEAPON CLASS\n\
BRUTE\n\
RONIN\n\
LIFELEECH\n\
FANATIC\n\
TEMPLAR\n\
KENSAI\n\
SAMURAI ..."

const agil =
  "\n\n<span class='weapon-title'>WEAPON FOCUS  |  AGIL</span>\n\
WEAPON CLASS\n\
CUTPURSE\n\
OUTLAW\n\
PHLEBOTOMIST\n\
STIMFREAK\n\
YAMABUSHI\n\
PRESDIGITATOR\n\
MECHINJA ..."

const wit =
  "\n\n<span class='weapon-title'>WEAPON FOCUS  |  WIT</span>\n\
WEAPON CLASS\n\
NOVICE\n\
DISCIPLE\n\
VAMPYRE\n\
FLAGELLANT\n\
SHUKENJA\n\
NEUROMANCER\n\
STREET SEER ..."

const legendary =
  "\n\n<span class='weapon-title'>SPECIAL WEAPON CLASS</span>\nL3G3ND4RY"

const crafted =
  "\n\n<span class='weapon-title'>SPECIAL WEAPON CLASS</span>\nCR4F73D"

const messages = [stren, agil, wit, legendary, crafted]

export const MintDrrsSuccess = ({ isWl, wlStart, publicStart }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bg: "white",
          width: "100%",
          height: ["calc(100vh - 70px)"],
          bg: "#000000",
          backgroundImage: `url(${Image1})`,
          backgroundRepeat: "no-repeat",
          objectFit: "cover",
          backgroundSize: ["auto 100%", "100% auto"],
          backgroundPosition: "0px -500px",
          pb: ["100%", "40%"],
          // overflow: "hidden",
        }}
      >
        <Box sx={{ px: 25, maxWidth: ["800px", "1100px"], m: "auto" }}>
          <Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                color: "white",
                width: "100%",
                position: "relative",
                top: [0, 50],
                pb: ["20px", 0],
                bottom: 0,
                textAlign: "center",
              }}
            >
              <Box>
                <Link href="/" sx={{ textDecoration: "none" }}>
                  <Box className="pulse" sx={buttonStyles}>
                    MAIN PAGE
                  </Box>
                </Link>
              </Box>
              <Box>
                <Box
                  className="pulse"
                  sx={buttonStyles}
                  onClick={() => {
                    window.location = "/mint/otrSami5XRj9yuZb/weapons"
                  }}
                >
                  MINT AGAIN
                </Box>
              </Box>
            </Box>
            <Box
              className="pulse"
              sx={{
                position: "relative",
                border: "3px solid #e0400f",
                width: "100%",
                px: [25, 50],
                py: [20, 40],
                bg: "rgba(0, 0, 0, 0.9)",
                mr: 25,
                mb: [25, 0],
                top: [25, 50],
                mt: 50,
                overflow: "auto",
                display: "flex",
                transition: "all 750ms",
                "&:hover": {
                  bg: "rgba(0, 0, 0, 1)",
                },
              }}
            >
              <Paragraph
                className="marker"
                sx={{
                  color: "#e0400f",
                  fontSize: ["16px", "18px"],
                  whiteSpace: "pre-wrap",
                  mb: 0,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Box mb={[2, 4]} sx={{ fontSize: ["18px", "22px"] }}>
                  {"Thank you for minting DRRS Weapons"}
                </Box>
                <Grid sx={{ gridTemplateColumns: ["1fr", "1fr 1fr"] }}>
                  <Box>
                    <Box
                      sx={{
                        textAlign: "center",
                        mb: 3,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {"YOUR WEAPONS HOLD THE KEYS"}
                    </Box>
                    <Box
                      sx={{
                        textAlign: "left",
                        ml: 3,
                        maxWidth: "100%",
                        mx: "auto",
                      }}
                    >
                      {
                        "*Transform any Cardano NFT into a Hero in #MoleWars.\n*Stake in the DRRS pool for $DEAD.\n*Craft Forged Weapons.  The Foundry opens soon.\n*Whitelist:  For every 5 Weapons you hold, you are Whitelisted to mint 1 Mole.\n\nBoth the art and the concept behind the upcoming Mole Federation of Kreeb mint break new ground."
                      }
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ mb: 4, mt: [3, 0] }}>
                      {"WEAPON HOLDER ROLES"}
                    </Box>
                    <Box
                      sx={{
                        textAlign: "left",
                        maxWidth: ["250px", "300px"],
                        mx: "auto",
                      }}
                    >
                      <Grid
                        sx={{
                          gridTemplateColumns: ["1fr auto"],
                          gridColumnGap: "20px",
                          gridRowGap: 0,
                        }}
                      >
                        <Box>Brash Partisan</Box>
                        <Box>3</Box>

                        <Box>Munitions Smuggler</Box>
                        <Box>5</Box>

                        <Box>Forge Master</Box>
                        <Box>10</Box>

                        <Box>Resistance Commander</Box>
                        <Box>20</Box>

                        <Box>Demolitions Zealot</Box>
                        <Box>35</Box>

                        <Box>Obsidian Templar</Box>
                        <Box>50</Box>

                        <Box>Lord of War</Box>
                        <Box>100+</Box>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const buttonStyles = {
  textAlign: "center",
  position: "relative",
  minHeight: "60px",
  color: "#e0400f",
  whiteSpace: "nowrap",
  zIndex: 1005,
  border: "3px solid #e0400f",
  bg: "rgba(0, 0, 0, 0.7)",
  py: "12px",
  px: 15,
  mx: "auto",
  width: ["80%", 250],
  minWidth: ["unset", "200px"],
  cursor: "pointer",
  fontSize: ["18px", "22px"],
  userSelect: "none",
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "25px",
  fontFamily: '"Electrolize", system-ui, sans-serif !important',
  textDecoration: "none",
  textTransform: "uppercase",
  transition: "all 750ms",
  "&:hover": {
    bg: "rgba(0, 0, 0, 0.90)",
  },
}

export default MintDrrsSuccess
