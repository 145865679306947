import { Theme } from "theme-ui"

export const theme = {
  fonts: {
    body: '"Lato",system-ui, sans-serif',
    heading: '"Avenir Next", sans-serif',
    monospace: "Menlo, monospace",
  },
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#33e",
  },
  text: {
    marker: {
      fontFamily: '"Permanent Marker", cursive',
    },
    lato: {
      fontFamily: '"Lato", sans-serif',
    },
  },
}
