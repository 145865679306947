import React from "react"
import { ThemeProvider, Paragraph } from "theme-ui"
import Scramble from "react-scramble"
import { theme } from "../theme"

export const ScrambleText = ({ text = "$DEADSTORE", sx = {} }) => {
  return (
    <ThemeProvider theme={theme}>
      <Paragraph
        sx={{
          fontSize: ["26px"],
          width: "100%",
          color: "#e0400f",
          textAlign: "center",
          mb: 20,
          ...sx,
        }}
      >
        <Scramble
          autoStart
          text={text}
          speed="slow"
          steps={[
            {
              roll: 6,
              action: "+",
              type: "all",
            },
            {
              action: "-",
              type: "all",
            },
          ]}
        />
      </Paragraph>
    </ThemeProvider>
  )
}

export default ScrambleText
