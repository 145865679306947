import React from "react"
import { Box, Grid, Text } from "theme-ui"

const GameScores = ({ scores }) => {
  const gameScores = scores ? JSON.parse(scores) : []

  return (
    <Box
      sx={{
        position: ["relative", "absolute"],
        display: ["none", "block"],
        top: [0, 70],
        right: [0, 25],
        zIndex: 169,
        pt: ["20px", 0],
        margin: ["auto"],
        maxWidth: ["90%", 250],
        fontSize: "26px",
      }}
    >
      <Box>
        <Grid className="press_start">
          <Text
            className="press_start"
            sx={{
              color: "white",
              display: "block",
              position: "relative",
              zIndex: 9,
              pb: "3px",
              fontSize: "16px",
              textAlign: "center",
              borderBottom: "2px solid white",
            }}
          >
            High Scores
          </Text>
          {[0, 1, 2, 3, 4].map((index) => {
            return (
              <Grid
                sx={{
                  gridTemplateColumns: "auto 1fr auto",
                  color: "white",
                  fontSize: "16px",
                }}
              >
                <Text
                  sx={{
                    maxWidth: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {gameScores?.[index]?.user || "LFGOAT"}
                </Text>
                <span />
                <Text>{gameScores?.[index]?.score || 0}</Text>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default GameScores
