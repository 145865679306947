import React, { useState, useEffect } from "react"
import { Box, Image as Img, Link, Grid, Select } from "theme-ui"
import axios from "axios"

import Grass1 from "images/grass-machine.png"
import Grass2 from "images/grass-machine-2.png"

export const GrassRegister = (props) => {
  const userId = props.user_id
  const csrf = props.csrf
  const walletRefresh = props.walletRefresh || 1
  const setWalletRefresh = props.setWalletRefresh
  let activeOrder = props.active_order || {}
  const connected = props.connected
  const walletLoading = props.walletLoading
  const arcadeWallet =
    "addr1qxwdpzm7y8vskurs6llm330lk0xpu9xwdrrjaqywxcrm08zwlr38q99p23p3747m2zqdlznpm3zc8sec90exfe2ua8fs6k9s8t"
  const [order, setOrder] = useState(activeOrder)
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(null)
  const [loading, setLoading] = useState(false)
  const [tokenSelect, setTokenSelect] = useState("GRASS")
  const [open, setOpen] = useState(false)
  const [loadingText, setLoadingText] = useState("")
  const hasPendingTx = order && order?.tx_hash && !order?.tx_hash_confirmed
  const orderCompleted = order && order?.tx_hash && order?.tx_hash_confirmed

  const acceptedTokens = [
    {
      name: "GRASS",
      policy: "32cc9c6c3456bc048d14a4a8e4ee3592e9664e8daac921a8ef52d92a",
      price: 100 * 1000000,
      description: "10 CREDITS FOR 100 $GRASS",
    },
    {
      name: "SNEK",
      policy: "279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f",
      price: 25000,
      description: "10 CREDITS FOR 25k $SNEK",
    },
    {
      name: "HOSKY",
      policy: "a0028f350aaabe0545fdcb56b039bfb08e4bb4d8c4d7c3c7d481c235",
      price: 250000000,
      description: "10 CREDITS FOR 250M $HOSKY",
    },
    {
      name: "$DERP",
      policy: "961f2cac0bb1967d74691af179350c1e1062c7298d1f7be1e4696e31",
      price: 400,
      description: "10 CREDITS FOR 400 $DERP",
    },
    {
      name: "CLAY",
      policy: "38ad9dc3aec6a2f38e220142b9aa6ade63ebe71f65e7cc2b7d8a8535",
      price: 500 * 10000,
      description: "10 CREDITS FOR 500 $CLAY",
    },
    {
      name: "SOCIETY",
      policy: "25f0fc240e91bd95dcdaebd2ba7713fc5168ac77234a3d79449fc20c",
      price: 150 * 1000000,
      description: "10 CREDITS FOR 150 $SOCIETY",
    },
    {
      name: "DEAD",
      policy: "6194158d24d71eca5cc5601c45de123bf78d02c297e851be2608810a",
      price: 2000,
      description: "10 CREDITS FOR 2k $DEAD",
    },
    {
      name: "DING",
      policy: "ce5b9e0f8a88255b65f2e4d065c6e716e9fa9a8a86dfb86423dd1ac0",
      price: 500 * 1000000,
      description: "10 CREDITS FOR 500 $DING",
    },
    {
      name: "KNIGHT",
      policy: "c0a36422d825edad4dabf658d127d681f1c53935b500c0a1b6d0499e",
      price: 100000000,
      description: "10 CREDITS FOR 100M $KNIGHT",
    },
    {
      name: "SACT",
      policy: "4fd6d8b1eac12b029cd68acd25220c20dd13b9c4b0d0b585d49135d3",
      price: 2000,
      description: "10 CREDITS FOR 2k $SACT",
    },
    {
      name: "UGLY",
      policy: "95a15fafc24be11478735df6fa4eaba6e6319c27f24ced13f39bea44",
      price: 1.25 * 1000000,
      description: "10 CREDITS FOR 1.25 $UGLY",
    },
  ]

  const selectedToken = acceptedTokens.find(
    (token) => token.name == order?.notes
  )

  const [showTx, setShowTx] = useState(!!activeOrder?.id)
  const [hovered, setHovered] = useState(false)

  useEffect(() => {
    refresh()
    if (order?.id && !order.tx_hash_confirmed && order.tx_status != "failed") {
      setTimeout(refresh, 5000)
    }

    if (order && order.tx_errors) {
      const newErrors = order.tx_errors?.split(";;") || []
      setErrors([
        "There was an error. Re-sign the tx and try again.",
        ...newErrors,
      ])
    }
  }, [])

  async function refresh() {
    console.log("refresh")
    if (!order?.id) return null

    const resp = await axios.get(`/orders/${order?.id}`, {
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

    const orderData = resp?.data
    const isDifferent = JSON.stringify(order) != JSON.stringify(orderData)
    if (isDifferent && orderData?.id) {
      setOrder(orderData)

      if (orderData.tx_hash && !orderData.tx_hash_confirmed) {
        setSuccess(
          "Your tx was successfully submitted\n\nCredits will be added to your account once it is confirmed"
        )
      }

      if (orderData.tx_hash_confirmed) {
        setSuccess(
          "Order was confirmed. Credits should be added to your account"
        )
        setWalletRefresh(walletRefresh + 1)
        document.getElementById("gt-credits-number").innerHTML =
          orderData?.credits
      }

      if (orderData.tx_errors) {
        const newErrors = orderData.tx_errors?.split(";;") || []
        setSuccess("")
        setErrors([
          "There was an error. Re-sign the tx and try again.",
          ...newErrors,
        ])
      }
    }

    if (!orderData.tx_hash_confirmed) {
      const delay = orderData.tx_hash ? 30000 : 10000
      setTimeout(refresh, delay)
    }
  }

  const handleOrderClick = async (tokenType) => {
    setLoading(true)
    setSuccess("")
    try {
      const resp = await axios.post(
        "/orders/gt-arcade",
        {
          order: {
            quantity: 10,
            user_id: userId,
            total: tokenType == "ADA" ? 20 : 0,
            notes: tokenType,
            order_type: "GT-ARCADE",
            address: connected?.address,
          },
        },
        {
          headers: {
            "X-CSRF-Token": csrf,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      setLoading(false)

      if (resp?.data?.id) {
        setOrder(resp?.data)
        setErrors(null)
      } else {
        setErrors(resp?.data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleOrderDelete = async (o) => {
    setLoading(true)
    try {
      const resp = await axios.delete(`/orders/${o.id}`, {
        headers: {
          "X-CSRF-Token": csrf,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })

      setLoading(false)

      if (resp?.data?.deleted == true) {
        setOrder(null)
      } else {
        setErrors([resp?.data?.error])
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleTxClick = () => {
    setLoading(true)
    setLoadingText("BUILDING")

    const createTransaction = async () => {
      let paymentAddress = connected?.address
      const userWallet = paymentAddress

      const tokenPayment = [
        {
          unit: `${selectedToken?.policy}.${selectedToken?.name}`,
          quantity: selectedToken?.price,
        },
      ]

      let dummyNftsMeta = {
        TEST: {
          Test: "GoatTribeGoatTribeGoatTribeGoatTribeGoatTribeGoatTribe",
          Publisher: "GoatTribeGoatTribeGoatTribeGoatTribeGoatTribeGoatTribe",
          Twitter: "GoatTribeGoatTribeGoatTribeGoatTribeGoatTribeGoatTribe",
        },
      }

      let dummyMetadata = {
        721: {
          1234: dummyNftsMeta,
        },
      }

      const assetsValid = true

      let recipients = []

      if (selectedToken) {
        recipients = [
          {
            address: arcadeWallet,
            amount: "0",
            assets: tokenPayment,
          },
        ]
      } else {
        recipients = [
          {
            address: arcadeWallet,
            amount: "20",
          },
        ]
      }

      let utxos = null
      let netId = null
      let transaction = null

      if (assetsValid) {
        try {
          utxos = await connected.getUtxosHex()
          netId = await connected.getNetworkId()
          transaction = await connected.transaction({
            PaymentAddress: paymentAddress,
            recipients: recipients,
            metadata: dummyMetadata,
            addMetadata: false,
            utxosRaw: utxos,
            networkId: netId?.id || 1,
            ttl: 7200,
          })
        } catch (err) {
          let errMessage = err?.message
          if (errMessage == "INPUTS_EXHAUSTED")
            errMessage =
              "UXTOs exhausted - You don't have enough funds or it's too many utxos.\n\nTry sending yourself all/most of your selected tokens to your same wallet address. That will put everything on one utxo. Then resign the tx and try again."
          console.error(err)
          setLoading(false)
          setErrors([errMessage])
        }
      } else {
        setLoading(false)
        setErrors(["Oh no! There was an error building the tx."])
      }

      let witnessBuyer = null
      try {
        witnessBuyer = await connected.signTx(transaction, true)
      } catch {
        // if user clicks cancel or Nami throws an error
        setLoading(false)
      }

      if (witnessBuyer) {
        try {
          let resp = null
          resp = await axios.post(
            `/orders/process/${order.id}`,
            {
              transaction,
              witness_buyer: witnessBuyer,
              user_wallet: userWallet,
            },
            {
              headers: {
                "X-CSRF-Token": csrf,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )

          if (resp?.data?.success) {
            setSuccess(
              "Confirmed ...\n\nYour transaction has been submitted to be processed."
            )
            setLoading(false)
            setErrors([])
            const newOrder = {
              ...order,
              tx_status: "submitted",
              witness_buyer: witnessBuyer,
            }
            setOrder(newOrder)

            // start polling for order status
            setTimeout(refresh, 5000)
          } else {
            console.log(resp?.data)
            const backendErrors = resp?.data?.errors || []
            setLoading(false)
            setErrors([
              "Something went wrong and we couldn't create the transaction. Try again or contact the team to let them know.",
              ...backendErrors,
            ])
          }
        } catch (err) {
          console.error(err)
          setLoading(false)
          setErrors([
            "Oh no! Something went wrong. Try it again or contact the team to let them know.",
            err,
          ])
        }
      } else {
        console.error(witnessBuyer)
        setLoading(false)
      }
    }
    if (!loading) createTransaction()
    // send transaction, witnessBuyer, order, and user to backend
  }

  const ogPolicy = "c281975562f394761771f13f599881517fa8455946e7e30454de22da"
  const goatiaPolicy =
    "bbb6c713c497b8a9c3390a2157bac72d730ce842b27b5672d3b3d51f"

  const ogs =
    connected?.balance?.assets?.filter((nft) => nft.policy == ogPolicy) || []

  const goatia =
    connected?.balance?.assets?.filter((nft) => nft.policy == goatiaPolicy) ||
    []

  const hasGoats = connected && (ogs?.length > 0 || goatia?.length > 0)

  return (
    <>
      <Box
        sx={{
          ml: "auto",
          mr: "30px",
          width: "288px",
          position: "sticky",
          zIndex: 50,
          top: "50px",
          mt: "50px",
          cursor: "pointer",
          height: "420px",
          mb: ["80px", 0],
        }}
      >
        {connected && hasGoats && (
          <Box
            onClick={() => {
              document.getElementById("grass-register").play()
              setShowTx(true)
            }}
            onMouseEnter={() => {
              setHovered(true)
              document.getElementById("grass-register").play()
            }}
            onMouseLeave={() => {
              setHovered(false)
            }}
          >
            <Img
              src={Grass1}
              sx={{
                width: ["250px"],
                zIndex: 9,
                position: "relative",
                opacity: hovered ? 0 : 1,
              }}
            />
            <Img
              src={Grass2}
              sx={{
                width: ["288px"],
                top: 0,
                position: "relative",
                top: "-417px",
                zIndex: 10,
                opacity: hovered ? 1 : 0,
              }}
            />
          </Box>
        )}
        <audio id="grass-register" src={"/cash-register-3.mp3"}></audio>
      </Box>
      <Box
        sx={{
          bg: "black",
          width: "100%",
          height: "calc(100vh - 0px)",
          position: "fixed",
          display: showTx ? "block" : "none",
          top: 0,
          bottom: 0,
          left: [0],
          zIndex: 42069,
        }}
        className="press_start"
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
          onClick={() => {
            setShowTx(false)
          }}
        ></Box>
        <Box
          sx={{
            width: ["90%", 550],
            border: "5px solid white",
            bg: "black",
            minHeight: "350px",
            position: "relative",
            top: "5%",
            mx: "auto",
            p: [15, 25],
            color: "white",
            textAlign: "center",
            cursor: "pointer",
            zIndex: 333333,
          }}
        >
          <Box>GRASS REGISTER</Box>
          <Box sx={{ mt: 30 }}>
            {connected && order?.id && (
              <Box>
                <Box
                  sx={{
                    color: "white",
                    whiteSpace: "pre-wrap",
                    fontSize: ["22px", "26px"],
                    mb: 20,
                  }}
                >
                  {`Order ${order.id}`}
                </Box>
                {errors && errors.length > 0 && (
                  <Box>
                    {errors?.map((error) => (
                      <Box
                        sx={{
                          color: "white",

                          whiteSpace: "pre-wrap",
                          textAlign: "center",
                          mb: 30,
                          mt: 0,
                          mx: "auto",
                          maxWidth: 1000,
                        }}
                      >
                        {error}
                      </Box>
                    ))}
                  </Box>
                )}

                {success && (
                  <Box>
                    <Box
                      sx={{
                        color: "white",
                        whiteSpace: "pre-wrap",
                        my: 20,
                      }}
                    >
                      {success}
                    </Box>
                  </Box>
                )}

                {hasPendingTx && !success && (
                  <>
                    <Box
                      sx={{
                        whiteSpace: "pre-wrap",
                        mb: 30,
                      }}
                    >
                      {`You have a pending tx\nWait for the tx to be confirmed\n\nOur system checks the blockchain every few seconds`}
                    </Box>
                  </>
                )}

                {order?.id && [null, "errors"].includes(order.tx_status) && (
                  <Box sx={{}}>
                    <Box my={30}>
                      {selectedToken?.description}
                      {order.notes == "ADA" && "10 CREDITS FOR 20 ADA"}
                    </Box>
                    <Grid sx={{ gridTemplateColumns: "1fr 1fr" }}>
                      <Box>
                        <Box
                          sx={{
                            mt: 50,
                            color: "black",
                            bg: "white",
                            width: "100%",
                            padding: "12px 40px",
                            display: "inline-block",
                            cursor: "pointer",
                            border: "4px solid black",
                            "&:hover": {
                              color: "white",
                              bg: "black",
                              border: "4px solid white",
                            },
                          }}
                          onClick={() => {
                            handleOrderDelete(order)
                          }}
                        >
                          CANCEL
                        </Box>
                      </Box>
                      {!walletLoading &&
                        (!order.witness_buyer || order.tx_errors) && (
                          <Box
                            sx={{
                              mt: 50,
                              color: "black",
                              bg: "white",
                              width: "100%",
                              padding: "12px 40px",
                              display: "inline-block",
                              cursor: "pointer",
                              border: "4px solid black",
                              "&:hover": {
                                color: "white",
                                bg: "black",
                                border: "4px solid white",
                              },
                            }}
                            onClick={loading ? null : handleTxClick}
                          >
                            {loading
                              ? loadingText
                              : order.tx_errors
                              ? "RE-SIGN"
                              : "BUILD TX"}
                          </Box>
                        )}
                    </Grid>
                  </Box>
                )}

                {orderCompleted && (
                  <Box>
                    <Box>
                      <Box
                        sx={{
                          color: "white",
                          whiteSpace: "pre-wrap",
                          fontSize: ["22px", "26px"],
                          mt: 30,
                          mb: 30,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          cursor: "pointer",
                          "& a": {
                            color: "#27c124",
                          },
                        }}
                      >
                        {`Tx Hash: `}
                        <a
                          href={`https://cardanoscan.io/transaction/${order.tx_hash}`}
                          target="_blank"
                        >
                          {order.tx_hash}
                        </a>
                      </Box>

                      <Box
                        sx={{
                          color: "black",
                          bg: "white",
                          padding: "12px 40px",
                          display: "inline-block",
                          cursor: "pointer",
                          border: "4px solid black",
                          width: "100%",
                          "&:hover": {
                            color: "white",
                            bg: "black",
                            border: "4px solid white",
                          },
                        }}
                        onClick={() => {
                          setOrder(null)
                          setShowTx(false)
                          activeOrder = {}
                        }}
                      >
                        CLEAR
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {!order?.id && (
              <Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: 15,
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setOpen(!open)
                  }}
                >
                  <Box>TOKEN:</Box>
                  <Box sx={{ position: "relative" }}>
                    <Box
                      sx={{
                        ...btnStyles,
                      }}
                    >
                      {tokenSelect}
                    </Box>
                    {open && (
                      <Box
                        sx={{
                          color: "black",
                          width: "100%",
                          bg: "white",
                          padding: ["6px 6px"],
                          display: "inline-block",
                          cursor: "pointer",
                          border: "4px solid black",
                          whiteSpace: ["nowrap"],
                          fontSize: ["12px", "14px"],
                          position: "absolute",
                          top: "60px",
                          left: 0,
                          right: 0,
                          maxHeight: 500,
                          overflow: "auto",
                        }}
                      >
                        {acceptedTokens.map((token) => (
                          <Box
                            sx={{
                              py: "10px",
                              width: "100%",
                              "&:hover": {
                                color: "white",
                                bg: "black",
                              },
                            }}
                            onClick={() => {
                              setTokenSelect(token.name)
                              setOpen(false)
                            }}
                          >
                            {token.name}
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>

                {acceptedTokens.map((token, index) =>
                  token.name == tokenSelect ? (
                    <Box
                      sx={{
                        mt: 20,
                        ...btnStyles,
                      }}
                      onClick={() => {
                        handleOrderClick(token.name)
                      }}
                    >
                      {token.description}
                    </Box>
                  ) : null
                )}
                <Box
                  sx={{
                    mt: 20,
                    ...btnStyles,
                  }}
                  onClick={() => {
                    handleOrderClick("ADA")
                  }}
                >
                  10 CREDITS FOR 20 ADA
                </Box>
                <Link
                  href="https://muesliswap.com/swap?base=.&quote=32cc9c6c3456bc048d14a4a8e4ee3592e9664e8daac921a8ef52d92a.4752415353"
                  target="_blank"
                >
                  <Box
                    sx={{
                      mt: 20,
                      ...btnStyles,
                    }}
                  >
                    BUY $GRASS
                  </Box>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

const btnStyles = {
  color: "black",
  width: "100%",
  bg: "white",
  padding: ["12px 10px", "12px 40px"],
  display: "inline-block",
  cursor: "pointer",
  border: "4px solid black",
  whiteSpace: ["nowrap"],
  fontSize: ["12px", "14px"],
  "&:hover": {
    color: "white",
    bg: "black",
    border: "4px solid white",
  },
}

export default GrassRegister
