import React from "react"
import { Box } from "theme-ui"
import { arcadeSongs, songTitles } from "./Goatcade"

import AudioPlayer from "./AudioPlayer"

const GameMusic = () => {
  return (
    <Box
      sx={{
        position: ["relative", "absolute"],
        top: [0, 70],
        left: 15,
        zIndex: 169,
        maxWidth: ["unset", 250],
        fontSize: "26px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          ml: "15px",
          mt: "20px",
          fontSize: "26px",
          textTransform: "uppercase",
          "& i": {
            fontSize: "40px",
          },
        }}
        className="neon2"
      >
        <AudioPlayer src={arcadeSongs} songTitles={songTitles} />
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: "15px",
        }}
        className="neon2"
      >
        <a href={"/"}>
          <Box
            sx={{
              ml: "10px",
              cursor: "pointer",
              fontSize: "26px",
              color: "#ffd5ff",
            }}
            onClick={() => {
              const player = document.getElementById("audio")
              window.localStorage.setItem("trackTime", player?.currentTime)
            }}
          >
            BACK
          </Box>
        </a>
      </Box>
    </Box>
  )
}

export default GameMusic
